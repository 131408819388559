<template>
	<div
		class="admin-item-row clickable"
		@click="$emit('on-click', session.id)"
	>
		<div class="admin-item-col medium">
			{{ session.sessionId }}
		</div>
		<div class="admin-item-col medium">
			<div v-if="session.user">{{ session.user.firstName }} {{ session.user.lastName }}</div>
		</div>
		<div class="admin-item-col small">
			{{ session.bikes || 0 }}
		</div>
		<div class="admin-item-col small">
			{{ session.parking? session.parking.code : '-' }}
		</div>
		<div class="admin-item-col small">
			{{ time(session.startTime) }}
		</div>
		<div class="admin-item-col small">
			{{ diffInTime(session.startTime, session.endTime || new Date().getTime()) }}
		</div>
	</div>
</template>

<script>
import { time, diffInTime } from '@/helpers'

export default {
	name: 'SessionResult',
	components: {},
	props: {
		session: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {}
	},
	computed: {},
	methods: {
		time,
		diffInTime,
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}

.admin-item-row.toApprove {
	background-color: lighten($red, 20);
}
</style>
