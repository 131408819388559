<template>
	<Accordion :title="$t('profile.company.title')" :isOpen="true">
		<div class="company-view">
			<!-- <p
			class="link activatable profile-action-button company-toggle"
			tabindex="0"
			@mousedown="toggleCompanyForm($event)"
			@keyup.enter="toggleCompanyForm($event)"
		>
			{{ companyInfoOpen ? $t('profile.company.closeForm') : $t('profile.company.openForm') }}
		</p> -->
			<div class="profile-fields">
				<div class="profile-field">
					<div class="profile-field-info">
						<h3 class="profile-field-title">
							{{ $t('profile.company.name') }}
						</h3>
						<p class="profile-field-value">
							{{ company.name }}
						</p>
					</div>
				</div>
				<div class="profile-field">
					<div class="profile-field-info">
						<h3 class="profile-field-title">
							{{ $t('profile.company.vatNumber') }}
						</h3>
						<p class="profile-field-value">
							{{ company.vatNumber }}
						</p>
					</div>
				</div>
				<div class="profile-field">
					<div class="profile-field-info">
						<h3 class="profile-field-title">
							{{ $t('profile.address.streetName') }}
						</h3>
						<p class="profile-field-value">
							{{ company.address.streetName }}
						</p>
					</div>
				</div>
				<div class="profile-field">
					<div class="profile-field-info">
						<h3 class="profile-field-title">
							{{ $t('profile.address.houseNumber') }} ({{ $t('profile.address.busNumber') }})
						</h3>
						<p class="profile-field-value">
							{{ company.address.houseNumber }}
							<span v-if="company.address.busNumber">({{ company.address.busNumber }})</span>
						</p>
					</div>
				</div>
				<div class="profile-field">
					<div class="profile-field-info">
						<h3 class="profile-field-title">
							{{ $t('profile.address.postalCode') }}
						</h3>
						<p class="profile-field-value">
							{{ company.address.postalCode }}
						</p>
					</div>
				</div>
				<div class="profile-field">
					<div class="profile-field-info">
						<h3 class="profile-field-title">
							{{ $t('profile.address.city') }}
						</h3>
						<p class="profile-field-value">
							{{ company.address.city }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</Accordion>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
export default {
	name: 'ProfileCompanyView',
	components: {
		Accordion,
	},
	props: {
		company: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			companyInfoOpen: true,
		}
	},
	methods: {
		toggleCompanyForm(e) {
			if (e) {
				e.preventDefault()
			}

			this.companyInfoOpen = !this.companyInfoOpen
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
.company-toggle {
	cursor: pointer;
}
</style>
