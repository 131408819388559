<template>
    <div class="bg">
        <PreviousButton v-if="showPreviousButton"/>
        
       
             <seeyousoon  />
     </div>
</template>

<script>
import PreviousButton from '@/components/PreviousButton.vue'
import activesession from '../../components/session/activesession.vue'
import seeyousoon from '../../components/session/seeyousoon.vue'
export default {
    name: "SeeYouSoon",
    components: {
        activesession,
        seeyousoon,
        PreviousButton
	},
	data() {
		return {
            progress: 3,
            parentData: 'session',
            showPreviousButton: !this.$route.fullPath.includes('thankyou'),
		}
	},
	methods: {
		nextStep() {
			this.progress += 1
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';
.mollie {
    width: 30%;
    /* position: relative; */
    margin: 0px auto;
} 

@media screen and (max-width: 768px) {
    .mollie {
        width: 80%;  
        margin-left: auto;
        margin-right: auto;
        
    }
    .roww{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}

</style>
