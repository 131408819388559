<template>
	<div class="bg-blue">
		<Loading v-if="!sessionEnd || isLoading" />
		<div v-else="sessionEnd && !isLoading" class="start-session-qr">
			<GenrateQr
				:type="localizedData.scanType"
				:title="localizedData.title"
				:description="localizedData.description"
				:sessionId="sessionEnd ? sessionEnd.sessionId : '0'"
				:qrCodeText="
					sessionEnd && sessionEnd.sessionQRCodes.length > 0
						? sessionEnd.sessionQRCodes[sessionEnd.sessionQRCodes.length - 1].qrCode
						: 'scan to encode'
				"
				:numOfScan="
					sessionEnd
						? sessionEnd.sessionQRCodes.length > 0
							? sessionEnd.sessionQRCodes[sessionEnd.sessionQRCodes.length - 1].used
							: 0
						: 0
				"
				:totalScan="
					sessionEnd
						? sessionEnd.sessionQRCodes.length > 0
							? sessionEnd.sessionQRCodes[sessionEnd.sessionQRCodes.length - 1].usage
							: 0
						: 0
				"
				:message="localizedData.message"
			/>
		</div>
	</div>
</template>

<script>
import GenrateQr from '@/components/session/GenerateQr.vue'
import { mapState, mapActions } from 'vuex'
import SessionRequired from '@/mixins/session-required'
import Loading from '@/components/Loading.vue'
import io from 'socket.io-client'
import { config } from '@/config'
export default {
	name: 'SessionEndQr',
	computed: {
		localizedData() {
			return {
				scanType: 'session-end-qr',
				title: this.$t('sessionParking.qrCode3.title'),
				description: this.$t('sessionParking.qrCode3.description'),
				message: this.$t('sessionParking.qrCode3.message'),
			}
    },
		...mapState({
			sessionEnd: (state) =>
				state.session.sessionEnd.length > 0 ? state.session.sessionEnd[0] : null,
		}),
	},
	components: {
		GenrateQr,
		Loading,
	},
	mixins: [SessionRequired],
	data() {
		return {
			GenrateQr,
			qrScannedStatus: null,
			isLoading: false,
		}
	},
	methods: {
		...mapActions('session', ['getSessionById']),
		...mapActions('alert', ['error']),
		async loadSessionPeriodically() {
			const sessionId = localStorage.getItem('sessionId')

			if (sessionId) {
				const numericSessionId = parseInt(sessionId, 10)

				if (!isNaN(numericSessionId)) {
					const sessionProps = {
						sessionId: numericSessionId,
					}
					try {
						// Dispatch the action properly
						await this.getSessionById(sessionProps)
					} catch (error) {
						console.error('Error fetching session:', error)
					}
				}
			}
		},
	},
	created() {
		// Connect to the Socket.IO server
		this.socket = io(config.apiUrl)
		// Handle event from the server for Subscription first charge
		this.socket.on('QrScanned', ({ response }) => {
			this.qrScannedStatus = response
			if (this.qrScannedStatus) {
				if (this.qrScannedStatus.success === true) {
					const rfId = this.qrScannedStatus.rfid
					if (rfId === this.sessionEnd.sessionQRCodes[0].qrCode) {
						this.isLoading = true
						setTimeout(() => {
							this.isLoading = false
						}, 5000)
						this.loadSessionPeriodically()
					}
				} else {
					this.error(this.qrScannedStatus.message)
				}
			}
		})

		// Example: handling connection error
		this.socket.on('connect_error', (error) => {
			console.error('Connection error:', error)
		})
	},
	beforeDestroy() {
		this.socket.off('QrScanned')
	},
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';
</style>
