<template>
	<div>
		<div>
			<div class="admin-item-info-field">
				<p class="admin-item-info-text">
					<strong>Deposit status:</strong>
					{{ depositStatus() }}
				</p>
			</div>

			<div v-if="deposit.status === statuses.closed" class="admin-item-info-field">
				<p style="margin-right: 5px" class="admin-item-info-text">
					<strong>{{ $t('admin.depositIncomeDate') }}:</strong>
					{{ date(deposit.incomedAt) }}
				</p>
			</div>
			<div v-else-if="deposit.status === statuses.cancelled" class="admin-item-info-field">
				<p style="margin-right: 5px" class="admin-item-info-text">
					<strong>{{ $t('admin.reimbursementDate') }}:</strong>
					{{ date(deposit.reimbursedAt) }}
				</p>
			</div>
			<div class="admin-item-info-field">
				<p class="admin-item-info-text">
					<strong>{{ $t('subscription.price') }}:</strong>
					{{ formattedAmount }}
				</p>
			</div>
			<div class="admin-item-info-field">
				<p class="admin-item-info-text">
					<strong>{{ $t('admin.paymentMethod') }}:</strong>
					{{ deposit.paymentMethod || '-' }}
				</p>
			</div>
		</div>
		<div v-if="!deposit.paymentMethod && !deposit.legacy">{{ $t('admin.depositNotPaid') }}</div>
		<div
			v-if="deposit.status === statuses.active && (deposit.paymentMethod || deposit.legacy)"
			class="admin-item-info-field action-buttons"
		>
			<div>
				<template>
					<form v-if="isSubActive" class="admin-item-info-form" @submit.prevent="$emit('add-key')">
						<button
							:disabled="hasInvoicedANewKey"
							:class="{ disabled: hasInvoicedANewKey }"
							class="button blue tiny subscription-deposit-action"
						>
							Invoice new key
						</button>
					</form>
					<form
						v-if="!deposit.legacy"
						class="admin-item-info-form"
						@submit.prevent="$emit('refund-deposit', deposit.id)"
					>
						<button
							:class="
								filterInvoiceItems(deposit.id) === true && deposit.stripeId
									? 'button blue tiny subscription-deposit-action disabled'
									: 'button blue tiny subscription-deposit-action'
							"
							:disabled="filterInvoiceItems(deposit.id) === true && deposit.stripeId ? true : false"
						>
							{{ deposit.mollieId ? 'Refund (mollie)' : 'Refund (stripe)' }}
						</button>
					</form>
					<form
						class="admin-item-info-form"
						@submit.prevent="$emit('manual-refund-deposit', deposit.id)"
					>
						<button
							:disabled="!hasBankAcountFilledIn"
							:class="{ disabled: !hasBankAcountFilledIn }"
							class="button blue tiny subscription-deposit-action"
						>
							Refund (manual)
						</button>
					</form>
					<form class="admin-item-info-form" @submit.prevent="$emit('close-deposit', deposit.id)">
						<button class="button blue tiny subscription-deposit-action">Close</button>
					</form>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { DEPOSIT_STATES } from '@/config'
import { keys, map, find } from 'lodash/fp'
import { date } from '@/helpers'

export default {
	name: 'DepositForm',

	// DatePicker,
	components: {},
	props: {
		deposit: {
			type: Object,
			default: () => ({}),
		},
		isSubActive: {
			type: Boolean,
			default: false,
		},
		hasInvoicedANewKey: {
			type: Boolean,
			default: false,
		},
		invoiceItems: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			options: map(
				(k) => ({ text: this.$t(`subscription.depositStatuses.${k}`), value: DEPOSIT_STATES[k] }),
				keys(DEPOSIT_STATES)
			),
			statuses: DEPOSIT_STATES,
		}
	},
	computed: {
		...mapState({
			user: (state) => state.admin.userForm.user,
		}),
		hasBankAcountFilledIn() {
			return this.user.bankAccount
		},
		formattedAmount() {
			if (this.deposit.mollieId !== null) {
				return (this.deposit.price * 100).toFixed(2)
			} else if (
				this.deposit.mollieId === null &&
				this.deposit.stripeId === null &&
				this.deposit.price < 1
			) {
				return (this.deposit.price * 100).toFixed(2)
			} else {
				return this.deposit.price.toFixed(2)
			}
		},
	},
	mounted() {
		//console.log('invoiceItems', this.invoiceItems)
	},

	methods: {
		depositStatus() {
			return (find((option) => option.value === this.deposit.status, this.options) || {}).text
		},
		filterInvoiceItems(depositId) {
			const result = this.invoiceItems.filter((items) => items.depositId === depositId)

			if (result.length > 0) {
				const paidAtDate = new Date(result[0].paidAt)
				console.log('paidAtDate', paidAtDate)
				const currentDate = new Date()
				const sixMonthsAgo = new Date()
				sixMonthsAgo.setMonth(currentDate.getMonth() - 6)
				// Return true if the paidAtDate is either older than 6 months or within the last 6 months
				if (paidAtDate < currentDate && paidAtDate < sixMonthsAgo) {
					return true
				}
			}

			return false // Return false if no matching depositId is found
		},
		date,
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}
</style>
