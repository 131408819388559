<template>
	<div>
		<div class="temporaryAccess-wrapper">
			<div class="center-div">
				<p class="link" @click="openConfirmationModal">I failed to open the door</p>
			</div>
		</div>
		<Modal
			v-if="confirmationModal"
			:modalOpen="confirmationModal"
			:cancelLabel="$t('temporaryAccess.retry.modal.cancelButton')"
			:confirmLabel="$t('temporaryAccess.retry.modal.confirmButton')"
			@confirm="confirmRetry"
			@cancel="closeConfirmationModal"
			@close="closeConfirmationModal"
		>
			<template>
				<div>
					<h1 v-if="retryEnter" class="small-title center-div">
						{{ $t('temporaryAccess.retry.modal.title.enter') }}
					</h1>
					<h1 v-else class="small-title center-div">
						{{ $t('temporaryAccess.retry.modal.title.getOut') }}
					</h1>
					<div class="temporaryAccess-modal-parking-info">
						<p v-if="retryEnter" class="temporaryAccess-modal-text warning">
							{{ $t('temporaryAccess.retry.modal.body.part1.enter') }}
						</p>
						<p v-else class="temporaryAccess-modal-text warning">
							{{ $t('temporaryAccess.retry.modal.body.part1.getOut') }}
						</p>
						<p class="temporaryAccess-modal-text warning">
							{{ $t('temporaryAccess.retry.modal.body.part2') }}
						</p>
					</div>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from '@/components/Modal.vue'

/* import { logger } from '@/logger' */

export default {
	name: 'Retry',
	components: {
		Modal,
	},
	props: {
		temporaryAccess: {
			type: Object,
			required: true,
		},
		retryEnter: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			confirmationModal: false,
		}
	},
	computed: {},
	methods: {
		confirmRetry() {
			this.closeConfirmationModal()
			this.$emit('retry')
		},
		openConfirmationModal() {
			this.confirmationModal = true
		},
		closeConfirmationModal() {
			this.confirmationModal = false
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/temporaryAccess/temporaryAccess';
</style>
