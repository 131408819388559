<template>
	<div>
		<Modal
			v-if="cancelSubModal"
			:modalOpen="cancelSubModal"
			:loading="cancelSubLoading"
			:cancelLabel="$t('profile.proposal.cancelLabel')"
			:confirmLabel="$t('profile.proposal.confirmLabel')"
			:canClose="true"
			@confirm="confirmCancelSub"
			@cancel="closeCancelSubModal"
			@close="closeCancelSubModal"
		>
			<h3>
				{{ $t('profile.subscription.confirmCancelModal') }}
			</h3>
		</Modal>
		<Modal
			v-if="familyAccountInfoOpen"
			:modalOpen="familyAccountInfoOpen"
			:hideCancel="true"
			:confirmLabel="'OK'"
			:canClose="true"
			@confirm="toggleFamilyAccountInfo()"
			@close="toggleFamilyAccountInfo()"
		>
			<h3>{{ $t('profile.completeFamilyProfileMessage') }}</h3>
		</Modal>

		<PreviousButton/>
		<Loading v-if="!isLoaded" />
		<div v-else :class="{ 'is-child': isChild }" class="profile">
			<CreateAccount v-if="!!createAccountOpen" @toggleForm="toggleCreateAccountOpen" />
			<div v-else class="profile-content no-wrap">
				<div class="profile-content-block no-wrap">
					<Plans
						v-if="!isAdminEdit"
						:subscriptions="subsToShow(currentUser.subscriptions)"
						:proposals="activeProposals"
						@toggle-proposal-modal="toggleProposalModal"
						@toggle-cancel-sub-modal="toggleCancelSubModal"
					/>
				</div>
				<div class="profile-content-block">
					<PaymentMethod
						v-if="!isChild && !isAdminEdit"
						:subscriptions="subsToShow(currentUser.subscriptions)"
						:id="currentUser.id"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import ProfileView from '@/components/profile/View.vue'
import ProfileEdit from '@/components/profile/Edit.vue'
import PaymentMethod from '@/components/mollie/PaymentMethod.vue'
import CreateAccount from '@/components/profile/CreateAccount.vue'
import Plans from '@/components/mollie/Plans.vue'
import ProfileTemporaryAccesses from '@/components/profile/TemporaryAccesses.vue'
import ProfileRequests from '@/components/profile/Requests.vue'
import ProfileAddresses from '@/components/profile/ProfileAddresses.vue'
import RequestModal from '@/components/RequestModal.vue'
import Loading from '@/components/Loading.vue'
import Modal from '@/components/Modal.vue'
import ValidationRequired from '@/mixins/validation-required'
import { subsToShow } from '@/store/modules/subscription'
import { find } from 'lodash/fp'
import { date } from '@/helpers'
import Checkbox from '@/components/form/Checkbox.vue'
import ProfileCompanyView from '@/components/profile/CompanyView.vue'
import Dropdown from '@/components/form/Dropdown.vue'
import PreviousButton from '../../components/PreviousButton.vue'

// import { logger } from '@/logger'

export default {
	name: 'MollieBilling',
	components: {
		ProfileView,
		ProfileEdit,
		ProfileAddresses,
		Plans,
		ProfileTemporaryAccesses,
		ProfileRequests,
		Loading,
		Modal,
		RequestModal,
		Checkbox,
		PaymentMethod,
		CreateAccount,
		ProfileCompanyView,
		Dropdown,
		PreviousButton,
	},
	mixins: [ValidationRequired],
	props: {
		editable: {
			default: false,
			type: Boolean,
		},
		proposal: {
			default: '',
			type: String,
		},
	},
	data() {
		return {
			profileEditable: this.editable,
			mobibLoading: false,
			editLoading: false,
			mobibModal: false,
			mobibType: '',
			proposalModal: false,
			proposalLoading: false,
			selectedProposalId: null,
			confirmProposalModal: false,
			cancelSubModal: false,
			cancelSubLoading: false,
			termsOfConditionProposal: false,
			toDeleteAddressId: '',
			createAccountOpen: false,
			deleteAccountModalOpen: false,
			deleteAccountModalLoading: false,
			familyAccountInfoOpen: false,
			addressOpen: false,
			rejectReason: {},
			rejectReasonOther: '',
		}
	},
	computed: {
		...mapState({
			/* currentUser: (state) => state.profile.currentUser, */
			valid: (state) => state.profile.valid,
			profileError: (state) => {
				return { type: state.alert.type, message: state.alert.message }
			},
			selectedProposal() {
				return find({ id: this.selectedProposalId }, this.currentUser.proposals)
			},
			requestModalOpen: (state) => state.parkings.requestModalOpen,
			deleteModalOpen: (state) => state.parkings.deleteModalOpen,
			activeLanguage: (state) => state.language,
			isChild: (state) => state.profile.isChild,

			rejectOptions() {
				const reasons = this.$i18n
					.t('profile.proposal.rejectReasons')
					.map((r, idx) => ({ key: idx, value: r }))
					.concat([{ key: 'other', value: this.$i18n.t('profile.proposal.otherRejectReason') }])

				return reasons
			},
			isAdminEdit: (state) => state.profile.isAdminEdit,
			userInAdmin: (state) => state.profile.userInAdmin,
			isAdminEditChild: (state) => state.profile.isAdminEdit && state.profile.userInAdmin.parentId,
			currentAddresses: (state) =>
				state.profile.isAdminEdit
					? state.profile.userInAdmin.addresses
					: state.profile.currentUser.addresses,

			// familyAccounts: state => state.profile.currentUser.children,
		}),

		...mapGetters('profile', {
			hasBigParkingSubscription: 'hasBigParkingSubscription',
			activeProposals: 'activeProposals',
			activeTemporaryAccess: 'activeTemporaryAccess',
			activeRequests: 'activeRequests',
			activeAddresses: 'activeAddresses',
		}),
	},
	watch: {
		'currentUser.proposals'(newVal) {
			if (newVal && newVal.length > 0) {
				// show parking proposal popup after loaded from server
				this.showProposalLanding()
			}
		},
		isChild(newVal) {
			if (newVal && this.currentUser.addresses.length === 0) {
				this.familyAccountInfoOpen = true
			}
		},
	},
	created() {},
	mounted() {
		if (this.isLoaded && !this.proposalModal) {
			this.showProposalLanding()
		}
	},
	methods: {
		...mapActions({
			// loadCurrentUser: 'getCurrentUser',
			updateMobib: 'profile/updateMobib',
			updateProfile: 'profile/updateProfile',
			rejectProposal: 'profile/rejectProposal',
			cancelSubscription: 'subscription/cancelSubscription',
			cancelRequestSubscription: 'subscription/cancelRequestSubscription',
			toggleRequestModal: 'parkings/toggleRequestModal',
			toggleDeleteModal: 'parkings/toggleDeleteModal',
			removeAddress: 'profile/removeAddress',
			logError: 'logError',
			backToParentAccount: 'profile/backToParentAccount',
			deleteFamilyAccount: 'profile/deleteFamilyAccount',
			exitAdminEdit: 'profile/exitAdminEdit',
		}),
		...mapMutations('subscription', {
			// selectProposalForSub: 'selectProposalForSub',
		}),

		doBackToParentAccount(e) {
			this.addressOpen = false

			if (e) {
				e.preventDefault()
			}

			return this.backToParentAccount()
		},
		doDeleteFamilyAccount(e) {
			if (e) {
				e.preventDefault()
			}

			this.deleteAccountModalLoading = true

			this.deleteFamilyAccount(this.currentUser.id).then(() => {
				this.deleteAccountModalLoading = false
				this.toggleDeleteAccountModal({ open: false })
			})
		},
		toggleEditProfile(e) {
			if (e) {
				e.preventDefault()
			}

			this.profileEditable = !this.profileEditable
		},
		toggleMobibModal(close = false) {
			if (close) {
				this.mobibModal = false
			} else {
				this.mobibModal = !this.mobibModal
			}
		},
		toggleProposalModal(id) {
			this.selectedProposalId = id
			this.proposalModal = !this.proposalModal
		},
		closeProposalModal() {
			this.proposalModal = false
		},
		closeConfirmProposalModal() {
			this.confirmProposalModal = false
		},

		// doAcceptConfirmProposal() {
		// 	this.confirmProposalModal = !this.confirmProposalModal
		// },
		// doDeclineConfirmProposal() {
		// 	this.confirmProposalModal = !this.confirmProposalModal
		// },
		toggleCancelSubModal(id) {
			this.selectedSubId = id
			this.cancelSubModal = !this.cancelSubModal
		},
		closeCancelSubModal() {
			this.cancelSubModal = false
		},

		async doUpdateMobib(number, action, type) {
			this.mobibLoading = true
			this.mobibType = type

			try {
				await this.updateMobib(number)
				this.mobibLoading = false
				action === 'remove'
					? this.flash(this.$t('flashMessage.removeMobib'), 'success')
					: this.flash(this.$t('flashMessage.addMobib'), 'success')
				this.toggleMobibModal(true)
			} catch (error) {
				// this.flash(this.$t('error.mobibUpdateFailed'), 'error')
				this.mobibLoading = false
				this.toggleMobibModal(true)
			}
		},

		doRejectProposal() {
			this.closeProposalModal()
			this.confirmProposalModal = true
		},

		async confirmRejectProposal(keep) {
			this.proposalLoading = true

			try {
				await this.rejectProposal({
					proposalId: this.selectedProposalId,
					keep,
					rejectReason:
						this.rejectReason.key === 'other' ? this.rejectReasonOther : this.rejectReason.value,
				})
			} catch (error) {
				this.logError(`Error while rejecting proposal ${this.selectedProposalId}`)
			} finally {
				this.confirmProposalModal = false
				this.proposalLoading = false
			}
		},

		async confirmCancelSub() {
			this.cancelSubLoading = true

			try {
				// await this.cancelSubscription(this.selectedSubId)
				await this.cancelRequestSubscription(this.selectedSubId)
			} catch (error) {
				this.logError(`Error while canceling subscription ${this.selectedSubId}`)
			} finally {
				this.cancelSubModal = false
				this.cancelSubLoading = false
			}
		},

		// doKeepProposal() {
		// 	this.confirmProposalModal = false
		// },

		doAcceptProposal() {
			// this.selectProposalForSub(this.selectedProposal)
			this.$router.push({
				name: 'subscription',
				params: {
					subType: this.selectedProposal.parking.parkingTypeName,
				},
				query: {
					proposal: this.selectedProposal.id,
				},
			})
		},

		async doUpdateProfile(inputs) {
			this.editLoading = true

			await this.updateProfile(inputs)

			if (this.valid) {
				// this.flash(this.$t('flashMessage.profileUpdate'), 'success')
				this.toggleEditProfile()
			}

			// this.flash(this.$t('error.profileUpdateFailed'), 'error')
			this.editLoading = false
		},

		address(proposal) {
			const parking = proposal.parking

			return parking.legacyAddress[this.activeLanguage.value] || parking.legacyAddress.en
		},

		addressType(proposal) {
			const request = find({ id: proposal.requestId }, this.currentUser.requests)

			const address = request ? request.address : null

			return address ? address.addressType : ''
		},

		showProposalLanding() {
			if (this.proposal) {
				const prop = find({ urlCode: this.proposal }, this.currentUser.proposals)

				if (prop) {
					this.toggleProposalModal(prop.id)
					this.$router.push({ name: 'profile' })
				}
			}
		},
		doOpenDeleteModal(id) {
			this.toDeleteAddressId = id
			this.toggleDeleteModal()
		},
		async doConfirmDeleteModal() {
			await this.removeAddress(this.toDeleteAddressId)
			this.toDeleteAddressId = ''
			this.toggleDeleteModal()
		},
		doCancelDeleteModal() {
			this.toDeleteAddressId = ''
			this.toggleDeleteModal()
		},
		toggleCreateAccountOpen(option = {}) {
			if ('open' in option) {
				this.createAccountOpen = option.open
			} else {
				this.createAccountOpen = !this.createAccountOpen
			}
		},

		toggleDeleteAccountModal(option = {}) {
			if ('open' in option) {
				this.deleteAccountModalOpen = option.open
			} else {
				this.deleteAccountModalOpen = !this.deleteAccountModalOpen
			}
		},
		toggleFamilyAccountInfo() {
			this.familyAccountInfoOpen = !this.familyAccountInfoOpen
		},
		toggleAddressForm() {
			this.addressOpen = !this.addressOpen
		},

		selectRejectReason(option) {
			this.rejectReason = this.rejectOptions.find((rejectReason) => rejectReason.key === option.key)
		},

		isDefined(v) {
			return typeof v !== 'undefined'
		},
		doExitAdminEdit(e) {
			if (e) {
				e.preventDefault()
			}

			this.exitAdminEdit()
		},
		subsToShow,
		date,
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
</style>
