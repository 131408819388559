<template>
	<div class="subscription-progress">
		<div
			:class="{ active: progress === 0, finished: progress > 0, blocked: progress === 2 }"
			class="subscription-progress-box"
			@click="switchStep(0)"
		/>
		<div
			:class="{ active: progress === 1, finished: progress > 1, blocked: progress === 2 }"
			class="subscription-progress-box"
			@click="switchStep(1)"
		/>
		<div
			:class="{ active: progress === 2 }"
			class="subscription-progress-box"
			@click="switchStep(2)"
		/>
	</div>
</template>

<script>
const lastStep = 2

export default {
	name: 'Progress',
	props: {
		progress: {
			type: Number,
			required: true,
			default: 0,
		},
	},
	data: () => ({}),
	methods: {
		switchStep(step) {
			if (step < this.progress && this.progress !== lastStep) {
				this.$emit('switch-step', step)
			}
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/subscription/progress';
</style>
