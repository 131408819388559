// import { mapState, mapMutations } from 'vuex'
import { logger } from '../logger'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    methods: {
        ...mapActions('session', {
            loadSessionById: 'getSessionById', // Fix the method name
        }),
    },
    beforeRouteEnter(to, from, next) {
        const sessionId = localStorage.getItem('sessionId');

        if (!sessionId) {
            console.error('Session ID not found in local storage');
            return next();
        }

        const numericSessionId = parseInt(sessionId, 10);

        if (isNaN(numericSessionId)) {
            console.error('Invalid session ID:', sessionId);
            return next();
        }

        const sessionProps = {
            sessionId: numericSessionId,
        }
        next((vm) => {
            vm.loadSessionById(sessionProps);
        });
    },
};

