<template>
	<div>
		<div class="temporaryAccess-wrapper wider">
			<div>
				<h1 class="title">
					{{ $t('temporaryAccess.start.title') }}
				</h1>
				<p class="text">
					{{ $t('temporaryAccess.start.text.part1') }}
					<span class="temporaryAccess-modal-text">{{ parking.name }}({{ parking.code }})</span>.
					<br />
					<br />
					{{ $t('temporaryAccess.start.text.part2') }}
				</p>
			</div>
			<form class="temporaryAccess-form center-div" @submit.prevent>
				<button
					:disabled="errors.any() || !canStart"
					:class="{ disabled: errors.any() || !canStart }"
					class="button blue temporaryAccess-form-next-button"
					@click="toggleConfirmationModal"
				>
					{{ $t('subscription.startLabel') }}
				</button>
			</form>
		</div>
		<Modal
			v-if="confirmationModal"
			:modalOpen="confirmationModal"
			:cancelLabel="$t('temporaryAccess.start.confirmation.cancelButton')"
			:confirmLabel="$t('temporaryAccess.start.confirmation.confirmButton')"
			:canConfirm="iAmReady && termsAccepted"
			@confirm="confirmStart"
			@cancel="closeConfirmationModal"
			@close="closeConfirmationModal"
		>
			<template>
				<div>
					<h1 class="small-title">{{ $t('temporaryAccess.start.confirmation.title') }}</h1>
					<div class="temporaryAccess-modal-parking-info">
						<p class="temporaryAccess-modal-text">
							<span class="black">Parking:</span> {{ parking.name }} ({{ parking.code }})
						</p>
						<p class="temporaryAccess-modal-text">
							{{ parkingAddress }}
						</p>
						<p class="temporaryAccess-modal-text warning">
							{{ $t('temporaryAccess.start.confirmation.message') }}
						</p>
						<p v-if="hasCreditCard" class="temporaryAccess-modal-text warning">
							{{ $t('temporaryAccess.start.confirmation.paymentWithCreditCard') }}
						</p>
						<p v-else class="temporaryAccess-modal-text warning">
							{{ $t('temporaryAccess.start.confirmation.paymentWithoutCreditCard.part1') }}
							<span class="link" @click="submitUpdatePaymentMethod">{{
								$t('temporaryAccess.start.confirmation.paymentWithoutCreditCard.yourCcLink')
							}}</span>
							{{ $t('temporaryAccess.start.confirmation.paymentWithoutCreditCard.part3') }}
							{{ $t('temporaryAccess.start.confirmation.ifNoCreditCard') }}
						</p>
						<Checkbox v-model="iAmReady" name="iAmReady" class="temporaryAccess-modal-checkbox">
							<span slot="label" class="checkbox-text text">
								{{ $t('temporaryAccess.start.confirmation.readyCheckBox') }}
							</span>
						</Checkbox>
						<Checkbox
							v-if="!userAlreadyHadATa"
							v-model="iAcceptTermsOfConditions"
							name="iAcceptTermsOfConditions"
							class="temporaryAccess-modal-checkbox"
						>
							<span slot="label" class="checkbox-text text">
								{{ $t('temporaryAccess.start.confirmation.temsOfConditionsCheckBox') }}
								<a :href="$t('general.navigationLinks.terms')" target="_blank" class="link">{{
									$t('general.navigation.terms')
								}}</a>
							</span>
						</Checkbox>
					</div>
				</div>
			</template>
			<template v-if="userAlreadyHadATa" #footer>
				<div>
					<a :href="$t('general.navigationLinks.terms')" target="_blank" class="link">{{
						$t('general.navigation.terms')
					}}</a>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Modal from '@/components/Modal.vue'
import { logger } from '@/logger'
import Checkbox from '@/components/form/Checkbox.vue'

export default {
	name: 'Start',
	components: {
		Modal,
		Checkbox,
	},
	props: {
		parking: {
			type: Object,
			required: true,
		},
		hasCreditCard: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			confirmationModal: false,
			iAmReady: false,
			iAcceptTermsOfConditions: false,
		}
	},
	computed: {
		...mapState({
			activeLanguage: (state) => state.language,
			userAlreadyHadATa: (state) => state.temporaryAccess.userAlreadyHadATa,
		}),
		...mapGetters('temporaryAccess', {
			getTemporaryAccessRediretionUrl: 'getTemporaryAccessRediretionUrl',
		}),
		canStart() {
			return true
		},
		parkingAddress() {
			return this.parking.legacyAddress[this.activeLanguage.value] || this.parking.legacyAddress.en
		},
		termsAccepted() {
			return this.iAcceptTermsOfConditions || this.userAlreadyHadATa
		},
	},
	methods: {
		...mapActions('temporaryAccess', {
			updatePaymentMethod: 'updatePaymentMethod',
		}),
		confirmStart() {
			this.closeConfirmationModal()
			this.$emit('start')
		},
		toggleConfirmationModal() {
			logger.debug("We are in: 'toggleConfirmationModal'")
			this.confirmationModal = !this.confirmationModal
		},
		closeConfirmationModal() {
			this.confirmationModal = false
			this.iAmReady = false
			this.iAcceptTermsOfConditions = false
		},
		async submitUpdatePaymentMethod() {
			const redirectUrl = `${this.getTemporaryAccessRediretionUrl}/${this.parking.code}`

			const url = await this.updatePaymentMethod(redirectUrl)

			window.location.href = url
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/temporaryAccess/temporaryAccess';
</style>
