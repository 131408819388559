import VeeValidate from 'vee-validate'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './languages/en.json'
import nl from './languages/nl.json'
import fr from './languages/fr.json'
import enValidate from 'vee-validate/dist/locale/en'
import nlValidate from 'vee-validate/dist/locale/nl'
import frValidate from 'vee-validate/dist/locale/fr'

Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: 'fr',
	fallbackLocale: 'en',
	silentTranslationWarn: true,
	messages: {
		en,
		nl,
		fr,
	},
})

Vue.use(VeeValidate, {
	i18nRootKey: 'validations', // Customize the root path for validation messages.
	i18n,
	dictionary: {
		en: enValidate,
		nl: nlValidate,
		fr: frValidate,
	},
	fieldsBagName: 'formFields',
})

export default i18n
