<template>
	<div :style="{ position: 'relative' }" class="admin-item-row invoice-items-row">
		<div class="admin-item-col small">
			{{ refundType() }}
		</div>
		<div class="admin-item-col smaller">€ {{ formattedAmount }}</div>
		<div class="admin-item-col small">
			{{ refundStatus() }}
		</div>
		<div class="admin-item-col">
			{{ item.description }}
		</div>
		<div class="admin-item-col small">
			{{ date(item.refundedAt) }}
		</div>
	</div>
</template>

<script>
import { invoiceItemTypes, REFUND_STATES } from '@/config'
import { findKey } from 'lodash/fp'
import { date } from '@/helpers'

export default {
	name: 'UserSubRefund',
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {}
	},
	computed: {
		formattedAmount() {
			if (this.item.mollieId !== null) {
				return (this.item.amount * 100).toFixed(2)
			} else if (
				this.item.mollieId === null &&
				this.item.stripeId === null &&
				this.item.amount < 1
			) {
				return (this.item.amount * 100).toFixed(2)
			} else {
				return this.item.amount.toFixed(2)
			}
		},
	},
	methods: {
		invoiceItemType(type) {
			const t = findKey((v) => v === type, invoiceItemTypes)

			return t
		},

		refundStatus() {
			return findKey((s) => s === this.item.status, REFUND_STATES)
		},

		refundType() {
			return this.item.stripeId ? 'Stripe' : this.item.mollieId ? 'Mollie' : 'Manual'
		},

		date,
	},
}
</script>

<style lang="scss" scoped>
// @import '../../assets/scss/pages/admin';
@import '../../assets/scss/components/info';
</style>
