<template>
	<div>
		<div class="temporaryAccess-wrapper">
			<div v-if="stillRunning">
				<h1 class="title">
					{{ $t('temporaryAccess.otherParking.stillRunning.title', { name: parking.name }) }}
				</h1>
				<p class="text" v-html="$t('temporaryAccess.otherParking.stillRunning.text')" />
				<p class="temporaryAccess-modal-text">
					<span class="black">Parking:</span> {{ parking.name }} ({{ parking.code }})
				</p>
				<p class="temporaryAccess-modal-text">
					{{ parkingAddress }}
				</p>
			</div>
			<div v-if="unpaid">
				<h1 class="title">
					{{ $t('temporaryAccess.otherParking.unpaid.title', { name: parking.name }) }}
				</h1>
				<p class="text" v-html="$t('temporaryAccess.otherParking.unpaid.text')" />
				<form class="temporaryAccess-form" @submit.prevent>
					<button
						:disabled="errors.any()"
						:class="{ disabled: errors.any() }"
						class="button blue temporaryAccess-form-next-button"
						@click="goToActiveTemporaryAccess"
					>
						{{ $t('temporaryAccess.otherParking.unpaid.showMe') }}
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { TEMPORARY_ACCESS_STATUSES } from '@/config'

/* import { logger } from '@/logger' */

export default {
	name: 'OtherParking',
	components: {},
	props: {
		parking: {
			type: [Object, Boolean],
			required: true,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapState({
			activeLanguage: (state) => state.language,
			status: (state) => state.temporaryAccess.currentTemporaryAccess.status,
		}),
		unpaid() {
			return [TEMPORARY_ACCESS_STATUSES.ENDED, TEMPORARY_ACCESS_STATUSES.INVOICED].includes(
				this.status
			)
		},
		stillRunning() {
			return !this.unpaid
		},
		parkingAddress() {
			return this.parking.legacyAddress[this.activeLanguage.value] || this.parking.legacyAddress.en
		},
	},
	methods: {
		goToActiveTemporaryAccess() {
			this.$emit('goToTa')
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/temporaryAccess/temporaryAccess';
</style>
