<template>
 
       
        
    <div class="main">
           <h1 class="h1">Information de paiement</h1>
    
   
           
     
   
           <div style="display: flex; flex-direction: row;align-items:center; 
           justify-content: space-between; border: 1px solid yellow; padding: 5px;
           box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
           ">
                <span>
                   <input type="radio" name="payment_method" checked> Défaut
               </span>
               <div style="display: flex;">
                   <img src="../../assets//images/session/icon_payment_visa.svg" alt="Add Circle Icon" class="icon-svg">
                   <p>**** **** **** 7890</p>
               </div> 
           </div>
           <a class="supprimer">supprimer</a>
           
   
           <div style="display: flex; flex-direction: row;align-items:center; 
           border: 1px solid rgb(226, 222, 222); padding: 5px;
           box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); margin-top: 10px; 
          justify-content:  space-between;"> 
               <span style="display: flex;">
                   <input type="radio" name="payment_method" checked> 
               </span>
               <div style="display: flex;">
                   <img src="../../assets//images/session/icon_payment_mastercard.svg" alt="Add Circle Icon" class="icon-svg">
                   <p>**** **** **** 7875</p>
               </div>
               
           </div>
           <a class="supprimer">supprimer</a>
           <hr>
          
           <div>
           <MollieStep2 :dataFromParent="parentData" :backgroundColor="parentBackgroundColor"/>
        </div>
   
        
           <div style="display: flex; justify-content: center; margin-top: 8rem;">
               <button>Commencer la sesion</button> 
           </div>
           </div>
       
   
   
   </template>
   
   <script>
   import MollieStep2 from '../../components/subscription/MollieStep2.vue'
   export default {
   name: "sessionNewPaymentMethod",
   components: {
        MollieStep2,
	},
   data() {
    return {
            parentData: 'session-addpayment-method',
           
    
      parentBackgroundColor: '#ffd022' // You can make this dynamic based on certain conditions
 
		}
   },
   methods: {
   
   },
   }
   </script>
   
   <style lang="scss" scoped>
   @import '../../assets/scss/pages/session/index';
    
   .main {
       display: flex;
       flex-direction: column;
       /* border: 0.5px solid rgb(224, 215, 215); */
       padding: 15px;
       width: 25%;
       margin-left: auto;
       margin-right: auto;
       /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
   
        
       @media (max-width: 768px) {
           width: 20rem;  
       }
   }
   
   .h1 {
       color: #1B388A;
       text-align: center;
   }
   
    
   button {
       padding: 10px 20px;
       background-color: #E75131; /* Green */
       border: none;
       color: white;
       text-align: center;
       text-decoration: none;
       display: inline-block;
       justify-content: center;
       font-size: 16px;
       margin: 4px 2px;
       width: 100%;
       cursor: pointer;
       border-radius: 5px;
       box-shadow: -5px 5px 0px 0px rgba(182, 175, 175, 0.5); /* Black shadow with x-offset -5px, y-offset 5px, and blur radius 0px */
   }
   .supprimer{
    text-decoration: underline;
    text-align: right;
   }
   </style>