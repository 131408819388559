<template>
	<Accordion :title="$t('profile.familyAccountsLabel')" @click="toggleAccordion(1)" :isOpen="true">
		<div class="profile-family-accounts">
			<!-- <h1 class="profile-family-accounts-title title">
				{{ $t('profile.familyAccountsLabel') }}
			</h1> -->
			<div class="profile-family-accounts-list">
				<div
					v-for="user in familyAccounts"
					:key="user.id"
					:class="{ active: user.id === currentUser.id }"
					class="profile-family-accounts-list-item"
					@click="doSwitchAccount(user.id)"
				>
					<div class="profile-family-accounts-list-item-icon user" />
					<div v-if="showWarningIcon(user)" class="profile-family-accounts-list-item-warning" />
					<p class="profile-family-accounts-list-item-text">
						{{ user.firstName + ' ' + user.lastName }}
					</p>
					<!-- <p class="profile-family-accounts-list-item-text">
						{{ user.lastName }}
					</p> -->
				</div>
				<div class="profile-family-accounts-list-cta" @click="$emit('toggleForm', { open: true })">
					<!-- <div class="profile-family-accounts-list-item-icon add" />
					<p class="profile-family-accounts-list-item-text"> -->
					{{ $t('profile.AddFamilyAccount') }}
					<!-- </p> -->
				</div>
			</div>
		</div>
	</Accordion>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Accordion from '@/components/Accordion.vue'

export default {
	name: 'FamilyAccounts',
	components: {
		Accordion,
	},
	props: {
		familyAccounts: {
			type: Array,
			required: true,
			default: () => [],
		},
		toggleAccordion: {
			type: Function,
		},
	},
	data() {
		return {
			// profileLocal: Object.assign({}, this.profile),
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
		}),
	},
	methods: {
		...mapActions({
			switchAccount: 'profile/switchAccount',
		}),
		doSwitchAccount(id) {
			if (id !== this.currentUser.id) {
				this.$emit('onSwitchAccount')
				this.switchAccount(id)
			}
		},
		showWarningIcon(user) {
			return (
				(user.activeProposals && user.activeProposals.length > 0) || user.addresses.length === 0
			)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/profile/familyAccounts';
</style>
