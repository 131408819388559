<template>
	<div class="maps">
		<RequestModal
			v-if="requestModalOpen"
			:loading="false"
			:modalOpen="requestModalOpen"
			:shouldSelectLocation="true"
			:addresses="currentUser.addresses"
			:activeAddresses="activeAddresses"
			@close="toggleRequestModal"
		/>
		<l-map
			v-if="parkings.length"
			ref="map"
			:center="center"
			:zoom="zoom"
			:options="{ zoomControl, maxZoom }"
			:class="{ 'details-open': detailsOpen }"
			class="maps-map"
			@update:zoom="zoomUpdated"
			@update:center="centerUpdated"
			@ready="onReady"
		>
			<l-control-zoom position="bottomright" />
			<l-tile-layer :url="url" :attribution="attribution" :options="{ maxZoom }"></l-tile-layer>
			<v-marker-cluster :options="clusterOptions">
				<div v-if="checkAddressMarkers()">
					<l-marker
						v-for="(parking, index) in addressMarkers"
						:key="`address-marker-${index}`"
						:latLng="getLatLng(parking.position)"
						:icon="getMarkerOptions(parking, { small: true })"
					/>
				</div>
				<div v-for="(parking, index) in getParkings(true)" :key="`polyline-${index}`">
					<l-circle
						:center="parking.lineTo"
						:radius="3"
						:visible="true"
						:options="{
							fillColor: '#E75130',
							strokeColor: '#ffffff',
							strokeWeight: 1.5,
							strokeOpacity: 1,
							fillOpacity: 1.0,
							zIndex: 5,
						}"
					/>
					<l-polyline
						:path="[parking.position, parking.lineTo]"
						:options="{ strokeColor: parking.lineColor }"
					/>
				</div>
				<l-marker
					v-for="(parking, index) in getParkings()"
					ref="marker"
					:key="`parkings-${index}`"
					:latLng="getLatLng(parking.position)"
					:icon="getMarkerOptions(parking, { small: true })"
					@mousedown="onParkingClick($event, parking, index)"
					@mouseover="toggleDetails(parking.id)"
					@mouseout="toggleDetails(parking.id)"
				>
					<l-icon :iconUrl="parking.iconUrl" :iconSize="iconSize" :iconAnchor="iconAnchor" />
				</l-marker>
			</v-marker-cluster>
		</l-map>
		<div :class="{ 'details-open': detailsOpen }" class="maps-search-container">
			<button class="button orange maps-request-button" @click="startRequest">
				{{ $t('parkings.requestButtonLabel') }}
			</button>
		</div>
		<Details :preMarker="preMarker" />
		<div
			v-if="parkingTypes.length > 0"
			:class="{ 'details-open': detailsOpen }"
			class="maps-legend"
		>
			<div v-show="!legendOpen" class="maps-legend-icon" @click="toggleLegend">
				{{ $t('parkings.legendLabel') }}
			</div>
			<div v-show="legendOpen" class="maps-legend-info">
				<div class="maps-legend-info-title" @click="toggleLegend">
					{{ $t('parkings.legendLabel') }}
					<div class="maps-legend-info-close" />
				</div>
				<div class="maps-legend-info-types">
					<div
						v-for="parkingType in parkingTypes"
						v-show="parkingType.loggedIn ? currentUser.id : true"
						:key="parkingType.type"
						class="maps-legend-info-row"
					>
						<Checkbox
							v-model="parkingTypeFilters[parkingType.type]"
							class="maps-legend-checkbox"
							:name="parkingType.label"
							@input="(data) => checkParkingType(data, parkingType.type)"
						></Checkbox>
						<img :src="parkingType.icon" class="maps-legend-info-row-image" />
						<p class="maps-legend-info-row-text">
							{{ $t(`parkings.parkingTypes.${parkingType.label}`) }}
						</p>
						<img
							src="../../assets/images/icon_new_small.svg"
							v-if="parkingType.isNew"
							class="maps-legend-icon-new"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/assets/css/leaflet.css'
import L, { Icon } from 'leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { LMap, LTileLayer, LControlZoom, LMarker, LIcon, LCircle, LPolyline } from 'vue2-leaflet'
import 'leaflet.smooth_marker_bouncing/dist/bundle.js'
import { mapActions, mapState, mapGetters } from 'vuex'
import Details from './Details'
import Checkbox from '@/components/form/Checkbox.vue'
import RequestModal from '../RequestModal'
import { isNil, find } from 'lodash/fp'
import { logger } from '@/logger'
import { formatAddressObj } from '../../helpers'
const addressMarker = require('../../assets/images/marker-home-purple.svg')
const searchMarker = require('../../assets/images/map-search-icon.svg')
const iconNew = '../../assets/images/icon_new_medium.svg'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
	// eslint-disable-next-line global-require
	conRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	// eslint-disable-next-line global-require
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	// eslint-disable-next-line global-require
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
	name: 'Maps',
	components: {
		LMap,
		LIcon,
		LCircle,
		LMarker,
		LPolyline,
		LTileLayer,
		LControlZoom,
		Details,
		Checkbox,
		RequestModal,
		'v-marker-cluster': Vue2LeafletMarkerCluster,
	},
	props: {
		parkings: {
			type: Array,
			required: false,
			default: () => [],
		},
		focus: {
			type: Number,
			required: false,
			default: undefined,
		},
	},
	data() {
		return {
			map: null,
			url: 'https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=1Maxych6Jo2JVwiZy6dfxAuFjgcKtut88zwwllEL6ZweemGt78XMNZx0Esbznzn5',
			attribution:
				'<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank" class="jawg-attrib">&copy; <b>Jawg</b>Maps</a> | <a href="https://www.openstreetmap.org/copyright" title="OpenStreetMap is open data licensed under ODbL" target="_blank" class="osm-attrib">&copy; OSM contributors</a>',
			center: [50.84797, 4.350369],
			zoom: 12,
			maxZoom: 16,
			zoomControl: false,
			marker: null,
			preMarker: null,
			searchMarker: null,
			detailsMarker: false,
			addressMarkers: [],
			parkingTypeFilters: [],
			localParkings: [],
			iconSize: [32, 37],
			iconAnchor: [16, 37],
			clusterOptions: {
				showCoverageOnHover: false,
				iconCreateFunction: (cluster) => {
					const markers = cluster.getAllChildMarkers()
					let n = 0

					for (let i = 0; i < markers.length; i++) {
						n += 1
					}

					let className = 'mycluster56'
					let size = 56

					if (n >= 50 && n < 100) {
						className = 'mycluster66'
						size = 66
					}

					if (n >= 100 && n < 500) {
						className = 'mycluster78'
						size = 78
					}

					if (n >= 500) {
						className = 'mycluster90'
						size = 90
					}

					return L.divIcon({
						html: `${cluster.getChildCount()}`,
						className,
						iconSize: L.point(size, size),
					})
				},
			},
			bouncingOptions: {
				bounceHeight: 30,
				bounceSpeed: 30,
				exclusive: true,
			},
			bouncingMotion: {
				marker: null,
				position: null,
				bouncingOptions: null,
			},
			searchControl: new GeoSearchControl({
				provider: new OpenStreetMapProvider({
					searchUrl: process.env.VUE_GEOCODE_HOST || 'https://geocoding.agifly.cloud/search',
					params: {
						'accept-language': 'fr', // render results in French
						countrycodes: 'be', // limit search results to the Belgium
						addressdetails: 1, // include additional address detail parts
					},
				}),
				resultFormat: ({ result }) => {
					const address = result?.raw?.address
					return formatAddressObj(address)
				},
				autoComplete: true,
				autoCompleteDelay: 200,
				marker: {
					icon: new L.icon({
						// eslint-disable-next-line global-require
						iconUrl: require('leaflet/dist/images/marker-icon.png'),
						className: 'bounced',
					}),
					draggable: false,
				},
				searchLabel: this.$i18n.t('parkings.searchPlaceholder'),
				style: 'bar',
			}),
		}
	},
	computed: {
		...mapState({
			parkingTypes: (state) => state.parkings.parkingTypes,
			legendOpen: (state) => state.parkings.legendOpen,
			detailsOpen: (state) => state.parkings.detailsOpen,
			parkingDetails: (state) => state.parkings.details,
			currentUser: (state) => state.profile.currentUser,
			requestModalOpen: (state) => state.parkings.requestModalOpen,
			isLoggedIn: (state) => state.authentication.isLoggedIn,
			activeLanguage: (state) => state.language,
		}),
		...mapGetters('profile', {
			activeAddresses: 'activeAddresses',
		}),
	},
	async mounted() {
		const parkingTypeFilters = this.$route.query.typeFilter?.split(',')
		if (parkingTypeFilters && parkingTypeFilters.length) {
			this.parkingTypeFilters.push(...parkingTypeFilters)
			parkingTypeFilters.forEach((filter) => {
				this.parkingTypeFilters[filter] = true
			})
		}
	},

	watch: {
		parkings() {
			this.localParkings = JSON.parse(JSON.stringify(this.parkings))
		},
		detailsMarker(val) {
			if (val === false) {
				this.preMarker = null
			}
		},
	},
	methods: {
		// receives a place object via the autocomplete component
		...mapActions({
			toggleDetails: 'parkings/toggleDetails',
			toggleLegend: 'parkings/toggleLegend',
			setDetails: 'parkings/setDetails',
			tapMarker: 'parkings/tapMarker',
			mapsError: 'alert/error',
			loadCurrentUser: 'profile/getCurrentUser',
			toggleRequestModal: 'parkings/toggleRequestModal',
		}),
		onReady() {
			let jwagUrl = 'https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token='
			jwagUrl = jwagUrl + process.env.VUE_APP_JAWG_API_KEY
			this.url = jwagUrl
			setTimeout(async () => {
				this.map = this.$refs.map.mapObject

				const myIcon = new L.icon({
					// eslint-disable-next-line global-require
					iconUrl: require('leaflet/dist/images/marker-icon.png'),
					iconSize: [38, 95],
					iconAnchor: [22, 94],
					popupAnchor: [-3, -76],
					// eslint-disable-next-line global-require
					shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
					shadowSize: [68, 95],
					shadowAnchor: [22, 94],
				})

				logger.info(
					'logged in? :',
					this.isLoggedIn,
					this.currentUser.id,
					isNil(this.currentUser.id)
				)
				if (isNil(this.currentUser.id) && this.isLoggedIn) {
					logger.info('You are not logged in')
					await this.loadCurrentUser()
					this.setHomeMarkers()
				} else if (this.isLoggedIn) {
					logger.info('You are logged in')
					this.setHomeMarkers()
				}

				if (this.focus) {
					this.setPlace({ name: this.getAddressName(this.focus) })
				}

				if (this.parkings?.length > 0) {
					this.localParkings = JSON.parse(JSON.stringify(this.parkings))
				}
				this.map.invalidateSize()
				new GeoSearchControl({
					// Configure options for the geosearch control
					provider: new OpenStreetMapProvider({
						searchUrl: 'https://geocoding.agifly.cloud/search',
						params: {
							'accept-language': 'fr', // render results in French
							countrycodes: 'be', // limit search results to the Belgium
							addressdetails: 1, // include additional address detail parts
						},
					}),
					resultFormat: ({ result }) => {
						const address = result?.raw?.address
						return formatAddressObj(address)
					},
					autoComplete: true,
					autoCompleteDelay: 200,
					style: 'bar',
					searchLabel: this.$i18n.t('parkings.searchPlaceholder'),
					marker: {
						icon: new L.icon({
							// eslint-disable-next-line global-require
							// iconUrl: require('leaflet/dist/images/marker-icon.png'),
							iconUrl: searchMarker,
							iconSize: [32, 48],
							iconAnchor: [16, 38],
						}),
						draggable: false,
					},
					// Add other options as needed
				}).addTo(this.map)

				this.map.on('geosearch/showlocation', (data) => {
					if (!this.searchMarker) {
						logger.debug('Adding New Marker: ', data)
						this.searchMarker = data.marker
						// data.marker._icon.style.webkitFilter = 'hue-rotate(145deg)'

						// data.marker.bounce(2);
					} else {
						logger.debug('Updating New Marker: ', this.searchMarker)
						this.searchMarker.setLatLng(data.latlng)
						// this.searchMarker.marker._icon.style.webkitFilter = 'hue-rotate(145deg)'
					}
				})

				// to focus parking from link query parameter
				if (this.$route.query.parkingId) {
					let parkings = this.getParkings()
					let parkingIndex = parkings.findIndex(
						(parking) => parking.id == this.$route.query.parkingId
					)
					if (parkingIndex != -1) {
						setTimeout(() => {
							let latlng = this.getLatLng(parkings[parkingIndex].position)
							this.onParkingClick(
								{ focusParking: true, latlng, target: { latlng } },
								parkings[parkingIndex],
								parkingIndex
							)
						}, 1000)
					}
				}
			}, 1000)
		},
		zoomUpdated(zoom) {
			this.zoom = zoom
		},
		centerUpdated(center) {
			this.center = center
		},
		onParkingClick(e, parking, index) {
			if (this.preMarker) {
				this.preMarker.stopBouncing()
			}
			this.bouncingMotion.marker = parking
			this.bouncingMotion.position = e.target.latlng
			this.bouncingMotion.bouncingOptions = this.bouncingOptions
			this.detailsMarker = true
			this.$refs.map.mapObject.setView(e.latlng, this.maxZoom)
			this.preMarker = this.$refs.marker[index].mapObject
			const clickType = e.focusParking
				? 'mousedown'
				: Object.values(e).find(
						(p) =>
							p instanceof window.MouseEvent ||
							(window.TouchEvent && p instanceof window.TouchEvent)
				  ).type

			this.map.eachLayer((layer) => {
				if (layer instanceof L.Marker) {
					if (
						layer._latlng.lng === parking.position.lng &&
						layer._latlng.lat === parking.position.lat
					) {
						layer.bounce()
					}
				}
			})

			if (clickType === 'mousedown') {
				this.setDetails(parking)
			} else {
				this.tapMarker(parking)
			}
		},
		getLatLng(position) {
			const latlng = [position.lat, position.lng]

			return latlng
		},
		getMarkerOptions(parking) {
			const newIcon = L.icon({
				iconUrl: parking.iconUrl,
				iconSize: [45, 66],
				iconAnchor: [36, 72],
			})

			return newIcon
		},
		position(parking) {
			return { lat: parking.latitude, lng: parking.longitude }
		},
		fullName(parking) {
			return `${parking.code} - ${parking.name} - ${
				parking.address[this.activeLanguage.value] || parking.address.en
			}`
		},
		calculator(markers, numStyles) {
			let index = 0
			const count = markers.length
			let total = count
			const divider = 5

			while (total !== 0) {
				total = parseInt(total / divider, 10)
				/* eslint-disable-next-line */
				index++
			}

			index = Math.min(index, numStyles)

			return {
				text: count,
				index,
			}
		},
		setPlace(place) {
			this.detailsMarker = false
			this.$refs.mapAutocomplete.$refs.input.blur()
			this.$refs.map.$mapObject.then((map) => {
				if (place.geometry) {
					this.marker = new this.google.maps.Marker({
						position: place.geometry.location,
						map,
						title: place.formatted_address,
					})

					map.setCenter(place.geometry.location)
					map.setZoom(this.maxZoom)
				}
			})
		},
		focusAutocomplete() {
			if (this.marker && !this.detailsMarker) {
				this.marker.setMap(null)
			}
		},
		startRequest() {
			if (this.isLoggedIn) {
				this.toggleRequestModal()
			} else {
				this.$router.push({ name: 'login' })
			}
		},
		setHomeMarkers() {
			logger.info('address: ', this.currentUser)
			this.currentUser.addresses.forEach(async (address) => {
				const latitude = address.latitude
				const longitude = address.longitude
				const homeAddress = {}
				homeAddress.position = Object.assign({}, { lat: latitude, lng: longitude })
				homeAddress.iconUrl = addressMarker
				this.addressMarkers.push(homeAddress)
			})
		},
		// setHomeMarkers() {
		// 	this.currentUser.addresses.forEach(async (address) => {
		// 		const addressName = `${address.streetName} ${address.houseNumber} ${address.city} ,Belgium`
		// 		const response = await fetch(
		// 			`https://api.openrouteservice.org/geocode/search?
		// 				api_key=5b3ce3597851110001cf62480a855d77031c43538bc8553cf7fbff18&
		// 				text=${addressName}&boundary.country=BEL`,
		// 			{
		// 				method: 'GET',
		// 			}
		// 		)
		// 		const data = await response.text()
		// 		if (data.length && response.status === 200) {
		// 			const location = JSON.parse(data).features[0]
		// 			logger.info('Geocoding result', location)
		// 			const homeAddress = {}
		// 			homeAddress.position = Object.assign(
		// 				{},
		// 				{ lat: location.geometry.coordinates[1], lng: location.geometry.coordinates[0] }
		// 			)
		// 			homeAddress.iconUrl = addressMarker
		// 			this.addressMarkers.push(homeAddress)
		// 		}
		// 	})
		// },
		getAddressName(id) {
			const address = find({ id }, this.currentUser.addresses)

			return `${address.streetName} ${address.houseNumber}, ${address.postalCode} ${address.city}`
		},
		checkParkingType(showParkingType, parkingType) {
			if (showParkingType) {
				this.parkingTypeFilters.push(parkingType)
			} else {
				const index = this.parkingTypeFilters.indexOf(parkingType)

				if (index > -1) {
					this.parkingTypeFilters.splice(index, 1)
				}
			}
		},
		getParkings(lineFilter = false) {
			const tempParkings = lineFilter
				? this.localParkings.filter((parking) => parking.line)
				: this.localParkings

			if (this.parkingTypeFilters.length > 0) {
				return tempParkings.filter(
					(parking) =>
						this.parkingTypeFilters.indexOf(parking.parkingTypeName) > -1 ||
						(parking.sessionConfig && this.parkingTypeFilters.indexOf('ParkingLaSession') > -1)
				)
			} else {
				return tempParkings
			}
		},
		checkAddressMarkers() {
			if (this.parkingTypeFilters.length > 0) {
				return this.parkingTypeFilters.includes('homeAddress') && this.addressMarkers.length > 0
			} else {
				return this.addressMarkers.length > 0
			}
		},
	},
}
</script>
<style lang="scss">
@import '../../assets/scss/pages/parkings/maps';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.leaflet-control-geosearch button.reset {
	display: none !important;
}
.leaflet-geosearch-bar {
	//position: absolute !important;
	width: calc(100% - 20px);
	max-width: 500px;
	height: 50px !important;
	border-radius: 0 0 0 0;
	margin: 0 !important;
	// box-shadow: 0px 1px 5px 0px;
	left: 10px;
	top: 10px;
}
.leaflet-touch .leaflet-geosearch-bar form {
	border: none;
	box-shadow: 3px 0 7px 2px #aaa;
	line-height: 50px;
}
.leaflet-control-geosearch form {
	border-radius: 0 0px 0px 0;
	font-size: 16px;
	font-family: Arial, Helvetica, sans-serif;
}
.leaflet-control-geosearch form input {
	min-width: 200px;
	width: 100%;
	outline: none;
	border: none;
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: Arial, Helvetica, sans-serif;
	height: 50px;
	border: none;
	border-radius: 0 0px 0px 0;
	text-indent: 10px;
}

.mycluster56 {
	display: grid;
	align-items: center;
	border-radius: 28px;
	background-color: #258ac9;
	text-align: center;
	font-size: 11px;
	font-family: Arial, sans-serif;
	box-sizing: border-box;
	color: white !important;
}
.mycluster66 {
	display: grid;
	align-items: center;
	border-radius: 33px;
	background-color: #258ac9;
	text-align: center;
	font-size: 11px;
	font-family: Arial, sans-serif;
	box-sizing: border-box;
	color: white !important;
}
.mycluster78 {
	display: grid;
	align-items: center;
	border-radius: 39px;
	background-color: #258ac9;
	text-align: center;
	font-size: 11px;
	font-family: Arial, sans-serif;
	box-sizing: border-box;
	color: white !important;
}
.mycluster90 {
	display: grid;
	align-items: center;
	border-radius: 45px;
	background-color: #258ac9;
	text-align: center;
	font-size: 11px;
	font-family: Arial, sans-serif;
	box-sizing: border-box;
	color: white !important;
}
.animation {
	animation: click 1.3s ease-in-out infinite alternate;
	@keyframes click {
		from {
			transform: translate3d(0, 0, 0);
		}
		to {
			transform: translate3d(0, -6px, 0);
		}
	}
}
//correct way to add a css class to leaflet marker icon
.leaflet-marker-icon.selectedMarker {
	animation: shake 3 timing-function delay iteration-count direction fill-mode;
}
.bounced {
	animation: shake 1.3s;
	animation-iteration-count: infinite;
}
@keyframes shake {
	0% {
		transform: translate(0px, 0px);
	}
	50% {
		transform: translate(0px, 35px);
	}
	100% {
		transform: translate(0px, -35px);
	}
}
</style>
