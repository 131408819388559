<template>
	<div>
		<Loading v-if="isLoading" />
		<div v-if="!isLoading" class="renew-request">
			<div v-if="!error">
				<div v-if="requestRenewed">
					<p class="feedback">
						{{ $t('request.renew.feedback.renewed') }}
					</p>
				</div>
				<div v-if="requestCanceled">
					<p class="feedback">
						{{ $t('request.renew.feedback.cancelled') }}
					</p>
				</div>
				<div v-if="!requestRenewed && !requestCanceled">
					<h1 class="title">
						{{ $t('request.renew.title') }}
					</h1>
					<p class="text reset-pw-info">
						{{ $t('request.renew.message.part1') }}
						{{ date(request.requestedAt) }}
						{{ $t('request.renew.message.part2') }}
						{{
							request.isCargo
								? $t('request.renew.message.cargo')
								: $t('request.renew.message.standard')
						}}
						{{ $t('request.renew.message.part3') }}
					</p>
					<p class="text reset-pw-info">
						{{ $t('request.renew.message.part4') }}
						{{ date(request.renewalLimitDate) }}
						{{ $t('request.renew.message.part5') }}
					</p>
					<div v-if="!isLoading" class="renew-request-actions">
						<button
							:disabled="isLoading"
							:class="{ disabled: isLoading }"
							class="button blue"
							@mousedown="renew"
						>
							{{ $t('request.renew.renewIt') }}
						</button>
						<button
							:disabled="isLoading"
							:class="{ disabled: isLoading }"
							class="button red"
							@mousedown="cancel"
						>
							{{ $t('request.renew.cancelIt') }}
						</button>
					</div>
				</div>
			</div>
			<div v-else>
				<p class="error">
					{{ error }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { date } from '@/helpers'
import Loading from '@/components/Loading.vue'

export default {
	name: 'RenewRequest',
	components: {
		Loading,
	},
	data() {
		return {
			local: {
				errors: {
					password: '',
					passwordConfirmation: '',
				},
				password: {
					value: '',
					reveal: false,
				},
				passwordConfirmation: {
					value: '',
					reveal: false,
				},
			},
		}
	},
	computed: {
		...mapState({
			alert: (state) => state.alert,
			isLoading: (state) => state.requests.loading,
			error: (state) => state.requests.error,
			request: (state) => state.requests.request,
			requestFetched: (state) => state.requests.requestFetched,
			requestRenewed: (state) => state.requests.requestRenewed,
			requestCanceled: (state) => state.requests.requestCanceled,
		}),
		renewCode() {
			return this.$route.params.code
		},
	},
	async created() {
		if (this.$route.params.code) {
			if (!this.requestFetched) {
				await this.getRequest(this.renewCode)
			}
		}
	},
	methods: {
		...mapActions({
			getRequest: 'requests/getRequest',
			renewRequest: 'requests/renew',
			cancelRequest: 'requests/cancel',
		}),

		async renew() {
			await this.renewRequest(this.renewCode)
		},
		async cancel() {
			await this.cancelRequest(this.renewCode)
		},
		date,
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/components/renewRequest.scss';
</style>
