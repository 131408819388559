<template>
	<div>
		<div class="temporaryAccess-wrapper">
			<h1 class="title">
				{{ $t('temporaryAccess.canceled.title') }}
			</h1>
			<p class="text">{{ $t('temporaryAccess.canceled.text.part1') }}</p>
			<p class="text">{{ $t('temporaryAccess.canceled.text.part2') }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Canceled',
	components: {},
	props: {},
	data() {
		return {}
	},
	computed: {},
	methods: {},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/temporaryAccess/temporaryAccess';
</style>
