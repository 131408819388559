<template>
	<div v-if="showModal" class="modal">
		<div class="modal-content">
			<span class="close" @click="closeModal"
				><img src="./../assets/images/icon_close_red.svg"
			/></span>
			<slot name="title"></slot>
			<slot name="content"></slot>
			<div class="button-container">
				<slot name="confirmButton"></slot>
				<slot name="cancelButton"></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SessionModal',
	props: {
		showModal: Boolean,
	},
	methods: {
		closeModal() {
			this.$emit('close')
		},
		confirmAction() {
			this.$emit('confirm')
		},
	},
}
</script>

<style scoped>
/* Styles for desktop */
.modal {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal-content {
	background-color: #ffd022;
	padding: 20px;
	position: relative;
	border-radius: 5px;
	width: 303px;
}

.modal-content h1,
.modal-content h2 {
	font-size: 32px;
	line-height: 40px;
	font-family: 'Poppins Bold';
	color: #3c3c3b;
	margin: 0px;
	overflow-wrap: break-word; /* Allow long words to wrap */
}

.modal-content p {
	font-size: 16px;
	line-height: 24px;
	font-family: 'Poppins Regular';
	color: #3c3c3b;
	margin: 8px 0px 0px 0px;
}

.modal-content .alert-message {
	display: flex;
	align-items: center;
	margin-top: 24px;
}

.modal-content .alert-message span {
	font-size: 16px;
	line-height: 24px;
	font-family: 'Poppins Regular';
	color: #3c3c3b;
}
.close {
	position: absolute;
	top: -12px;
	right: -12px;
	cursor: pointer;
	width: 24px;
	height: 24px;
	/*border-radius: 50%;
	background-color: #e75130;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold; */
}

.button-container {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.button-container .cancel {
	cursor: pointer;
}

button {
	padding: 10px 20px;
	margin: 5px 0;
	background-color: #e75131;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border-radius: 5px;
	box-shadow: -5px 5px 0 0 rgba(0,0,0,.1607843137254902);
	font-family: 'Poppins Bold';
	font-size: 16px;
	line-height: 25px;
}

p {
	overflow-wrap: break-word; /* Allow long words to wrap */
}
.cancel {
	text-align: center;
	color: #e75131;
	font-weight: bold;
	/* font-family: 'Bold'; */
	text-decoration: underline;
	margin-top: 5%;
}

.alert-img {
	position: relative;
	top: -15px;
	right: 5px;
}

/* Media query for mobile */
@media screen and (max-width: 768px) {
	.modal-content {
		width: 303px; /* Adjust maximum width for mobile devices */
	}
}
</style>
