<template>
	<div>
		<div class="temporaryAccess-wrapper">
			<h1 class="title">
				{{ $t('temporaryAccess.invoiced.title') }}
			</h1>
			<p class="text">
				{{ $t('temporaryAccess.invoiced.text') }}
			</p>
			<p class="temporaryAccess-modal-text">
				<span class="black">Parking:</span> {{ parking.name }} ({{ parking.code }})
			</p>
			<p class="temporaryAccess-modal-text">
				{{ parkingAddress }}
			</p>
			<p class="temporaryAccess-modal-text">
				<span class="black">{{ $t('temporaryAccess.invoiced.startedAt') }} </span>
				{{ startedAt }}
			</p>
			<p class="temporaryAccess-modal-text">
				<span class="black">{{ $t('temporaryAccess.invoiced.endedAt') }} </span>
				{{ endedAt }}
			</p>
			<p class="temporaryAccess-modal-text">
				<span class="black">{{ $t('temporaryAccess.invoiced.duration') }} </span>
				{{ duration }}
			</p>
			<p class="temporaryAccess-modal-text">
				<span class="black">{{ $t('temporaryAccess.invoiced.price') }} </span>
				{{ temporaryAccess.price }}€
			</p>
			<div v-if="notYetSent">
				<div v-if="hasCreditCard">
					<p
						class="text"
						v-html="
							$t('temporaryAccess.invoiced.ccPaymentFailed.part1', { price: temporaryAccess.price })
						"
					/>
					<div class="centerButtonWrapper">
						<button class="button blue centerButton" @click="submitUpdatePaymentMethod">
							{{ $t('temporaryAccess.invoiced.ccPaymentFailed.updateCcInfLink') }}
						</button>
					</div>
					<p class="text" v-html="$t('temporaryAccess.invoiced.ccPaymentFailed.part2')" />
					<div class="centerButtonWrapper">
						<button
							:disabled="!canRetry"
							:class="{ disabled: !canRetry }"
							class="button blue centerButton"
							@click="retryPayment"
						>
							<span v-if="canRetry">{{
								$t('temporaryAccess.invoiced.ccPaymentFailed.retryPaymentButton')
							}}</span>
							<span v-else>{{
								$t('temporaryAccess.invoiced.ccPaymentFailed.canRetryIn', {
									nbrOfSec: 0 - timeBeforeRetryEnable,
								})
							}}</span>
						</button>
					</div>
					<p class="text" v-html="$t('temporaryAccess.invoiced.ccPaymentFailed.part3')" />
					<div class="centerButtonWrapper">
						<button
							:disabled="!canRetry"
							:class="{ disabled: !canRetry }"
							class="button blue centerButton"
							@click="sendMeTheInvoice"
						>
							<span v-if="canRetry">{{
								$t('temporaryAccess.invoiced.ccPaymentFailed.sendMeTheInvoiceButton')
							}}</span>
							<span v-else>{{
								$t('temporaryAccess.invoiced.ccPaymentFailed.canSendInvoiceIn', {
									nbrOfSec: 0 - timeBeforeRetryEnable,
								})
							}}</span>
						</button>
					</div>
				</div>
				<div v-else>
					<p
						class="text"
						v-html="
							$t('temporaryAccess.invoiced.notYetSent.text', { price: temporaryAccess.price })
						"
					/>
				</div>
			</div>
			<div v-if="alreadySent">
				<p
					class="text"
					v-html="$t('temporaryAccess.invoiced.alreadySent.text', { price: temporaryAccess.price })"
				/>
				<p class="text" v-html="$t('temporaryAccess.invoiced.alreadySent.followTheWriteRabbit')" />
				<div class="centerButtonWrapper">
					<button class="button blue centerButton" @click="payNow">
						{{ $t('temporaryAccess.invoiced.alreadySent.button') }}
					</button>
				</div>
			</div>
			<div v-if="alreadyPaid">
				<p
					class="text"
					v-html="$t('temporaryAccess.invoiced.alreadyPaid.text', { price: temporaryAccess.price })"
				/>
				<p class="text" v-html="$t('temporaryAccess.invoiced.alreadyPaid.startAgain')" />
			</div>
			<div v-if="!alreadyPaid && !hasCreditCard">
				<p class="text" v-html="$t('temporaryAccess.invoiced.warning')" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import {
	TEMPORARY_ACCESS_STATUSES,
	NUMBER_OF_MILISEC_IN_A_DAY,
	NUMBER_OF_MILISEC_IN_AN_HOUR,
} from '@/config'
import { date } from '@/helpers'

/* import { logger } from '@/logger' */

export default {
	name: 'Invoiced',
	components: {},
	props: {
		parking: {
			type: [Object, Boolean],
			required: true,
		},
		temporaryAccess: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			timer: null,
			now: new Date(),
		}
	},
	watch: {
		timeBeforeRetryEnable(v) {
			if (v >= 0) {
				this.cancelAutoRefresh()
			}
		},
	},
	created() {
		this.timer = setInterval(this.refresh, 1000)
	},
	beforeDestroy() {
		this.cancelAutoRefresh()
	},
	computed: {
		...mapState({
			activeLanguage: (state) => state.language,
			endedTime: (state) => state.temporaryAccess.endedAt,
		}),
		...mapGetters('temporaryAccess', {
			getTemporaryAccessRediretionUrl: 'getTemporaryAccessRediretionUrl',
		}),
		hasCreditCard() {
			return this.temporaryAccess.payByCreditCard
		},
		notYetSent() {
			return this.temporaryAccess.status === TEMPORARY_ACCESS_STATUSES.ENDED
		},
		alreadySent() {
			return this.temporaryAccess.status === TEMPORARY_ACCESS_STATUSES.INVOICED
		},
		alreadyPaid() {
			return this.temporaryAccess.status === TEMPORARY_ACCESS_STATUSES.PAID
		},
		parkingAddress() {
			return this.parking.legacyAddress[this.activeLanguage.value] || this.parking.legacyAddress.en
		},
		startedAt() {
			return this.date(this.temporaryAccess.startedAt, { withHours: true })
		},
		endedAt() {
			return this.date(this.temporaryAccess.endedAt, { withHours: true })
		},
		duration() {
			const diff = this.temporaryAccess.endedAt - this.temporaryAccess.startedAt
			const nbrFullDays = Math.floor(diff / NUMBER_OF_MILISEC_IN_A_DAY)

			const reste = diff % NUMBER_OF_MILISEC_IN_A_DAY

			const nbrStartedHours = Math.floor(reste / NUMBER_OF_MILISEC_IN_AN_HOUR) + 1
			let duration = ''

			if (nbrFullDays > 0) {
				duration = `${nbrFullDays} days `
			}

			duration = `${duration}${nbrStartedHours} hour`

			if (nbrStartedHours > 1) {
				duration = `${duration}s`
			}

			return duration
		},
		timeBeforeRetryEnable() {
			const diff = this.now - this.temporaryAccess.endedAt

			const delayBeforeRetry = 15000 // 15sec
			const withDelay = parseInt((diff - delayBeforeRetry) / 1000, 10)

			return withDelay
		},
		canRetry() {
			return this.notYetSent && this.timeBeforeRetryEnable >= 0
		},
	},
	methods: {
		...mapActions('temporaryAccess', {
			updatePaymentMethod: 'updatePaymentMethod',
		}),
		goToActiveTemporaryAccess() {
			this.$emit('goToTa')
		},
		payNow() {
			window.open(this.temporaryAccess.paymentUrl, '_blank')
		},
		async submitUpdatePaymentMethod() {
			const redirectUrl = `${this.getTemporaryAccessRediretionUrl}?id=${this.temporaryAccess.id}`

			const url = await this.updatePaymentMethod(redirectUrl)

			window.location.href = url
		},
		retryPayment() {
			this.$emit('refresh', { setLoading: true })
		},
		sendMeTheInvoice() {
			this.$emit('refresh', { sendMeTheInvoice: true, setLoading: true })
		},
		date,
		refresh() {
			this.now = new Date()
		},
		cancelAutoRefresh() {
			clearInterval(this.timer)
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/temporaryAccess/temporaryAccess';
</style>
