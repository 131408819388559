<template>
	<div>
		<Loading v-if="loading" />
		<Menu activeMenu="municipality" />
		<div class="admin-municipality">
			<form
				class="admin-municipality-form"
				data-vv-scope="municipality-search-form"
				autocomplete="off"
				@submit.prevent="searchForm()"
			>
				<div class="admin-municipality-search-content">
					<div class="admin-municipality-search-field">
						<input
							ref="zip-search"
							v-model="query"
							:placeholder="$t('admin.municipality.searchZipcode')"
							:class="{ error: errors.has('municipality-search-form.search') }"
							name="search"
							type="text"
							class="input-field admin-input-field input admin-search-field"
						/>
						<p v-show="errors.has('municipality-search-form.search')" class="input-error">
							{{ errors.first('municipality-search-form.search') }}
						</p>
					</div>
					<div v-if="false" class="admin-municipality-search-field">
						<Dropdown
							:value="'test'"
							:options="parkingTypes"
							:selected="{}"
							:placeholder="$t('admin.municipality.parkingType')"
							:class="{ error: false }"
							name="parkingTypeSearch"
							class="profile-input-field"
							@on-click="(option) => selectParkingTypeSearch(option)"
						/>
						<p v-show="errors.has('municipality-search-form.search')" class="input-error">
							{{ errors.first('municipality-search-form.search') }}
						</p>
					</div>
					<button
						:disabled="errors.any('municipality-search-form')"
						:class="{ disabled: errors.any('municipality-search-form') }"
						class="button blue admin-parking-search-button"
						type="submit"
					>
						{{ $t('admin.searchButtonText') }}
					</button>
				</div>
			</form>
			<div class="admin-municipality-actions">
				<div
					:class="{ active: formOpen }"
					class="button blue admin-parking-info-header-button activatable"
					@click="toggleForm"
				>
					{{ formOpen ? $t('general.cancelLabel') : $t('admin.parking.createPriceLabel') }}
				</div>
			</div>
			<form
				v-if="formOpen"
				class="admin-municipality-add-form"
				data-vv-scope="admin-municipality-add-form"
				autocomplete="off"
				@submit.prevent="validateFormBeforeSubmit()"
			>
				<div class="admin-municipality-add-form-row">
					<div class="admin-municipality-add-form-row-item">
						<label class="profile-input-field-label">{{
							`${$t('admin.municipality.zip')}*`
						}}</label>
						<div class="input-box admin-parking-form-field">
							<input
								v-model="municipality.postalCode"
								v-validate="{ required: true, min: 2 }"
								:placeholder="$t('admin.municipality.zip')"
								name="zip"
								type="text"
								class="input-field profile-input-field"
							/>
						</div>
						<p v-show="errors.has('admin-municipality-add-form.zip')" class="input-error">
							{{ errors.first('admin-municipality-add-form.zip') }}
						</p>
					</div>
					<div class="admin-municipality-add-form-row-item">
						<label class="profile-input-field-label">{{
							`${$t('admin.municipality.parkingType')}*`
						}}</label>
						<Dropdown
							:value="municipality.parkingTypeName.value"
							:options="parkingTypes"
							:selected="municipality.parkingTypeName"
							:placeholder="$t('admin.municipality.parkingType')"
							:class="{ error: hasCustomError && customErrors.hasOwnProperty('parkingTypeName') }"
							name="parkingTypeName"
							class="profile-input-field"
							@on-click="(option) => selectParkingType(option.key)"
						/>
						<p
							v-show="hasCustomError && customErrors.hasOwnProperty('parkingTypeName')"
							class="input-error"
						>
							{{ customErrors.parkingTypeName }}
						</p>
					</div>
				</div>
				<div class="admin-municipality-add-form-row">
					<div class="admin-municipality-add-form-row-item">
						<label class="profile-input-field-label">{{
							`${$t('admin.municipality.price')}*`
						}}</label>
						<div class="input-box admin-parking-form-field">
							<input
								v-model="municipality.price"
								v-validate="{ required: true, min: 1 }"
								:placeholder="$t('admin.municipality.price')"
								name="price"
								type="text"
								class="input-field profile-input-field"
							/>
						</div>
						<p v-show="errors.has('admin-municipality-add-form.price')" class="input-error">
							{{ errors.first('admin-municipality-add-form.price') }}
						</p>
					</div>
					<div class="admin-municipality-add-form-row-item">
						<div class="input-box admin-parking-form-field">
							<Checkbox v-model="municipality.isCargo" class="profile-checkbox" name="cargo">
								<span slot="label" class="checkbox-text text">
									{{ $t('admin.municipality.cargo') }}
								</span>
							</Checkbox>
						</div>
						<div class="input-box admin-parking-form-field">
							<Checkbox v-model="municipality.active" class="profile-checkbox" name="active">
								<span slot="label" class="checkbox-text text">
									{{ $t('admin.municipality.active') }}
								</span>
							</Checkbox>
						</div>
					</div>
				</div>
				<div class="admin-municipality-add-form-row actions">
					<button
						:disabled="errors.any('admin-municipality-add-form')"
						:class="{ disabled: errors.any('admin-municipality-add-form') }"
						class="button blue admin-parking-search-button"
						type="submit"
					>
						{{ municipality.id ? $t('general.saveLabel') : $t('general.addLabel') }}
					</button>
				</div>
			</form>
			<div class="admin-municipality-table">
				<section>
					<header class="admin-item-row header">
						<div class="admin-item-col header">Zip</div>
						<div class="admin-item-col header">Type</div>
						<div class="admin-item-col header small">Price</div>
						<div class="admin-item-col header smaller">Cargo</div>
						<div class="admin-item-col header smaller">Active</div>
					</header>
					<div
						v-for="item in visiblePcPrices"
						:key="item.id"
						class="admin-item-row clickable"
						@click="editPcPrice(item.id)"
					>
						<div class="admin-item-col">
							{{ item.postalCode }}
						</div>
						<div class="admin-item-col">
							{{ item.parkingTypeName }}
						</div>
						<div class="admin-item-col small">
							{{ item.price }}
						</div>
						<div class="admin-item-col smaller">
							{{ item.isCargo }}
						</div>
						<div class="admin-item-col smaller">
							{{ item.active }}
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import Menu from '@/components/admin/Menu'
import Dropdown from '@/components/form/Dropdown'
import Checkbox from '@/components/form/Checkbox.vue'
import ValidationRequired from '@/mixins/validation-required'
import { initialPcPriceState } from '@/store/modules/admin/municipality'
import { find, filter, omit } from 'lodash/fp'

/* import { logger } from '@/logger' */

export default {
	name: 'AdminMunicipality',
	components: {
		Loading,
		Menu,
		Dropdown,
		Checkbox,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			customErrors: {},
			hasCustomError: false,
			query: '',
			formOpen: false,
			municipality: {},
			visiblePcPrices: [],
		}
	},
	computed: {
		...mapState('admin', {
			allPcPrices: (state) => state.municipality.all,
			loading: (state) => state.loading,
		}),
		...mapGetters('admin', {
			parkingTypes: 'getParkingTypes',
			keyTypes: 'getKeyTypes',
		}),
	},
	mounted() {
		this.$validator.pause()
		this.getAllPostcodePrices().then(() => {
			this.visiblePcPrices = this.allPcPrices
		})
		this.municipality = initialPcPriceState
	},
	methods: {
		...mapActions('admin', {
			getAllPostcodePrices: 'getAllPostcodePrices',
			upsertPostcodePrice: 'upsertPostcodePrice',
			deletePostcodePrice: 'deletePostcodePrice',
		}),
		validateFormBeforeSubmit() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			if (!this.loading) {
				this.$validator.validateAll('admin-municipality-add-form').then(async (result) => {
					// this.addressTypeError = await this.parking.parkingTypeName.key && await this.parking.keyType.key

					let ok = true

					if (!Object.prototype.hasOwnProperty.call(this.municipality.parkingTypeName, 'key')) {
						this.customErrors.parkingTypeName = 'Required'
						this.hasCustomError = true
						ok = false
					}

					// if (result && !this.addressTypeError) {
					if (result && ok) {
						this.$validator.pause()

						if (await this.upsertPostcodePrice(this.municipality)) {
							this.formOpen = false

							this.searchForm()
						}
					}
				})
			}
		},

		editPcPrice(id) {
			const editPrice = find({ id }, this.allPcPrices)

			// console.log('edititn', id, this.allPcPrices, editPrice, this.municipality.parkingTypeName)

			this.municipality = omit(['__typename'], Object.assign({}, editPrice))

			this.selectParkingType(this.municipality.parkingTypeName)
			this.formOpen = true
		},

		searchForm() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			this.$validator.validateAll('municipality-search-form').then((result) => {
				if (result) {
					this.$validator.pause()
					this.visiblePcPrices = this.findPcPrices(this.query)
				}
			})
		},
		findPcPrices(query) {
			const re = new RegExp(query, 'i')

			return filter(
				(municipality) => municipality.postalCode && municipality.postalCode.search(re) >= 0,
				this.allPcPrices
			)
		},
		selectParkingTypeSearch() {
			console.log('test')
		},
		toggleForm() {
			if (!this.formOpen) {
				this.municipality = initialPcPriceState
			}

			this.formOpen = !this.formOpen
		},
		selectParkingType(optionKey) {
			if (this.customErrors.parkingTypeName) {
				delete this.customErrors.parkingTypeName

				if (Object.keys(this.customErrors).length < 1) {
					this.hasCustomError = false
				}
			}

			this.municipality.parkingTypeName = this.parkingTypes.find((type) => type.key === optionKey)
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/admin/municipality';
</style>
