<template>
	<div class="modal-request-subscription-background">
		<div v-on-clickaway="away" class="modal-request-subscription-content">
			<loading v-if="loading" />
			<div class="modal-content">
				<div v-if="!requestDone" class="modal-request-content">
					<div v-if="shouldSelectLocation" class="modal-request-content-location">
						<p v-if="addresses && addresses.length > 0" class="modal-request-info">
							{{ $t('parkings.selectAddressText') }}
						</p>
						<p v-else class="modal-request-subscription-info">
							{{ $t('parkings.noAddressText') }}
						</p>
						<ul class="modal-request-select-options">
							<li
								v-for="address in addresses"
								:key="address.id"
								:class="{ disabled: isAddressDisabled(address.id) }"
								class="modal-request-select-option"
							>
								<input
									:id="address.id"
									:value="fullAddress(address)"
									:disabled="isAddressDisabled(address.id)"
									:checked="isChecked(address.id)"
									type="radio"
									name="address"
									class="modal-request-select-option-input"
									@change="selectAddress"
								/>
								<!-- <span class="custom-radio"></span> -->
								<label :for="address.id" class="modal-request-select-option-label">
									{{ fullAddress(address) }}
									<span v-if="isAddressDisabled(address.id)">
										({{ $t('parkings.alreadyRequested') }})
									</span>
								</label>
								<div class="modal-request-select-option-check" />
							</li>
						</ul>
						<p
							v-if="addresses && activeAddresses.length === 0 && addresses.length > 0"
							class="modal-request-info extra"
						>
							*
							{{
								hasMaxAddresses(addresses.length)
									? $t('parkings.maxAddressesText')
									: $t('parkings.maxRequestsText')
							}}
						</p>
					</div>
					<p v-else class="modal-request-info spot-type-info">
						{{ $t('parkings.parkingRequestSpotTypeInfo') }}
					</p>
					<Dropdown
						v-if="activeAddresses.length > 0"
						:value="spotType.value"
						:options="spotTypeOptions"
						:selected="spotType"
						:placeholder="$t('parkings.parkingRequestSpotTypeSelection')"
						name="spotType"
						class="modal-request-dropdown"
						@on-click="(option) => selectSpotType(option)"
					/>
				</div>
				<div v-else class="modal-request-content">
					<h3 class="modal-request-info">
						{{ $t('parkings.parkingRequestDone') }}
					</h3>
					<p class="modal-request-info warning">
						* {{ $t('parkings.parkingRequestDoneWarning') }} (
						<a :href="$t('general.navigationLinks.terms')" target="_blank" class="link">
							{{ $t('parkings.parkingRequestDoneWarningLinkLabel') }}</a
						>
						).
					</p>
				</div>
			</div>
			<div v-if="!requestDone" class="modal-request-actions">
				<button
					v-if="
						(addresses && addresses.length > 0 && activeAddresses.length > 0) ||
						!shouldSelectLocation
					"
					:class="{ disabled: !canConfirm() }"
					class="modal-request-subscription-action-button button"
					@mousedown="confirm"
				>
					{{ $t('modal.confirmLabel') }}
				</button>
				<!-- <router-link v-else class="modal-request-confirm button blue" to="/">
					{{ $t('general.profileLink') }}
				</router-link> -->
				<!-- i want to close moadal and redirect too both -->
				<button
					v-else
					class="modal-request-subscription-action-button button"
					@mousedown="NavigateandAway"
				>
					{{ $t('general.profileLink') }}
				</button>
			</div>
			<div v-else class="modal-request-actions confirmation">
				<button class="modal-request-actions-button button red" @mousedown="away">
					{{ $t('modal.closeLabel') }}
				</button>
				<a
					:href="$t('general.navigationLinks.faq')"
					target="_blank"
					class="modal-request-actions-button button blue"
					@mousedown.native="(e) => e.preventDefault()"
				>
					{{ $t('general.navigation.faq') }}
				</a>
			</div>
			<!-- <div class="modal-request-subscription-close-button" @click="away" /> -->
			<span class="modal-request-subscription-close-button" @click="away"
			><img src="./../assets/images/icon_close_red.svg"
		/></span>
		</div>
	</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import Loading from '@/components/Loading'
import { mapActions, mapState } from 'vuex'
import { isNil } from 'lodash/fp'
import Dropdown from '@/components/form/Dropdown.vue'
import { MAX_POI } from '@/config'

export default {
	name: 'RequestModal',
	components: {
		Loading,
		Dropdown,
	},
	mixins: [clickaway],
	props: {
		modalOpen: {
			type: Boolean,
			default: false,
		},
		shouldSelectLocation: {
			type: Boolean,
			default: false,
		},
		addresses: {
			type: Array,
			default: () => [],
		},
		activeAddresses: {
			type: Array,
			default: () => [],
		},
	},
	data: () => ({
		selectedAddressId: null,
		requestDone: false,
		spotType: {},
		maxAddresses: MAX_POI + 1,
	}),
	computed: {
		...mapState({
			loading: (state) => state.profile.loading,
			parkingRequestId: (state) => state.parkings.parkingRequestId,
		}),
		spotTypeOptions() {
			return [
				{ key: 'classic', value: this.$i18n.t('parkings.bikeTypes.classic') },
				{ key: 'cargo', value: this.$i18n.t('parkings.bikeTypes.cargo') },
			]
		},
	},
	destroyed() {
		this.closeRequestModal()
	},
	methods: {
		...mapActions({
			createParkingRequest: 'profile/createParkingRequest',
			closeRequestModal: 'parkings/closeRequestModal',
		}),
		away() {
			this.closeRequestModal()
		},
		NavigateandAway() {
			this.closeRequestModal()
			this.$router.push('/')
		},
		selectAddress(e) {
			const id = parseInt(e.target.id, 10)

			this.selectedAddressId = id === this.selectedAddressId ? null : id
		},
		fullAddress(address) {
			return `${address.streetName} ${address.houseNumber}, ${address.city}`
		},
		checkIsNil(id) {
			return isNil(id)
		},
		confirm(e) {
			e.preventDefault()

			const addressId = this.parkingRequestId ? this.parkingRequestId : this.selectedAddressId

			if (this.canConfirm()) {
				this.createParkingRequest({
					addressId,
					isCargo: this.spotType.key === 'cargo',
				}).then(() => {
					this.requestDone = true
				})
			}
		},
		isAddressDisabled(id) {
			return !this.activeAddresses.some((address) => address.id === id)
		},
		isChecked(id) {
			if (this.activeAddresses.length === 1 && !this.isAddressDisabled(id)) {
				this.selectedAddressId = id

				return true
			} else {
				return false
			}
		},
		selectSpotType(option) {
			this.spotType = this.spotTypeOptions.find((spotType) => spotType.key === option.key)
		},
		canConfirm() {
			return (
				(!this.checkIsNil(this.selectedAddressId) || !this.shouldSelectLocation) &&
				Boolean(this.spotType.key)
			)
		},

		hasMaxAddresses(nrOfAddresses) {
			return nrOfAddresses >= this.maxAddresses
		},
	},
}
</script>
<style scoped lang="scss">
@import '../assets/scss/components/modal';
@import '../assets/scss/pages/parkings/requestModal';
</style>
