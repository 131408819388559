// import { mapState, mapMutations } from 'vuex'
// import { logger } from '../logger'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			isChild: (state) => state.profile.isChild,
		}),

		...mapGetters('profile', {
			isLoaded: 'isLoaded',
		}),
	},
	created() {
		// logger.info('mixing in validation required !')
	},

	methods: {
		...mapActions('profile', {
			loadCurrentUser: 'getCurrentUser',
			backToParentAccount: 'backToParentAccount',
		}),
	},

	beforeRouteEnter(to, from, next) {
		next(async (vm) => {
			if (!vm.isLoaded) {
				await vm.loadCurrentUser()
			}

			if (to.meta.admin) {
				if (vm.currentUser.admin) {
					return next()
				} else {
					return next('/')
				}
			}
			console.log(vm.currentUser , 'vm.currentUser')
			let currentChild = vm.currentUser
			const userToVerify = vm.isChild ? vm.currentUser.parent : vm.currentUser
			if (!userToVerify.phoneVerified) {
				vm.flash('Phone verification pending, please validate', 'error')
				let tempChild = vm.isChild
				if (vm.isChild) {
					await vm.backToParentAccount()
					vm.$store.commit('storeFromPath', '/profile')
				} else {
					vm.$store.commit('storeFromPath', to.fullPath)
				}
				// return next({ name: 'smsValidation', query: { redirect: to.fullPath } })
				return next({ name: 'smsValidation', query: { redirect: to.fullPath} , params: {currentChild} })
			}

			return next()
		})
	},
}
