<template>
	<div class="attribution-parking-info">
		<div class="attribution-parking-info-header">
			<h2 class="attribution-parking-info-header-title">
				{{ parking.code }} - {{ parking.name }} (ID: {{ parking.id }})
			</h2>
			<div class="radio-btn-group">
				<div class="radio">
					<input
						id="one"
						:checked="distanceFilter === lowerFilter"
						:value="lowerFilter"
						type="radio"
						@change="$emit('changeDistanceFilter', $event.target.value)"
					/>
					<label for="one" class="green">{{ `&lt; ${lowerFilter}m` }}</label>
				</div>
				<div class="radio">
					<input
						id="two"
						:checked="distanceFilter === middleFilter"
						:value="middleFilter"
						type="radio"
						@change="$emit('changeDistanceFilter', $event.target.value)"
					/>
					<label for="two" class="orange">{{ `&lt; ${middleFilter}m` }}</label>
				</div>
				<div class="radio">
					<input
						id="three"
						:checked="distanceFilter === upperFilter"
						:value="upperFilter"
						type="radio"
						@change="$emit('changeDistanceFilter', $event.target.value)"
					/>
					<label for="three" class="red">{{ `&lt; ${upperFilter}m` }}</label>
				</div>
			</div>
			<Checkbox v-model="local.disableFilter" class="profile-checkbox" name="availableParkings">
				<span slot="label" class="checkbox-text text">
					{{ $t('admin.disableRequestFilter') }}
				</span>
			</Checkbox>
		</div>
		<h2 v-if="requests.length === 0">No requests found</h2>
		<div v-else class="attribution-table">
			<div class="attribution-table-header">
				<div
					v-for="field in tableAttributionFields"
					:key="field.key"
					:class="`attribution-table-column ${field.size}`"
				>
					{{ field.label }}
				</div>
				<div class="attribution-table-column small">&nbsp;</div>
			</div>
			<div class="attribution-table-body">
				<ParkingRequest
					v-for="(request, index) in filteredRequests"
					:key="index"
					:parking="parking"
					:request="request"
					:selectedParkingSpot="selectedParkingSpot"
					:class="distanceClass(request.walkingDistance)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { date } from '@/helpers'
import { filter, reject } from 'lodash/fp'
import ParkingRequest from '@/components/admin/attribution/ParkingRequest'
import { parkingDistanceFilters } from '@/config'
import Checkbox from '@/components/form/Checkbox.vue'

// import { logger } from '@/logger'

export default {
	name: 'ParkingRequests',
	components: {
		ParkingRequest,
		Checkbox,
	},
	props: {
		parking: {
			type: Object,
			default: () => ({}),
		},
		requests: {
			type: Array,
			default: () => [],
		},
		selectedParkingSpot: {
			type: Object,
			default: () => ({}),
		},
		distanceFilter: {
			type: Number,
			default: parkingDistanceFilters.lower,
		},
	},
	data() {
		return {
			local: {
				disableFilter: false,
			},

			// distanceFilter: parkingDistanceFilters.lower,
			lowerFilter: parkingDistanceFilters.lower,
			upperFilter: parkingDistanceFilters.upper,
			middleFilter:
				parkingDistanceFilters.lower +
				(parkingDistanceFilters.upper - parkingDistanceFilters.lower) / 2,
			tableAttributionFields: [
				{ key: 'name', label: 'Name' },
				{ key: 'type', label: 'Request type' },
				{ key: 'walkingDistance', label: 'dist', size: 'tiny' },
				{ key: 'requestDate', label: 'Request Date' },
				{ key: 'cargo', label: 'cargo', size: 'tiny' },
				{ key: 'declined', label: 'Decl / Exp' },
			],
		}
	},
	computed: {
		...mapGetters('admin', {
			activeProposal: 'activeProposal',
		}),
		filteredRequests() {
			// logger.debug('filtering', this.upperFilter, this.lowerFilter, parkingDistanceFilters, this.distanceFilter)

			return reject(
				(req) => {
					return this.local.disableFilter
						? false
						: req.closestSubDist && req.closestSubDist < req.walkingDistance
				},
				filter(
					(req) =>
						req.walkingDistance <= parseInt(this.distanceFilter, 10) ||
						this.activeProposal(req.requestId, this.parking.id),
					this.requests
				)
			)
		},
	},
	created() {},

	methods: {
		distanceClass(dist) {
			if (dist <= this.lowerFilter) {
				return 'distance-lower'
			} else if (dist <= this.middleFilter) {
				return 'distance-middle'
			} else if (dist <= this.upperFilter) {
				return 'distance-upper'
			}

			return 'distance-out-of-scope'
		},
		date,
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/attribution';
@import '../../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}
</style>
