/**
 * Mocking client-server processing
 */
// Const tempCompanies = [
// 	{ id: 1, name: 'iPad 4 Mini', address: 'just an address' },
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { config } from '../config'
import { logger } from '../logger'

const authHeader = setContext((_, { headers }) => {
	const token = localStorage.getItem('cycloToken')

	return {
		headers: {
			...headers,
			Authorization: token ? `Bearer ${token}` : '',
		},
	}
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.map(({ message, locations, path }) =>
			logger.error(`[API error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
		)
	}

	if (networkError) {
		logger.error(`[Network error]: ${networkError}`)
	}
})

export default new ApolloClient({
	// Provide the URL to the API server.
	link: errorLink.concat(
		authHeader.concat(
			new HttpLink({
				uri: `${config.apiUrl}/graphql`,
			})
		)
	),

	// Using a cache for blazingly
	// Fast subsequent queries.
	cache: new InMemoryCache(),
})
