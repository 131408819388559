<template>
	<div>
		<Loading v-if="isLoading" />
		<PreviousButton />
		<div class="sms-validation">
			<h1 class="title forgot-pw-title">
				{{ $t('emailValidation.title') }}
			</h1>
			<!-- <p class="text sms-validation-info">
				{{ $t('forgotPw.info') }}
			</p> -->
			<form
				v-show="passwordReset.userId"
				class="sms-validation-form"
				data-vv-scope="code-form"
				@submit.prevent=""
			>
				<p class="text sms-validation-info">
					{{ $t('smsValidation.validateInfo') }}
					<strong>
						{{ passwordReset.phoneNumber ? passwordReset.phoneNumber : passwordReset.email }}
					</strong>
				</p>
				<div class="input-box sms-validation-otp-inputs-sms">
					<input
						v-for="(input, index) in inputs"
						:key="index"
						v-model="input.value"
						@input="handleInput(index, $event)"
						@keyup="handleKeyUp(index, $event)"
						maxlength="1"
						ref="inputs"
						:class="{ 'last-input': index === inputs.length - 1, error: input.error }"
						class="sms-validation-otp-input-field input-field"
						type="text"
					/>
					<p v-if="errors.has('code-form.accessCode')" class="input-error">
						{{ errors.first('code-form.accessCode') }}
					</p>
				</div>
				<!-- requestPasswordReset() -->
				<!-- <button
					:disabled="smsCode.length < 4"
					:class="{ disabled: smsCode.length < 4 }"
					class="button red sms-validation-button-resend"
					@click="requestPasswordReset"
				>
					{{ $t('smsValidation.resendLabel') }}
				</button> -->
				<div>
					<span class="sms-validation-link-button-link" @click="requestPasswordReset()">
						{{ $t('emailValidation.resendCode') }}
					</span>
				</div>
				<button
					:disabled="errors.has('code-form.accessCode') || smsCode.length < 4"
					:class="{ disabled: errors.has('code-form.accessCode') || smsCode.length < 4 }"
					class="button red sms-validation-button-confirm"
					@click="doValidateToken"
				>
					{{ $t('smsValidation.validateLabel') }}
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import PreviousButton from '@/components/PreviousButton.vue'

export default {
	name: 'ForgotPasswordValidation',
	components: {
		Loading,
		PreviousButton,
	},
	data() {
		return {
			smsCode: '',
			inputs: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }],
		}
	},
	computed: {
		...mapState({
			alert: (state) => state.alert,
			isLoading: (state) => state.authentication.loading,
			passwordReset: (state) => state.authentication.passwordReset,
		}),
	},
	methods: {
		...mapActions({
			requestPhoneAuth: 'authentication/requestPhoneAuth',
			requestEmailAuth: 'authentication/requestEmailAuth',
			validateToken: 'authentication/validateResetToken',
		}),
		// hasLocalErrors() {
		// 	return (
		// 		Object.keys(this.local.errors).some((key) => this.local.errors[key]) ||
		// 		(this.phoneNumber || '').length < 1
		// 	)
		// },
		// onInputPhone(value, phoneObject) {
		// 	this.local.phoneNumber = phoneObject.number

		// 	const empty = (value || '').length < 1

		// 	this.local.errors.phone = empty || phoneObject.valid ? '' : this.$t('error.notValidPhone')
		// },

		async requestPasswordReset() {
			// We store the phoneNumber in a local val on the 'onInputPhone' event because of a bug with the vue-phone-number-input component
			// When we copy-past a phone number in the field when it was already filled-in the country-code was not added.
			// This way, we make sure that the phone number is always correct.
			// this.phoneNumber = this.local.phoneNumber
			if (this.passwordReset.phoneNumber) {
				await this.requestPhoneAuth(this.passwordReset.phoneNumber)
			} else {
				await this.requestEmailAuth(this.passwordReset.email)
			}

			// this.flash(this.alert.message, this.alert.type)
		},
		async doValidateToken() {
			if (this.passwordReset.phoneNumber) {
				await this.validateToken({
					pin: this.smsCode,
					userId: this.passwordReset.userId,
					type: 'sms',
				})
			} else {
				await this.validateToken({
					pin: this.smsCode,
					userId: this.passwordReset.userId,
					type: 'email',
				})
			}
			// this.flash(this.alert.message, this.alert.type)
		},

		handleInput(index, event) {
			const val = event.target.value
			if (isNaN(val)) {
				this.inputs[index].value = ''
				return
			}
			if (val !== '') {
				if (this.smsCode.length < 4) {
					// this.smsCode += String(val)
					this.smsCode = this.inputs.map((input) => input.value).join('')
					console.log('this.smsCode', this.smsCode)
				}
				const nextIndex = index + 1
				if (this.inputs[nextIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[nextIndex].focus()
					})
				}
			}
		},
		handleKeyUp(index, event) {
			const key = event.key.toLowerCase()
			if (key === 'backspace' || key === 'delete') {
				this.inputs[index].value = ''
				if (this.smsCode.length > 0) {
					this.smsCode = this.smsCode.slice(0, -1)
				}
				const prevIndex = index - 1
				if (this.inputs[prevIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[prevIndex].focus()
					})
				}
			}
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			// console.log('vm.passwordReset.userId', vm.passwordReset.userId)
			if (vm.passwordReset.userId === null) {
				// vm.$router.push('/forgotPassword') // Redirect to the forgot password page
				vm.$router.go(-1)
			}
		})
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/forgotPw';
@import '../../assets/scss/pages/smsValidation';
</style>
