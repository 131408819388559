<template>
	<div class="profile-subscriptions">
		<div class="profile-title-section">
			<h1 class="title profile-title">
				{{ $t('profile.titleTemporaryAccesses') }}
			</h1>
		</div>
		<div class="profile-subscriptions-table">
			<div class="profile-subscriptions-table-header">
				<div class="profile-subscriptions-table-header-item size25">
					{{ $t('temporaryAccess.overviewHeaders.name') }}
				</div>
				<div class="profile-subscriptions-table-header-item size20">
					{{ $t('temporaryAccess.overviewHeaders.status') }}
				</div>
				<div class="profile-subscriptions-table-header-item size25">
					{{ $t('temporaryAccess.overviewHeaders.startDate') }}
				</div>
				<div class="profile-subscriptions-table-header-item size10">
					{{ $t('temporaryAccess.overviewHeaders.price') }}
				</div>
				<div class="profile-subscriptions-table-header-item actions" />
			</div>
			<div class="profile-subscriptions-table-content">
				<div class="profile-subscriptions-table-content-item size25">
					{{ temporaryAccess.parking.code }}
				</div>
				<div class="profile-subscriptions-table-content-item size20">
					{{ $t(`temporaryAccess.statuses.${temporaryAccessStatusName(temporaryAccess)}`) }}
				</div>
				<div class="profile-subscriptions-table-content-item size25">
					{{
						date(temporaryAccess.startedAt || temporaryAccess.startCodeGeneratedAt, {
							withHours: true,
						})
					}}
				</div>
				<div class="profile-subscriptions-table-content-item size10">
					{{ price }}
				</div>
				<div
					class="profile-subscriptions-table-content-item actions button tiny blue"
					@click="doShowTemporaryAccess(temporaryAccess.id)"
				>
					{{ $t('temporaryAccess.showMeButton') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {} from 'vuex'
import { date } from '@/helpers'
import { temporaryAccessStatusName } from '@/store/modules/temporaryAccess'

export default {
	name: 'ProfileTemporaryAccesses',
	components: {},
	props: {
		temporaryAccess: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {}
	},
	computed: {
		price() {
			return this.temporaryAccess.price ? `${this.temporaryAccess.price}€` : '-'
		},
	},
	methods: {
		date,
		temporaryAccessStatusName,
		doShowTemporaryAccess(id) {
			this.$router.push({
				name: 'temporaryAccess',
				query: { id },
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
</style>
