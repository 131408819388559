import gql from 'graphql-tag'
import graphqlClient from '@/api/db'
import { logger } from '@/logger'
import { findKey, invert } from 'lodash/fp'
import { DEPOSIT_STATES, SUBSCRIPTION_STATES } from '@/config'
import i18n from '@/i18nVeeValidate'
import { date } from '@/helpers/'

// import { date } from '@/helpers/'
// import { REQUEST_STATES, PROPOSAL_STATES, USER_STATES, SUBSCRIPTION_STATES, DEPOSIT_STATES } from '@/config'
// import i18n from '@/i18nVeeValidate'

// Initial state
const initialState = () => ({
	data: [],
})
const state = initialState()

export const inventoryReportProps = `
		subscriptionId
		email
		parkingId
		parking
		status
		start
		end
		provisionTTC
		provisionHTVA
		createdAt
		depositPrice
		depositReimbursedAt
		depositStripeId
		remainingDays
		remainingMonths
		externalDeposit
		depositStatus
		depositPaid
		depositProvision
		isCycloSub
`

export const incomeReportProps = `
	product
	period
	incomeSubscription
	incomeTemporaryAccess
	incomePenalty
	incomeDepositStripe
	incomeDepositManual
	incomeTotal
	refundsStripe
	refundsManual
	paidDeposits
	returnedDepositsStripe
	returnedDepositsManual
`
export const depositReportProps = `
	type
	status
	amount
	total
`

export const prorationReportProps = (group) => {
	return `
	period
	${prorationReportGroups[group] || ''}
	incomeSubscription
	incomeTemporaryAccess
	total
`
}

export const prorationReportGroups = {
	product: 'product',
	parking: 'parking',
	subscription: `parking
	user
	subscriptionPeriod`,
}

export const inventoryReportFields = {
	'Sub id': 'subscriptionId',
	email: 'email',
	parking: 'parking',
	status: {
		field: 'status',
		callback: (value) => {
			return findKey((s) => s === value, SUBSCRIPTION_STATES)

			// const state = findKey((s) => s === value, SUBSCRIPTION_STATES)
			// return state ? i18n.t(`subscription.statuses.${state}`) : ''
		},
	},
	start: {
		field: 'start',
		callback: (value) => {
			return value ? date(value) : ''
		},
	},
	end: {
		field: 'end',
		callback: (value) => {
			return value ? date(value) : ''
		},
	},
	createdAt: {
		field: 'createdAt',
		callback: (value) => {
			return value ? date(value) : ''
		},
	},
	remainingDays: 'remainingDays',
	remainingMonths: 'remainingMonths',
	isCycloSub: 'isCycloSub',
	provisionTTC: 'provisionTTC',
	provisionHTVA: 'provisionHTVA',
	depositPrice: 'depositPrice',
	depositReimbursedAt: {
		field: 'depositReimbursedAt',
		callback: (value) => {
			return value ? date(value) : ''
		},
	},
	depositStripeId: 'depositStripeId',
	depositProvision: 'depositProvision',
	depositStatus: {
		// field: 'depositStatus',
		callback: (row) => {
			let state = null

			if (row.depositStatus === DEPOSIT_STATES.active) {
				state = row.depositStripeId && !row.depositPaid ? 'paymentPending' : 'active'
			} else {
				state = findKey((s) => s === row.depositStatus, DEPOSIT_STATES)
			}

			return state ? i18n.t(`subscription.depositStatuses.${state}`) : ''
		},
	},
	'multiple deposits': {
		field: 'depositCount',
		callback: (v) => (v > 1 ? 'Y' : 'N'),
	},
}

export const incomeReportFields = invert({
	product: 'product',
	period: 'period',
	incomeSubscription: 'subscriptions',
	incomeTemporaryAccess: 'temporary accesses',
	incomePenalty: 'penalties',
	incomeDepositStripe: 'closed deposits (stripe)',
	incomeDepositManual: 'closed deposits (manual)',
	incomeTotal: 'total income',
	refundsStripe: 'compensations (stripe)',
	refundsManual: 'compensations (manual)',
	paidDeposits: 'paid deposits',
	returnedDepositsStripe: 'returned deposits (stripe)',
	returnedDepositsManual: 'returned deposits (manual)',
})

export const depositReportFields = {
	type: 'type', // Stripe or legacy
	status: 'status', // sub active or not
	amount: 'amount',
	'# deposits': 'total',
}

const getters = {}

const actions = {
	async reportInventory({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query reportInventory {
						reportInventory {
								${inventoryReportProps}
							}
					}
				`,
			})

			// remove duplicate deposits
			const data = response.data.reportInventory

			// trick to get rid of duplicate lines due to multiple deposits
			// const mappedData = new Map(data.map((obj) => [obj.subscriptionId, obj]))
			const mappedData = data.reduce((a, c) => {
				const entry = a.get(c.subscriptionId) || c

				entry.depositCount = (entry.depositCount || 0) + 1

				return a.set(c.subscriptionId, entry)
			}, new Map())

			// logger.debug('mapped data 1:', Array.from(mappedData.values()))

			commit('setExport', Array.from(mappedData.values()))
		} catch (error) {
			logger.debug('error', error)
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async reportDeposit({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query reportDeposit {
						reportDeposit {
								${depositReportProps}
							}
					}
				`,
			})

			commit('setExport', response.data.reportDeposit)
		} catch (error) {
			logger.debug('error', error)
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async reportIncome({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query reportIncome {
						reportIncome {
								${incomeReportProps}
							}
					}
				`,
			})

			commit('setExport', response.data.reportIncome)
		} catch (error) {
			logger.debug('error', error)
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async reportProration({ commit, dispatch }, { from, to, groupBy, groupOf }) {
		commit('setLoading', true)

		const fromUTC = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()))
		const toUTC = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate()))

		try {
			const response = await graphqlClient.query({
				query: gql`
					query reportProration($from: Date!, $to: Date!, $groupBy: String, $groupOf: Int!) {
						reportProration(from: $from, to: $to, groupBy: $groupBy, groupOf: $groupOf) {
								${prorationReportProps(groupBy)}
							}
					}
				`,
				variables: {
					from: fromUTC,
					to: toUTC,
					groupBy,
					groupOf,
				},
			})

			commit('setExport', response.data.reportProration)
		} catch (error) {
			logger.debug('error', error)
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {}

export default {
	state,
	getters,
	actions,
	mutations,
}
