<template>
	<div class="profile-subscriptions-profile">
		<!-- <div class="profile-title-section"> -->
		<!-- <h1 class="title profile-title">
				{{ $t('profile.titleRequests') }}
			</h1> -->
		<!-- <button
				v-if="activeAddresses.length > 0"
				class="button orange profile-action-button"
				@click="doRequestParking"
			>
				{{ $t('parkings.requestButtonLabel') }}
			</button>
		</div> -->
		<div v-if="requests.length <= 0" class="profile-subscriptions-profile-no-data">
			<p v-if="activeAddresses.length > 0" :class="{ error: !hasSubscriptions() }" class="text">
				{{
					hasSubscriptions()
						? $t('profile.request.noRequestWithSubscription')
						: $t('profile.request.noRequest')
				}}
			</p>
			<p v-else>
				{{ $t('profile.request.noAddress') }}
			</p>
		</div>
		<div v-else class="profile-subscriptions-profile-table">
			<Request
				v-for="req in requests"
				:key="req.id"
				:request="req"
				:disableCancel="disableCancelRequest"
			/>
		</div>
		<!-- v-if="activeAddresses.length > 0" -->
		<button
			class="button profile-subscriptions-profile-cta"
			@click="doRequestParking"
		>
			{{ $t('profile.request.requestButtonLabel') }}
		</button>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { date } from '@/helpers'
import Request from './Request.vue'

// import { subscriptionStatusName, subscriptionName } from '@/store/modules/subscription'

export default {
	name: 'ProfileRequests',
	components: {
		Request,
	},
	props: {
		requests: {
			type: Array,
			default: () => [],
		},
		activeAddresses: {
			type: Array,
			default: () => [],
		},
		subscriptions: {
			type: Array,
			default: () => [],
		},
		disableCancelRequest: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('profile', {
			// hasBigParkingSubscription: 'hasBigParkingSubscription',
		}),
	},
	methods: {
		...mapActions('profile', {
			cancelParkingRequest: 'cancelParkingRequest',
		}),

		isSuggestion(type) {
			return type === 'parking'
		},
		doRequestParking() {
			this.$emit('toggleRequestModal')
		},
		hasSubscriptions() {
			return this.subscriptions.length > 0
		},
		date,
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
@import '../../assets/scss/components/info';

.request-button-delete {
	width: 20px;
	height: 20px;
}
</style>
