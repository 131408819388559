<template>
  <div
    class="profile-fields"
    style="margin: 30px"
  >
    <div class="profile-field">
      <h1 class="profile-field-value">
        {{ $t('accountMenu.title') }}
      </h1>
    </div>
    <div class="profile-field">
      <div class="profile-field-info">
        <p class="profile-field-value">
          {{ $t('accountMenu.subTitle') }}
        </p>
      </div>
    </div>

    <div
      v-for="title in titles"
      class="profile-acc-title"
    >
      <div class="" @click="toggleActive(title)">
        <p class="profile-field-value">
          {{ title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
	name: 'AccountMenu',
	components: {},
	data() {
	  const isActive = ref('')

	  const toggleActive = (title) => {
		  isActive.value = title
	  }

		return {
			titles: [
		  'Actieve Parkeersessies',
		  'Account',
		  'Mijn adressen',
		  'Mijn Familie',
		  'Mijn Parkeerplaatsen',
		  'Mijn eign parkings',
		  'Betalingsinformatie',
	  ],
		}
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
</style>
