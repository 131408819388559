import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { logger } from '@/logger'
import { findIndex, pick, omit } from 'lodash/fp'
import Vue from 'vue'
import i18n from '@/i18nVeeValidate'

// import { keyTypes } from '@/config'
// import { sortBy, findIndex, find, filter, values, groupBy, flatten, map, min, compose } from 'lodash/fp'

// Initial state
const initialErrorState = { coupon: {}, couponCode: {} }
const initialState = () => ({
	all: [],
	error: Object.assign({}, initialErrorState),
	subscriptionTypeNames: [],
	loading: false,
})

export const couponProps = `
	id
	name
	isPercentage
	amount
	duration
	nbrMonths
	validFromDate
	expirationDate
	maxNbrRedemptions
	nbrRedeemed
	valid
	active
	deleted
	parkingTypes
	parkings {
		id
	}
	couponCodes {
		id
		name
		validFromDate
		expirationDate
		maxNbrRedemptions
		nbrRedeemed
		valid
		active
	}
`

const couponErrorFields = `
	name
	isPercentage
	amount
	duration
	nbrMonths
	validFromDate
	expirationDate
	maxNbrRedemptions
	active
	deleted
	parkingType
	parking
`

const coupouCodeErrorFields = `
	name
	code
	validFromDate
	expirationDate
	maxNbrRedemptions
	active
`
const couponErrorProps = `
  generic
	coupon {
		${couponErrorFields}
	}
	couponCode {
		${coupouCodeErrorFields}
	}
`

const state = initialState()

const prepareParkingIds = (coupons) => {
	coupons.forEach((coupon) => {
		// eslint-disable-next-line no-param-reassign
		coupon.parkingIds = coupon.parkings.map((p) => p.id)
		// eslint-disable-next-line no-param-reassign
		delete coupon.parkings
	})
}

export const initialCouponState = {
	name: '',
	parkingTypes: [],
	parkingIds: [],
	couponCodes: [],
	isPercentage: false,
	active: true,
}

const getters = {
	getsubscriptionTypeNames: (state) => {
		return state.subscriptionTypeNames.map((type) => ({
			key: type,
			value: i18n.t(`parkings.parkingTypes.${type.toLowerCase()}`),
		}))
	},
	couponParkings: (state, getters, rootState, rootGetters) => {
		const parkings = rootGetters['admin/orderedParkings']

		return parkings.map((item) => {
			return Object.assign({}, item, { label: `${item.code} (${item.name})` })
		})
	},
}

const actions = {
	async getSubscriptionTypeNames({ commit }) {
		try {
			commit('setLoading', true)

			const response = await graphqlClient.query({
				query: gql`
					query subscriptionTypeNames {
						subscriptionTypeNames
					}
				`,
			})
			const subscriptionTypeNames = response.data.subscriptionTypeNames

			commit('setSubscriptionTypeNames', subscriptionTypeNames)
		} catch (e) {
			logger.error('Problem retrieving subscriptionTypeNames', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async getCoupons({ commit }) {
		try {
			commit('setLoading', true)

			const response = await graphqlClient.query({
				query: gql`
					query coupons {
						coupons {
							${couponProps}
						}
					}
				`,
			})
			const coupons = response.data.coupons || []

			commit('setCoupons', coupons)
		} catch (e) {
			logger.error('Problem retrieving coupons', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async upsertCoupon({ commit, dispatch }, payload) {
		// console.log('creating parking', parking)

		commit('setLoading', true)

		const maxNbrRedemptions = payload.maxNbrRedemptions
			? parseFloat(payload.maxNbrRedemptions)
			: null
		const nbrMonths = payload.nbrMonths ? parseFloat(payload.nbrMonths) : null

		const coupon = Object.assign({}, omit(['valid', 'nbrRedeemed'], payload), {
			duration: payload.duration.key,
			amount: parseFloat(payload.amount),
			maxNbrRedemptions,
			nbrMonths,
			couponCodes: payload.couponCodes.map((code) => {
				const codeMaxNbrRedemptions = code.maxNbrRedemptions
					? parseFloat(code.maxNbrRedemptions)
					: null

				return Object.assign(
					{},
					pick(
						['id', 'name', 'validFromDate', 'expirationDate', 'maxNbrRedemptions', 'active'],
						code
					),
					{
						maxNbrRedemptions: codeMaxNbrRedemptions,
					}
				)
			}),

			// couponCodes: [...Array(payload.codes)].map(() => {
			// 	return {
			// 		validFromDate: payload.validFromDate,
			// 		expirationDate: payload.expirationDate,
			// 		maxNbrRedemptions: maxRed,
			// 	}
			// }),
		})

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation upsertCoupon($coupon: CouponUpsertProps!) {
						upsertCoupon(props: $coupon) {
							coupon {
								${couponProps}
							},
							error{
								${couponErrorProps}
							}
						}
					}
				`,
				variables: {
					coupon,
				},
			})

			if (response.data.upsertCoupon) {
				const error = response.data.upsertCoupon.error

				if (error) {
					if (error.generic) {
						dispatch('alert/error', error.generic, {
							root: true,
						})
					} else {
						dispatch('alert/error', 'Validation error. Please check you inputs', {
							root: true,
						})
						commit('setCouponError', error)
					}
				} else {
					coupon.id
						? commit('updateCoupon', response.data.upsertCoupon.coupon)
						: commit('createCoupon', {
								id: response.data.upsertCoupon.coupon.id,
								coupon: response.data.upsertCoupon.coupon,
						  })

					return true
				}
			}
		} catch (e) {
			logger.error('Coupon update error', e)

			// dispatch('alert/error', e.message, { root: true })
			dispatch('alert/error', 'Failed to store, please check you input.', {
				root: true,
			})
			throw e
		} finally {
			commit('setLoading', false)
		}

		return false
	},
}

const mutations = {
	setLoading(state, loadingState) {
		state.loading = loadingState
	},
	setSubscriptionTypeNames(state, subscriptionTypeNames) {
		state.subscriptionTypeNames = subscriptionTypeNames
	},

	setCoupons(state, coupons) {
		prepareParkingIds(coupons)
		state.all = coupons
	},

	createCoupon(state, { id, coupon }) {
		prepareParkingIds([coupon])
		state.all.push(Object.assign({}, coupon, { id }))
	},
	updateCoupon(state, coupon) {
		prepareParkingIds([coupon])

		const idx = findIndex({ id: coupon.id }, state.all)

		if (idx >= 0) {
			Vue.set(state.all, idx, coupon)
		}
	},
	setCouponError(state, error) {
		state.error.coupon = error.coupon || {}
		state.error.couponCode = error.couponCode || {}
	},
	resetCouponError(state) {
		state.error = Object.assign({}, initialErrorState)
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
