// import { mapState, mapMutations } from 'vuex'
import { logger } from '../logger'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
		}),

		...mapGetters('profile', {
			isLoaded: 'isLoaded',
		}),
	},
	created() {
		// logger.info('mixing in validation required !')
	},

	methods: {
		...mapActions('profile', {
			loadCurrentUser: 'getCurrentUser',
		}),
	},

	beforeRouteEnter(to, from, next) {
		next(async (vm) => {
			if (!vm.isLoaded) {
				await vm.loadCurrentUser()
			}

			if (to.meta.admin) {
				if (vm.currentUser.admin) {
					return next()
				} else {
					return next('/')
				}
			}

			if (!vm.currentUser.status || vm.currentUser.status === 0) {
				logger.silly('account pending, please validate')

				return next({ name: 'emailValidation' })
			}

			return next()
		})
	},
}
