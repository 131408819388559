<template>
	<div class="modal-background">
		<div v-on-clickaway="away" class="modal">
			<loading v-if="loading" />
			<div class="modal-content-and-buttons">
				<div class="modal-content">
					<slot />
				</div>
				<div v-if="canClose" class="modal-close-button" @click="away" />
				<div v-if="!hideActions" class="modal-actions">
					<button
						:disabled="loading || !canConfirm"
						:class="{ disabled: !canConfirm }"
						class="button blue"
						@mousedown="confirm"
					>
						{{ confirmLabel }}
					</button>
					<button
						v-if="!hideCancel"
						:disabled="loading || !canCancel"
						:class="{ disabled: !canCancel }"
						class="button red"
						@mousedown="cancel"
					>
						{{ cancelLabel }}
					</button>
					<button
						v-if="decideLater"
						:disabled="loading"
						class="button orange"
						@mousedown="doDecideLater"
					>
						{{ $t('modal.decideLaterLabel') }}
					</button>
				</div>
			</div>
			<div class="modal-footer">
				<slot name="footer" />
			</div>
		</div>
	</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import Loading from '@/components/Loading'
import i18n from '@/i18nVeeValidate'

export default {
	name: 'Modal',
	components: {
		Loading,
	},
	mixins: [clickaway],
	props: {
		modalOpen: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		decideLater: {
			type: Boolean,
			default: false,
		},
		hideCancel: {
			type: Boolean,
			default: false,
		},
		cancelLabel: {
			type: String,
			required: false,
			default: i18n.t('general.cancelLabel'),
		},
		confirmLabel: {
			type: String,
			required: false,
			default: i18n.t('modal.confirmLabel'),
		},
		hideActions: {
			type: Boolean,
			required: false,
			default: false,
		},
		canClose: {
			type: Boolean,
			default: true,
		},
		canConfirm: {
			type: Boolean,
			default: true,
		},
		canCancel: {
			type: Boolean,
			default: true,
		},
	},
	data: () => ({}),
	methods: {
		away() {
			if (this.modalOpen && this.canClose) {
				this.$emit('close')
			}
		},
		cancel(e) {
			e.preventDefault()
			this.$emit('cancel')
		},
		confirm(e) {
			e.preventDefault()
			this.$emit('confirm')
		},
		doDecideLater(e) {
			e.preventDefault()
			this.$emit('doDecideLater')
		},
	},
}
</script>
<style scoped lang="scss">
@import '../assets/scss/components/modal';
</style>
