<template>
	<div class="admin-item-row clickable" @click="showUserForm(user)">
		<div :class="{ 'admin-user': user.admin }" class="admin-item-col" :title="user.parentId ? `${user.parent.email} (child)` : user.email">
			{{ user.parentId ? `${user.parent.email} (child)` : user.email }}
		</div>
		<div class="admin-item-col small" :title="user.lastName">
			{{ user.lastName }}
		</div>
		<div class="admin-item-col small" :title="user.firstName">
			{{ user.firstName }}
		</div>
		<div class="admin-item-col small">
			{{ user.phoneNumber }}
		</div>
		<div class="admin-item-col tiny">
			{{ user.language }}
		</div>
		<div class="admin-item-col tiny">
			{{ userStatusName(user.status) }}
		</div>
		<div class="admin-item-col smaller">
			{{ date(user.createdAt) }}
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { date } from '@/helpers'
import { userStatusName } from '@/store/modules/admin/users'

export default {
	name: 'UserResult',
	components: {},
	props: {
		user: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			local: {},
		}
	},
	computed: {
		...mapState({}),
	},
	created() {},

	methods: {
		...mapActions('admin', {}),
		...mapMutations('admin', {
			showUserForm: 'showUserForm',
		}),
		date,
		userStatusName,
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/admin';
.admin-item-col {
	overflow: hidden;

	&.admin-user {
		color: red;
	}
}
</style>
