<template>
	<div>
		<Loading v-if="isLoading" />
		<PreviousButton />
		<div class="reset-pw">
			<h1 class="title reset-pw-title">
				{{ $t('resetPw.title') }}
			</h1>
			<!-- <p class="text reset-pw-info">
				{{ $t('resetPw.info') }}
			</p> -->
			<form class="reset-pw-form" data-vv-scope="password-form" @submit.prevent="doResetPassword">
				<div class="reset-pw-input-box">
					<label class="reset-pw-label">
						<span>{{ $t('profile.newPassword') }}</span>
					</label>
					<div class="input-box reset-pw-input-box">
						<input
							ref="password"
							v-model="local.password.value"
							v-validate="'required|min:6'"
							:placeholder="$t('profile.newPassword')"
							:class="{ error: errors.has('password-form.password') }"
							:data-vv-as="$t('profile.newPassword')"
							name="password"
							type="password"
							class="input-field profile-input-field"
						/>
						<div class="input-reveal-button" @click="doToggleReveal('password')">
							<div :class="{ revealed: local.password.reveal }" class="input-reveal-button-line" />
						</div>
						<p v-show="errors.has('password-form.password')" class="input-error">
							{{ errors.first('password-form.password') }}
						</p>
					</div>
				</div>
				<div class="reset-pw-input-box">
					<label class="reset-pw-label">
						<span>{{ $t('profile.newPasswordAgain') }}</span>
					</label>
					<div class="input-box reset-pw-input-box">
						<input
							ref="passwordConfirmation"
							v-model="local.passwordConfirmation.value"
							v-validate="'required|confirmed:password'"
							:placeholder="$t('profile.newPasswordAgain')"
							:class="{ error: errors.has('password-form.passwordConfirmation') }"
							:data-vv-as="$t('profile.newPassword')"
							name="passwordConfirmation"
							type="password"
							class="input-field profile-input-field"
						/>
						<div class="input-reveal-button" @click="doToggleReveal('passwordConfirmation')">
							<div
								:class="{ revealed: local.passwordConfirmation.reveal }"
								class="input-reveal-button-line"
							/>
						</div>
						<p v-show="errors.has('password-form.passwordConfirmation')" class="input-error">
							{{ errors.first('password-form.passwordConfirmation') }}
						</p>
					</div>
				</div>
				<button
					:disabled="errors.any('password-form')"
					:class="{ disabled: errors.any('password-form') }"
					class="button reset-pw-button"
					type="submit"
				>
					{{ $t('resetPw.requestLabel') }}
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { toggleReveal } from '@/helpers'
import Loading from '@/components/Loading.vue'
import { logger } from '@/logger'
import PreviousButton from '@/components/PreviousButton.vue'

export default {
	name: 'ResetPassword',
	components: {
		Loading,
		PreviousButton,
	},
	data() {
		return {
			local: {
				errors: {
					password: '',
					passwordConfirmation: '',
				},
				password: {
					value: '',
					reveal: false,
				},
				passwordConfirmation: {
					value: '',
					reveal: false,
				},
			},
		}
	},
	computed: {
		...mapState({
			alert: (state) => state.alert,
			isLoading: (state) => state.authentication.loading,
			passwordReset: (state) => state.authentication.passwordReset,
		}),
		resetCode() {
			return this.$route.params.code || this.passwordReset.resetCode
		},
	},
	created() {
		if (this.$route.params.code) {
			logger.debug('secret code found', this.$route.params.code)
		}
	},
	methods: {
		...mapActions({
			resetPassword: 'authentication/resetPassword',
		}),

		doToggleReveal(field) {
			this.local[field].reveal = toggleReveal(this.local[field], this.$refs[field])
		},

		async doResetPassword() {
			// Is this needed? Validation already happening on the fly...
			const validated = await this.$validator.validateAll('password-form')

			if (validated) {
				await this.resetPassword({ password: this.local.password.value, resetCode: this.resetCode })
				// this.flash(this.alert.message, this.alert.type)
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/forgotPw';
</style>
