<template>
	<div v-if="canShow">
		<div class="temporaryAccess-wrapper wider" :class="wrapperClass">
			<div>
				<h1
					v-if="!alreadyEntered && ((!canGetOut && !isRetry) || (canGetOut && isRetry))"
					class="title"
				>
					{{ $t('temporaryAccess.qrCode.title', { parking: parkingName }) }}
				</h1>
				<h1
					v-if="(!alreadyEntered && canGetOut && !isRetry) || (!canGetOut && isRetry)"
					class="title"
				>
					{{ $t('temporaryAccess.qrCode.titleToGetOut', { parking: parkingName }) }}
				</h1>
				<div v-if="false">
					(yes, this div is a comment. Didn't find any other way to add a comment). We need to
					render the qrCodes twice and only show the one we want with v-show. Using v-if or useing a
					dynamic 'size' property doesn't not work. The small QR code will then already be displayed
					in BIG for some reason
				</div>
				<div @click="scanIt">
					<VueQRCodeComponent
						v-show="!alreadyEntered"
						:text="qrCode"
						:size="bigSize"
						class="qrCode"
					></VueQRCodeComponent>
					<VueQRCodeComponent
						v-show="alreadyEntered"
						:text="qrCode"
						:size="smallSize"
						class="qrCode"
					></VueQRCodeComponent>
				</div>
				<br />
				<p v-if="!alreadyEntered" class="temporaryAccess-modal-text">
					<span class="black">{{ $t('temporaryAccess.qrCode.generatedAt') }} </span>
					{{ generatedAt }}
				</p>
				<p class="temporaryAccess-modal-text willExpireIn" @click="expireIt">
					<span class="black">{{ $t('temporaryAccess.qrCode.willExpireIn') }} </span>
					{{ willExpireIn }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { date } from '@/helpers'
import {
	TEMPORARY_ACCESS_QR_CODE_GET_IN_DURATION,
	TEMPORARY_ACCESS_QR_CODE_GET_OUT_DURATION,
	TEMPORARY_ACCESS_QR_CODE_RETRY_DURATION,
	NUMBER_OF_MILISEC_IN_A_MIN,
	NUMBER_OF_MILISEC_IN_A_SEC,
	NUMBER_OF_SEC_IN_A_MIN,
	APP_ENV,
} from '@/config'
import VueQRCodeComponent from 'vue-qrcode-component'
import { logger } from '@/logger'

export default {
	name: 'QrCode',
	components: {
		VueQRCodeComponent,
	},
	props: {
		temporaryAccess: {
			type: Object,
			required: true,
		},
		parkingName: {
			type: String,
			required: true,
		},
		isEndCode: {
			type: Boolean,
			required: true,
		},
		alreadyEntered: {
			type: Boolean,
			required: true,
		},
		canGetOut: {
			type: Boolean,
			required: true,
		},
		isRetry: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			timer: null,
			now: new Date(),
			bigSize: 256,
			smallSize: 100,
		}
	},
	created() {
		this.timer = setInterval(this.refresh, 1000)
	},
	beforeDestroy() {
		this.cancelAutoRefresh()
	},
	computed: {
		...mapState({}),
		codeGeneratedAt() {
			return (
				this.retryPendingSince ||
				(this.isEndCode
					? this.temporaryAccess.endCodeGeneratedAt
					: this.temporaryAccess.startCodeGeneratedAt)
			)
		},
		retryPendingSince() {
			return this.temporaryAccess.retryPendingSince
		},
		qrCode() {
			return this.isEndCode ? this.temporaryAccess.endKey : this.temporaryAccess.startKey
		},
		generatedAt() {
			return this.date(this.codeGeneratedAt, { withHours: true })
		},
		willExpireIn() {
			let remaining = 'Expired'

			const duration = this.retryPendingSince
				? TEMPORARY_ACCESS_QR_CODE_RETRY_DURATION
				: this.canGetOut
				? TEMPORARY_ACCESS_QR_CODE_GET_OUT_DURATION
				: TEMPORARY_ACCESS_QR_CODE_GET_IN_DURATION

			const diff = this.codeGeneratedAt + duration - this.now.getTime()

			if (diff > 0) {
				let nbrFullMinutes = Math.floor(diff / NUMBER_OF_MILISEC_IN_A_MIN)
				const reste = diff % NUMBER_OF_MILISEC_IN_A_MIN
				let nbrStartedSeconds = Math.floor(reste / NUMBER_OF_MILISEC_IN_A_SEC) + 1

				if (nbrStartedSeconds === NUMBER_OF_SEC_IN_A_MIN) {
					nbrStartedSeconds = 0
					nbrFullMinutes += 1
				}

				remaining = `${nbrFullMinutes}min ${nbrStartedSeconds}sec`
			}

			return remaining
		},
		isExpired() {
			return Boolean(this.willExpireIn === 'Expired')
		},
		canShow() {
			return Boolean(!this.alreadyEntered || !this.isExpired)
		},
		size() {
			return this.alreadyEntered ? 100 : 256
		},
		wrapperClass() {
			return this.alreadyEntered ? 'lite' : ''
		},
	},
	methods: {
		date,
		refresh() {
			this.now = new Date()
		},
		cancelAutoRefresh() {
			clearInterval(this.timer)
		},
		scanIt() {
			if (['DEV', 'ACC'].includes(APP_ENV)) {
				this.$emit('refresh', { simulateScan: true })
			}
		},
		expireIt() {
			logger.debug(`We are in 'expireIt'`)

			if (['DEV', 'ACC'].includes(APP_ENV)) {
				this.$emit('refresh', { simulateExpiration: true })
			}
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/temporaryAccess/temporaryAccess';
</style>
