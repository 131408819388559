<template>
	<div>
		<cleave
			ref="mobibInput"
			v-validate="{ regex: '^(?=[0-9A-Fa-f]*$)(?:.{16}|.{18})$' }"
			:value="mobib"
			:placeholder="$t('profile.mobibLabel')"
			:class="{ error: errors.has(errorTag), disabled: !!mobibPresent }"
			:disabled="!!mobibPresent"
			:data-vv-as="$t('profile.mobibLabel')"
			:options="cleaveOptions"
			name="mobib"
			type="password"
			class="input-field profile-input-field"
			@input="doInput($event)"
			autocomplete="new-password"
		/>
		<div v-if="!mobibPresent" class="info" @mouseenter="toggleModal" @mouseleave="toggleModal">
			<div class="info-icon">?</div>
			<div v-if="showMessage" class="info-modal">
				{{ message }}
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'MobibInput',
	inject: ['$validator'],
	props: {
		message: {
			type: String,
			required: true,
			default: '',
		},
		errorTag: {
			type: String,
			required: true,
			default: '',
		},
		mobibPresent: {
			type: Boolean,
			required: true,
			default: false,
		},
		mobib: {
			type: String,
			required: false,
			default: '',
		},

		// localMobib: {
		// 	type: String,
		// 	required: false,
		// 	default: '',
		// },
	},
	data() {
		return {
			showMessage: false,
			cleaveOptions: { blocks: [4, 4, 4, 4, 2] },

			// localMobib: null,
		}
	},
	computed: {
		cleaveOptionsComp() {
			if (this.mobib && this.mobib.length > 1 && this.mobib[1] === '0') {
				return this.cleaveOpts1
			} else {
				return this.cleaveOpts2
			}
		},
	},
	watch: {
		mobib(newVal) {
			console.log('newVal', newVal, newVal.length)
			if (newVal.length < 3) {
				if (newVal && newVal.length > 1 && newVal[1] === '0') {
					if (this.cleaveOptions.blocks[0] !== 4) {
						this.cleaveOptions.blocks = [4, 4, 4, 4, 2]
					}
				} else if (this.cleaveOptions.blocks[0] !== 2) {
					this.cleaveOptions.blocks = [2, 4, 4, 4, 4]
				}
			}
		},
	},
	methods: {
		toggleModal() {
			this.showMessage = !this.showMessage
		},
		doInput(e) {
			// logger.debug('inputting mobib', e, this.mobib)

			if (this.$refs.mobibInput.$el.type === 'password') {
				Vue.set(this.$refs.mobibInput.$el, 'type', 'text')
			}

			this.$emit('update:mobib', e)
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/components/info';
</style>
