import graphqlClient from '@/api/db'

export const genericMutation = async ({ commit, dispatch }, { mutation, variables }) => {
	commit('setLoading', true)

	try {
		const response = await graphqlClient.mutate({
			mutation,
			variables,
		})

		return response
	} catch (error) {
		dispatch('alert/error', error.message, { root: true })

		// Do we need to throw again here?
		throw error
	} finally {
		commit('setLoading', false)
	}
}
