<template>
	<div>
		<Loading v-if="isLoading()" />
		<div v-show="!isLoading()" class="parkings">
			<Maps :parkings="parkings" :focus="focus" />
		</div>
	</div>
</template>

<script>
import Maps from '@/components/parkings/Maps'
import Loading from '@/components/Loading.vue'
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash/fp'

export default {
	name: 'Parkings',
	components: {
		Maps,
		Loading,
	},
	props: {
		focus: {
			type: Number,
			required: false,
			default: undefined,
		},
	},
	data() {
		return {
			view: 'map',
		}
	},
	computed: {
		...mapState({
			parkings: (state) => state.parkings.all,
			parkingsFetched: (state) => state.parkings.parkingsFetched,
			loading: (state) => state.parkings.loading,
			userLoading: (state) => state.profile.loading,
			currentUser: (state) => state.profile.currentUser,
			language: (state) => state.language,
			mapsLanguage: (state) => state.mapsLanguage,
		}),
	},
	watch: {
		language(newVal) {
			// When language changes, page reload is necessary for the google maps plugin
			if (newVal !== this.mapsLanguage) {
				location.reload()
			}
		},
	},
	async created() {
		this.checkLaguage()

		if (!this.parkingsFetched) {
			await this.getParkings()
		}
	},
	methods: {
		...mapActions({
			getParkings: 'parkings/getParkings',
		}),
		checkIsNil(id) {
			return isNil(id)
		},
		isLoading() {
			return this.loading || (this.userLoading && this.checkIsNil(this.currentUser.id))
		},
		checkLaguage() {
			if (this.language !== this.mapsLanguage) {
				location.reload()
			}
		},
	},
}
</script>
<style lang="scss">
@import '../../assets/scss/pages/parkings/index';
</style>
