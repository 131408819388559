<template>
	<label class="checkbox" tabindex="0" @focus="focusCheckbox($event)" @blur="blurCheckbox">
		<input
			:name="name"
			:checked="localValue"
			:disabled="disabled"
			type="checkbox"
			class="checkbox-input"
			@click="check($event.target)"
		/>
		<span class="checkbox-icon-box">
			<span class="checkbox-icon" />
		</span>
		<slot name="label" class="checkbox-text" />
	</label>
</template>

<script>
export default {
	name: 'Checkbox',
	model: {
		prop: 'value',
		event: 'valuechange',
	},
	props: {
		name: {
			type: String,
			required: true,
			default: '',
		},
		value: {
			type: Boolean,
			required: true,
			default: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data: () => ({
		currentTarget: '',
	}),
	computed: {
		localValue: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('valuechange', value)
			},
		},
	},
	methods: {
		focusCheckbox(e) {
			this.currentTarget = e.target
			window.addEventListener('keydown', this.checkEnter, true)
		},

		blurCheckbox() {
			this.currentTarget = ''
			window.removeEventListener('keydown', this.checkEnter, true)
		},
		check(target) {
			this.localValue = !this.localValue
			this.$emit('input', target.checked)
		},
		checkEnter(e) {
			const keyCodeEnter = 13

			if (e.keyCode === keyCodeEnter) {
				this.check(this.currentTarget.querySelector('input'))
			}
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/components/checkbox';
</style>
