/* eslint-disable no-console */

import { unregister } from 'register-service-worker'
import { environment } from './config'

if (environment === 'production') {
	// Register(`${process.env.BASE_URL}service-worker.js`, {
	// 	Ready () {
	// 		Console.log(
	// 			'App is being served from cache by a service worker.\n' +
	// 			'For more details, visit https://goo.gl/AFskqB'
	// 		)
	// 	},
	// 	Cached () {
	// 		Console.log('Content has been cached for offline use.')
	// 	},
	// 	Updated () {
	// 		Console.log('New content is available; please refresh.')
	// 	},
	// 	Offline () {
	// 		Console.log('No internet connection found. App is running in offline mode.')
	// 	},
	// 	Error (error) {
	// 		Console.error('Error during service worker registration:', error)
	// 	}
	// })
	unregister()
}
