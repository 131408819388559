<template>
	<!-- <div class="accordion-container"> -->
	<div class="accordion-item" :class="{ active: accordionActive }">
		<Loading v-if="loading" />
		<div class="accordion-header" @click="toggleAccordion()">
			{{ title }}
			<span class="arrow" :style="accordionActive ? { transform: 'rotate(180deg)' } : {}"></span>
		</div>
		<div class="accordion-content" v-show="accordionActive">
			<slot />
		</div>
	</div>
	<!-- </div> -->
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import Loading from '@/components/Loading'

export default {
	name: 'Accordion',
	components: {
		Loading,
	},
	mixins: [clickaway],
	props: {
		isOpen: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: "",
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			accordionActive: this.isOpen || false
		}
	},
	methods: {
		toggleAccordion() {
			this.accordionActive = !this.accordionActive;
		},
	},
}
</script>
<style scoped lang="scss">
.accordion-item {
	margin-bottom: 16px;
	width: 100%;
	box-sizing: border-box;
	background-color: #faf2d5;
	border: 1px solid #faf2d5;
	border-radius: 4px;
	padding: 16px;
}

.accordion-header {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 4px;
	color: #3c3c3b;
	font-size: 18px;
	line-height: 27px;
	font-family: 'Poppins Bold';
}

.arrow {
	background-image: url('../assets/images/arrow-down.png');
	background-repeat: no-repeat;
	background-position: right;
	background-size: contain;
	width: 15px;
	height: 15px;
	transition: linear 200ms;
}

.accordion-content {
	display: none;
	padding-top: 16px;
	// background-color: #faf2d5;	
}

.accordion-item.active .accordion-content {
	display: block;
}

@media screen and (max-width: 400px) {}
</style>
