<template>
	<div class="generate-qr">
		<div class="generate-qr-top">
			<h2 class="">{{ title }}</h2>
			<p>{{ description }}</p>
			<h4>ID {{ sessionId }}</h4>
		</div>
		<QrCode :text="qrCodeText" />
		<div class="generate-qr-num-scan">
			<span class="generate-qr-num-scan-text">{{
				$t('sessionParking.generateQrCode.scanTitle')
			}}</span>
			<span class=""
				><strong>{{ numOfScan }}</strong> {{ $t('sessionParking.generateQrCode.scanNumber') }}
				{{ totalScan }}</span
			>
		</div>
		<div class="generate-qr-info" v-if="type === 'session-start-qr'">
			<img src="../../assets/images/session/icon_warning_white.svg" />
			<p class="generate-qr-info-text" v-html="message"></p>
		</div>
		<div class="generate-qr-action" v-if="type === 'entering-qr'">
			<div
				class="generate-qr-action-btn"
				@click="endSession"
				v-if="prevNumOfScan > 0 && this.sessionQrData.length > 1"
			>
				Je suis déjà rentré
			</div>
			<p v-html="message"></p>
			<div class="generate-qr-action-timer">{{ formattedTimer }}</div>
		</div>
		<div class="generate-qr-action" v-if="type === 'main-door-qr'">
			<div class="generate-qr-action-btn" @click="skipMainDoor">
				{{ $t('sessionParking.generateQrCode.skipQr') }}
			</div>
			<p v-html="message"></p>
		</div>
		<p class="generate-qr-text" v-if="type === 'session-end-qr'">
			{{ message }}
		</p>
		<div class="generate-qr-footer">
			<span class="" v-html="$t('sessionParking.generateQrCode.disclaimer')"></span>
		</div>
	</div>
</template>

<script>
import QrCode from '@/components/QrCode.vue'
import io from 'socket.io-client'
import { config } from '@/config'

export default {
	name: 'GenerateQr',
	components: {
		QrCode,
	},
	props: {
		type: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		sessionId: {
			type: String,
			required: true,
		},
		numOfScan: {
			type: Number,
			required: true,
		},
		qrCodeText: {
			type: String,
			required: true,
		},
		totalScan: {
			type: Number,
			required: true,
		},
		prevNumOfScan: {
			type: Number,
		},
		sessionQrData: {
			type: Array,
		},
		message: {
			type: String,
			required: true,
		},
		timeLimit: {
			type: Number,
			required: false,
		},
		startTime: {
			type: Number,
			required: false,
		},
		endSession: {
			type: Function,
		},
		skipMainDoor: {
			type: Function,
		},
	},
	data() {
		return {
			timer: null,
			initialTimeLimit: this.timeLimit * 60, // minutes in seconds
			timerDuration: 0,
		}
	},
	computed: {
		formattedTimer() {
			const minutes = Math.floor(this.timerDuration / 60)
			const seconds = this.timerDuration % 60
			return `${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`
		},
	},
	methods: {
		calculateTimerDuration() {
			if (this.startTime) {
				const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds
				const elapsedTime = currentTime - Math.floor(this.startTime / 1000) // Time elapsed since startTime in seconds
				const remainingTime = Math.max(0, this.initialTimeLimit - elapsedTime) // Remaining time in seconds
				return remainingTime // Ensure timer never goes negative
			} else {
				return this.initialTimeLimit // If no startTime, return initial time limit
			}
		},
	},
	mounted() {
		console.log('type', this.type)
		if (this.type === 'entering-qr') {
			this.timerDuration = this.calculateTimerDuration()
			this.timer = setInterval(() => {
				if (this.timerDuration > 0) {
					this.timerDuration -= 1
				} else {
					clearInterval(this.timer)
					const session = localStorage.getItem('qrTimeout')
					if (session === 'true') {
						this.$router.push('/how-session-start')
					} else {
						localStorage.setItem('qrTimeout', 'true')
						this.$router.push('/how-session-start')
					}
				}
			}, 1000)
		}
	},
	beforeDestroy() {
		clearInterval(this.timer)
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/generateQr';
</style>
