<template>
	<div class="input-box admin-parking-form-field multiple">
		<div class="admin-parking-form-field-box">
			<input
				v-validate="{ required: true, numeric: false }"
				:value="localParkingSpot.name"
				:placeholder="$t('admin.parking.spot.name')"
				:class="{ error: errors.has('parking-form.spot-name') || hasMissingValue('name') }"
				:data-vv-as="$t('admin.parking.spot.name')"
				name="spot-name"
				type="text"
				class="input-field inline profile-input-field"
				@keyup="removeError('name')"
				@input="changeParkingSpot($event.target.value, 'name')"
			/>
			<p v-show="errors.has('parking-form.spot-name')" class="input-error">
				{{ errors.first('parking-form.spot-name') }}
			</p>
			<p
				v-show="hasMissingValue('name') && !errors.has('parking-form.spot-name')"
				class="input-error"
			>
				{{ hasMissingValue('name') }}
			</p>
		</div>
		<div class="admin-parking-form-field-box">
			<input
				v-validate="{ required: true }"
				:value="parkingSpot.amount"
				:placeholder="$t('admin.parking.spot.amount')"
				:class="{ error: errors.has('parking-form.spot-amount') || hasMissingValue('amount') }"
				:data-vv-as="$t('admin.parking.spot.amount')"
				name="spot-amount"
				type="text"
				class="input-field inline profile-input-field"
				@keyup="removeError('amount')"
				@input="changeParkingSpot($event.target.value, 'amount')"
			/>
			<p v-show="errors.has('parking-form.spot-amount')" class="input-error">
				{{ errors.first('parking-form.spot-amount') }}
			</p>
			<p
				v-show="hasMissingValue('amount') && !errors.has('parking-form.spot-amount')"
				class="input-error"
			>
				{{ hasMissingValue('amount') }}
			</p>
		</div>
		<Checkbox
			:value="parkingSpot.isCargo"
			class="admin-parking-form-checkbox"
			name="active"
			@input="changeParkingSpot($event.target.checked, 'isCargo')"
		>
			<span slot="label" :class="{ error: hasMissingValue('isCargo') }" class="checkbox-text text">
				{{ $t('admin.parking.spot.isCargo') }}
			</span>
			<p v-show="hasMissingValue('isCargo')" class="input-error">
				{{ hasMissingValue('isCargo') }}
			</p>
		</Checkbox>
		<div
			v-if="!parkingSpot.id"
			class="button-delete admin-parking-form-action icon-actions"
			@click="$emit('removeSpot', sIndex)"
		/>
		<ToggleButton
			v-else
			:labels="{ checked: 'Active', unchecked: 'Inactive' }"
			:value="parkingSpot.active"
			:width="95"
			:height="30"
			:fontSize="14"
			:sync="true"
			:color="{ unchecked: '#E75130' }"
			class="admin-parking-form-toggle"
			@change="toggleParkingSpotActive"
		/>
	</div>
</template>
>

<script>
import Checkbox from '@/components/form/Checkbox.vue'
import { ToggleButton } from 'vue-js-toggle-button'

/* import { logger } from '@/logger' */

export default {
	name: 'ParkingSpot',
	components: {
		Checkbox,
		ToggleButton,
	},
	props: {
		parkingSpot: {
			type: Object,
			default: () => ({}),
		},
		sIndex: {
			type: Number,
			default: null,
		},
		parkingSpotErrors: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			localParkingSpot: {},
		}
	},
	computed: {},
	created() {
		this.localParkingSpot = { ...this.parkingSpot }
	},
	methods: {
		toggleParkingSpotActive(e) {
			this.localParkingSpot.active = e.value
			this.$emit(`update:parkingSpot`, this.localParkingSpot)

			// this.parkingSpot.active = e.value
		},
		hasMissingValue(property) {
			return (this.parkingSpotErrors[this.sIndex] || {})[property]
		},
		removeError(type) {
			const errors = this.parkingSpotErrors[this.sIndex] || {}

			errors[type] = false

			this.$emit(
				'update:parkingSpotErrors',
				Object.assign({}, this.parkingSpotErrors, {
					[this.sIndex]: errors,
				})
			)

			// For some reason the component was not reloaded after the first keyPress, it only occured on the second one. I then have to force the reload
			this.$forceUpdate()
		},
		changeParkingSpot(val, key) {
			this.localParkingSpot[key] = val

			this.$emit(`update:parkingSpot`, this.localParkingSpot)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/admin';
</style>
