import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import Vue from 'vue'
import { findIndex, omit } from 'lodash/fp'
import { logger } from '@/logger'

// import { isNil, omit, find, findIndex, reject, map, filter, includes } from 'lodash/fp'
// import router from '@/router'

// import { addressTypes, SUBSCRIPTION_STATES, REQUEST_STATES, PROPOSAL_STATES, MAX_POI } from '@/config'
// import i18n from '@/i18nVeeValidate'

// Initial state
const initialState = () => ({
	all: [],
})

const state = initialState()

const getters = {}

const myParkingProps = `
	id
	name
	code
	latitude
	longitude
	capacityCargo
	capacityClassic
	active
	badgeAccess
	approved
	legacyAddress {
		nl
		fr
	}
	comment {
		nl
		fr
		en
	}
	parkingSpots {
	    id
	    amount,
	    name,
	    isCargo,
	    active
	}
`

const actions = {
	async getMyParkings({ commit }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query myParkings {
						myParkings {
							${myParkingProps}
						}
					}
				`,
			})
			const parkings = response.data.myParkings

			commit('setMyParkings', parkings)
		} catch (e) {
			logger.error('Problem retrieving your parkings', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async upsertMyParking({ commit, dispatch }, payload) {
		commit('setLoading', true)

		const parking = Object.assign({}, payload, {
			parkingTypeName: 'PrivateParkings',
			keyType: 0,
			latitude: parseFloat(payload.latitude),
			longitude: parseFloat(payload.longitude),
			capacityCargo: parseInt(payload.capacityCargo, 10),
			capacityClassic: parseInt(payload.capacityClassic, 10),
			parkingSpots: payload.parkingSpots.map((ps) =>
				omit(
					'__typename',
					Object.assign({}, ps, {
						amount: parseFloat(ps.amount),
					})
				)
			),
		})

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation upsertMyParking($parking: ParkingUpsertProps!) {
						upsertMyParking(props: $parking) {
							${myParkingProps}
						}
					}
				`,
				variables: {
					parking,
				},
			})

			if (response.data.upsertMyParking) {
				parking.id
					? commit('updateParking', response.data.upsertMyParking)
					: commit('createParking', response.data.upsertMyParking)

				return true
			}
		} catch (e) {
			logger.error('parking creation error')
			dispatch('alert/error', e.message, { root: true })
			throw e
		} finally {
			commit('setLoading', false)
		}

		return false
	},
}

const mutations = {
	setMyParkings(state, parkings) {
		state.all = parkings
	},

	createParking(state, parking) {
		state.all.push(parking)
	},
	updateParking(state, parking) {
		// state.all.push(parking)
		const idx = findIndex({ id: parking.id }, state.all)

		if (idx >= 0) {
			Vue.set(state.all, idx, parking)
		}
	},
}

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations,
}
