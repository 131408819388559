<template>
	<div class="bg-blue">
		<Loading v-if="!session || isLoading" />
		<div v-else="session && !isLoading" class="start-session-qr">
			<GenerateQr
				:type="localizedData.scanType"
				:title="localizedData.title"
				:description="localizedData.description"
				:sessionId="session ? session.sessionId : '0'"
				:qrCodeText="session ? session.sessionQRCodes[0].qrCode : 'scan to encode'"
				:numOfScan="session ? session.sessionQRCodes[0].used : 0"
				:totalScan="session ? session.sessionQRCodes[0].usage : 0"
				:message="localizedData.message"
				:skipMainDoor="openModal"
			/>
		</div>
		<SessionModal :showModal="showModal" @close="closeModal" @confirm="confirmAction">
			<template v-slot:title>
				<h2>{{ $t('sessionParking.modal.skipMainDoorQr.modalTitle') }}</h2>
			</template>
			<template v-slot:content>
				<p>
					{{ $t('sessionParking.modal.skipMainDoorQr.modalDescription') }}
				</p>
				<div>
					<div class="alert-message">
						<img
							src="../../assets//images/session//icon_warning_black.svg"
							alt="Add Circle Icon"
							class="icon-svg alert-img"
						/>
						<span class="alert">{{ $t('sessionParking.modal.skipMainDoorQr.modalAlertText') }}</span>
					</div>
				</div>
			</template>
			<template v-slot:confirmButton>
				<button @click="confirmAction">Recuperer mon velo</button>
			</template>
			<template v-slot:cancelButton>
				<a class="cancel" @click="closeModal">{{ $t('general.cancelLabel')  }}</a>
			</template>
		</SessionModal>
	</div>
</template>

<script>
import GenerateQr from '@/components/session/GenerateQr.vue'
import SessionRequired from '@/mixins/session-required'
import SessionModal from '@/components/SessionModal.vue'
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import io from 'socket.io-client'
import { config } from '@/config'

export default {
	name: 'MainDoorQr',
	components: {
		GenerateQr,
		Loading,
		SessionModal,
	},
	mixins: [SessionRequired],
	data() {
		return {
			qrScannedStatus: null,
			isLoading: false,
			showModal: false,
		}
	},
	computed: {
		localizedData() {
			return {
				scanType: 'main-door-qr',
				title: this.$t('sessionParking.qrCodeMainDoorEnter.title'),
				description: this.$t('sessionParking.qrCodeMainDoorEnter.description'),
				message: this.$t('sessionParking.qrCodeMainDoorEnter.message'),
			}
		},
		...mapState({
			session: (state) =>
				state.session.sessionCreate.length > 0 ? state.session.sessionCreate[0] : null,
		}),
	},
	methods: {
		...mapActions('session', ['getSessionById']),
		...mapActions('session', ['updateQrCodeUsage']),
		...mapActions('alert', ['error']),
		async loadSessionPeriodically() {
			const sessionId = localStorage.getItem('sessionId')

			if (sessionId) {
				const numericSessionId = parseInt(sessionId, 10)

				if (!isNaN(numericSessionId)) {
					const sessionProps = {
						sessionId: numericSessionId,
					}
					try {
						// Dispatch the action properly
						await this.getSessionById(sessionProps)
					} catch (error) {
						console.error('Error fetching session:', error)
					}
				}
			}
		},
		openModal() {
			// Show the default modal
			this.showModal = true
		},
		closeModal() {
			this.showModal = false
		},
		async confirmAction() {
			this.skipMainDoorQr()
			this.closeModal()
		},
		async skipMainDoorQr() {
			const sessionId = localStorage.getItem('sessionId')

			if (sessionId) {
				const numericSessionId = parseInt(sessionId, 10)

				if (!isNaN(numericSessionId)) {
					const sessionProps = {
						rfid: this.session.sessionQRCodes[0].qrCode,
						parkingId: this.session.parkingId,
					}
					try {
						await this.updateQrCodeUsage(sessionProps)
						this.loadSessionPeriodically()
					} catch (error) {
						console.error('Error fetching session:', error)
					}
				}
			}
		},
	},
	created() {
		// Connect to the Socket.IO server
		this.socket = io(config.apiUrl)
		// Handle event from the server for Subscription first charge
		this.socket.on('QrScanned', ({ response }) => {
			this.qrScannedStatus = response
			if (this.qrScannedStatus) {
				if (this.qrScannedStatus.success === true) {
					const rfId = this.qrScannedStatus.rfid
					if (rfId === this.session.sessionQRCodes[0].qrCode) {
						this.isLoading = true
						setTimeout(() => {
							this.isLoading = false
						}, 5000)
						this.loadSessionPeriodically()
					}
				} else {
					this.error(this.qrScannedStatus.message)
				}
			}
		})

		// Example: handling connection error
		this.socket.on('connect_error', (error) => {
			console.error('Connection error:', error)
		})
	},
	beforeDestroy() {
		this.socket.off('QrScanned')
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';
</style>
