<template>
	<div class="admin-item-row clickable" @click="$emit('on-click', parking.id)">
		<div class="admin-item-col small">
			{{ parking.code }}
		</div>
		<div class="admin-item-col small">
			{{ parking.name }}
		</div>
		<div class="admin-item-col small">
			{{ parking.active ? 'Y' : 'N' }}
		</div>
		<div class="admin-item-col smaller">
			{{ parking.approved ? 'Y' : 'N' }}
		</div>
	</div>
</template>

<script>
import { date } from '@/helpers'

export default {
	name: 'MyParkingResult',
	components: {},
	props: {
		parking: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {}
	},
	computed: {},
	created() {},

	methods: {
		date,
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}
</style>
