<template>
	<div>
		<div class="temporaryAccess-wrapper">
			<div>
				<div v-if="qrCodeVisible" class="center-div">
					<h1 class="title-light">
						{{ $t('temporaryAccess.stop.title', { name: parking.name }) }}
					</h1>
				</div>
				<h1 v-else class="title">
					{{ $t('temporaryAccess.stop.title', { name: parking.name }) }}
				</h1>
				<br />
				<p class="temporaryAccess-modal-text">
					<span class="black">Parking:</span> {{ parking.name }} ({{ parking.code }})
				</p>
				<p class="temporaryAccess-modal-text">
					{{ parkingAddress }}
				</p>
				<p class="temporaryAccess-modal-text">
					<span class="black">{{ $t('temporaryAccess.stop.startedAt') }} </span>
					{{ startedAt }}
				</p>
				<p class="temporaryAccess-modal-text">
					<span class="black">{{ $t('temporaryAccess.stop.duration') }} </span>
					{{ duration }}
				</p>
				<p class="temporaryAccess-modal-text">
					<span class="black">{{ $t('temporaryAccess.stop.price') }} </span>
					{{ temporaryAccess.price }}€
				</p>
			</div>
			<form v-if="canGetOut" class="temporaryAccess-form center-div" @submit.prevent>
				<div v-if="!leaveQrCodeVisible && !isRetry" class="center-div">
					<p class="text" v-html="$t('temporaryAccess.stop.leaveMessage')" />
					<button
						:disabled="errors.any()"
						:class="{ disabled: errors.any() }"
						class="button orange temporaryAccess-form-next-button"
						@click="showTheLeaveQrCode"
					>
						{{ $t('temporaryAccess.stop.leaveButton') }}
					</button>
				</div>
				<p
					v-if="!stopRequested && !failedToEnterAtStart"
					class="link temporaryAccess-form-finish-link"
					@click="openConfirmationModal"
				>
					{{ $t('temporaryAccess.stop.finishItNow') }}
				</p>
			</form>
			<form
				v-if="!canGetOut && !failedToEnterAtStart"
				class="temporaryAccess-form center-div"
				@submit.prevent
			>
				<p class="text" v-html="$t('temporaryAccess.stop.text')" />
				<button
					:disabled="errors.any()"
					:class="{ disabled: errors.any() }"
					class="button blue temporaryAccess-form-next-button"
					@click="openConfirmationModal"
				>
					{{ $t('temporaryAccess.stop.stopButton') }}
				</button>
			</form>
		</div>
		<Modal
			v-if="confirmationModal"
			:modalOpen="confirmationModal"
			:cancelLabel="$t('temporaryAccess.stop.confirmation.cancelButton')"
			:confirmLabel="$t('temporaryAccess.stop.confirmation.confirmButton')"
			:canConfirm="iAmReady"
			@confirm="confirmStop"
			@cancel="closeConfirmationModal"
			@close="closeConfirmationModal"
		>
			<div>
				<h1 class="small-title">{{ $t('temporaryAccess.stop.confirmation.title') }}</h1>
				<div class="temporaryAccess-modal-parking-info">
					<p class="temporaryAccess-modal-text">
						<span class="black">Parking:</span> {{ parking.name }} ({{ parking.code }})
					</p>
					<p class="temporaryAccess-modal-text">
						{{ parkingAddress }}
					</p>
					<p class="temporaryAccess-modal-text warning">
						{{
							$t('temporaryAccess.stop.confirmation.message', {
								startedAt,
								price: temporaryAccess.price,
								potentialEndedAt: date(temporaryAccess.potentialEndedAt, { withHours: true }),
							})
						}}
					</p>
					<Checkbox v-model="iAmReady" name="iAmReady" class="temporaryAccess-modal-checkbox">
						<span slot="label" class="checkbox-text text">
							{{ $t('temporaryAccess.stop.confirmation.readyCheckBox') }}
						</span>
					</Checkbox>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '@/components/Modal.vue'
import { date } from '@/helpers'
import Checkbox from '@/components/form/Checkbox.vue'
import {
	NUMBER_OF_MILISEC_IN_A_DAY,
	NUMBER_OF_MILISEC_IN_AN_HOUR,
	NUMBER_OF_MILISEC_IN_A_MIN,
	NUMBER_OF_MILISEC_IN_A_SEC,
} from '@/config'

/* import { logger } from '@/logger' */

export default {
	name: 'Stop',
	components: {
		Modal,
		Checkbox,
	},
	props: {
		parking: {
			type: Object,
			required: true,
		},
		temporaryAccess: {
			type: Object,
			required: true,
		},
		confirmationModal: {
			type: Boolean,
			required: true,
		},
		canGetOut: {
			type: Boolean,
			required: true,
		},
		leaveQrCodeVisible: {
			type: Boolean,
			required: true,
		},
		qrCodeVisible: {
			type: Boolean,
			required: true,
		},
		stopRequested: {
			type: Boolean,
			required: true,
		},
		isRetry: {
			type: Boolean,
			required: true,
		},
		failedToEnterAtStart: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			iAmReady: false,
			timer: null,
			now: new Date(),
		}
	},
	created() {
		this.timer = setInterval(this.refreshDuration, 1000)
	},
	beforeDestroy() {
		this.cancelTimers()
	},
	computed: {
		...mapState({
			activeLanguage: (state) => state.language,
		}),
		parkingAddress() {
			return this.parking.legacyAddress[this.activeLanguage.value] || this.parking.legacyAddress.en
		},
		startedAt() {
			return this.date(this.temporaryAccess.startedAt, { withHours: true })
		},
		durationTimes() {
			const r = {}
			const diff = this.now.getTime() - this.temporaryAccess.startedAt

			r.nbrFullDays = Math.floor(diff / NUMBER_OF_MILISEC_IN_A_DAY)

			let reste = diff % NUMBER_OF_MILISEC_IN_A_DAY

			r.nbrFullHours = Math.floor(reste / NUMBER_OF_MILISEC_IN_AN_HOUR)

			reste %= NUMBER_OF_MILISEC_IN_AN_HOUR

			r.nbrFullMinutes = Math.floor(reste / NUMBER_OF_MILISEC_IN_A_MIN)

			reste %= NUMBER_OF_MILISEC_IN_A_MIN

			r.nbrFullSeconds = Math.floor(reste / NUMBER_OF_MILISEC_IN_A_SEC)

			return r
		},
		payForDuration() {
			const times = this.durationTimes

			const nbrStartedHours = times.nbrFullHours + 1
			let duration = ''

			if (times.nbrFullDays > 0) {
				duration = `${times.nbrFullDays} days `
			}

			duration = `${duration}${nbrStartedHours} hour`

			if (nbrStartedHours > 1) {
				duration = `${duration}s`
			}

			return duration
		},
		duration() {
			const times = this.durationTimes

			const durationItems = []

			if (times.nbrFullDays > 0) {
				durationItems.push(`${times.nbrFullDays} days`)
			}

			if (times.nbrFullHours > 0) {
				durationItems.push(`${times.nbrFullHours}h`)
			}

			if (times.nbrFullMinutes > 0) {
				durationItems.push(`${times.nbrFullMinutes}m`)
			}

			if (times.nbrFullSeconds > 0) {
				durationItems.push(`${times.nbrFullSeconds}s`)
			}

			return `${durationItems.join(' ')} (${this.payForDuration})`
		},
	},
	watch: {
		payForDuration() {
			this.refresh()
		},
	},
	methods: {
		confirmStop() {
			this.closeConfirmationModal()
			this.$emit('stop')
		},
		openConfirmationModal() {
			this.$emit('openModal')
			this.refresh()
		},
		showTheLeaveQrCode() {
			this.$emit('showTheLeaveQrCode')

			setTimeout(this.hideTheLeaveQrCode, 60000)
		},
		hideTheLeaveQrCode() {
			this.$emit('hideTheLeaveQrCode')
		},
		closeConfirmationModal() {
			this.iAmReady = false
			this.$emit('closeModal')
		},
		refreshDuration() {
			this.now = new Date()
		},
		cancelTimers() {
			clearInterval(this.timer)
		},
		refresh() {
			this.$emit('refresh')
		},
		date,
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/temporaryAccess/temporaryAccess';
</style>
