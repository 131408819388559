<template>
	<div>
		<div class="subscription">
			<Loading v-if="loading" />
			<Step1
				v-show="progress === 0"
				:data="dataStep1"
				:deposit.sync="deposit"
				@next-step="doStartSubscription"
			/>
			<Step2 v-if="progress === 1" v-model="dataStep2" :deposit="deposit" @next-step="nextStep" />
			<Step3
				v-if="!profileLoading && progress === 2"
				:data="dataStep3"
				@switch-step="switchStep(0)"
			/>
			<Progress :progress="progress" @switch-step="(step) => switchStep(step)" />
		</div>
	</div>
</template>

<script>
import Step1 from '@/components/subscription/Step1.vue'
import Step2 from '@/components/subscription/Step2.vue'
import Step3 from '@/components/subscription/Step3.vue'
import Progress from '@/components/subscription/Progress.vue'
import Loading from '@/components/Loading.vue'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import ValidationRequired from '@/mixins/validation-required'
import PhoneVerificationRequired from '@/mixins/phone-verification-required'

export default {
	name: 'Subscription',
	components: {
		Step1,
		Step2,
		Step3,
		Progress,
		Loading,
	},
	mixins: [ValidationRequired, PhoneVerificationRequired],
	props: {
		subType: {
			type: String,
			required: false,
			default: 'BigParking',
		},

		proposalId: {
			type: Number,
			required: false,
			default: null,
		},
		step: {
			required: false,
			default: '1',
			type: String,
		},
		subId: {
			type: Number,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			progress: 0,
			counter: 0,

			// loading: false,
			deposit: false,
			dataStep2: {},
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			profileLoading: (state) => state.profile.loading,
			currentSub: (state) => state.subscription.current,
			loading: (state) => state.subscription.isLoading,

			// useMobib: state => state.subscription.useMobib,

			// proposal: state => state.subscription.proposal,
			subscriptionError: (state) => {
				return { type: state.alert.type, message: state.alert.message }
			},
		}),
		...mapGetters({
			proposalParking: 'profile/proposalParking',
			subIsRetryableForPaymentLink: 'subscription/subIsRetryableForPaymentLink',
			isNewSubscription: 'subscription/isNewSubscription',
		}),
		dataStep1() {
			return {
				mobib: this.currentUser.mobib || null,
				isLoaded: !this.profileLoading,
				subType: this.subType,
				parking: this.proposalId ? this.proposalParking(this.proposalId) : {}, // this.isLoaded ? find({ id: this.proposalId }, this.currentUser.proposals).parking || {} : {},
			}
		},

		// dataStep2() {
		// 	return {
		// 		proposalId: this.proposal.id,
		// 	}
		// },
		dataStep3() {
			return {
				isLoaded: !this.profileLoading,
				currentUser: this.currentUser,
				subId: this.subId,
				proposalId: this.proposalId,
			}
		},
	},
	watch: {},

	created() {},
	async mounted() {
		// allow direct link to confirmation step

		if (!this.isLoaded) {
			await this.loadCurrentUser()
		}

		this.progress = parseInt(this.step, 10) - 1

		if (this.subId) {
			this.loadSubscription()
		}
	},
	beforeDestroy() {
		this.resetState()
		this.clearError()
	},
	methods: {
		...mapActions({
			updateMobib: 'profile/updateMobib',
			clearError: 'alert/clear',
			subscribe: 'subscription/subscribe',
			getSubscriptionToRetryPayment: 'subscription/getSubscriptionToRetryPayment',
		}),
		...mapMutations('subscription', {
			resetState: 'resetState',
			setLoading: 'setLoading',
		}),
		nextStep() {
			this.progress += 1
		},
		/* eslint-disable-next-line max-statements */
		async loadSubscription() {
			await this.getSubscriptionToRetryPayment({
				subscriptionId: this.subId,
				proposalId: this.proposalId,
			})

			// If sub status is active or toBeCancelled, you redirect to the profile page
			// If sub status is new, you do nothing (we'll stay on step1)
			// If the sub status is pending and if it is isPaymentLink, then we go to step2
			// If the sub status is pending and if it is not isPaymentLink, then we raise an error and reddirect to profile page
			if (this.currentSub) {
				if ((this.currentSub.deposits || []).length > 0) {
					this.deposit = true
				}
				const urlParams = new URLSearchParams(window.location.search);
				const redirectParam = urlParams.get('redirect');
				if (redirectParam === 'true') {

						this.progress = 1

				} else if (this.isNewSubscription) {
					
				} else if (this.subIsRetryableForPaymentLink) {
					// Can directly go to step2
					this.progress = 1 // step2 is progress 1 (0 is step1)
				} else {
					// sub is active (or toBeCancelled) or pending (but not retryable), we then redirect to the profile page
					this.$router.push({ name: 'profile' })
				}
			}
		},
		async doStartSubscription(mobib = null) {
			try {
				if (mobib) {
					this.setLoading(true)
					await this.updateMobib(mobib)
					this.setLoading(false)
				}

				if (!this.currentSub.id || this.isNewSubscription) {
					await this.subscribe({
						subscriptionTypeId: this.subType,
						proposalId: this.proposalId,
						withDeposit: this.deposit,
					})

					this.$router.push({
						query: Object.assign({}, this.$route.query, {
							subId: this.currentSub.id,
							step: this.progress+1
						}),
					})

					// try {
					// 	} catch (error) {
					// 	this.flash(this.subscriptionError.message, 'error')
					// }
				}

				this.nextStep()
			} catch (e) {
				// this.flash(this.$t('error.mobibUpdateFailed'), 'error')
				this.setLoading(false)
			}

			// this.loading = false
		},
		switchStep(step) {
			this.progress = step
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/subscription/subscription';
</style>
