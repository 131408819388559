var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-blue"},[(!_vm.sessionEnd || _vm.isLoading)?_c('Loading'):_c('div',{staticClass:"start-session-qr"},[_c('GenrateQr',{attrs:{"type":_vm.localizedData.scanType,"title":_vm.localizedData.title,"description":_vm.localizedData.description,"sessionId":_vm.sessionEnd ? _vm.sessionEnd.sessionId : '0',"qrCodeText":_vm.sessionEnd && _vm.sessionEnd.sessionQRCodes.length > 0
					? _vm.sessionEnd.sessionQRCodes[_vm.sessionEnd.sessionQRCodes.length - 1].qrCode
					: 'scan to encode',"numOfScan":_vm.sessionEnd
					? _vm.sessionEnd.sessionQRCodes.length > 0
						? _vm.sessionEnd.sessionQRCodes[_vm.sessionEnd.sessionQRCodes.length - 1].used
						: 0
					: 0,"totalScan":_vm.sessionEnd
					? _vm.sessionEnd.sessionQRCodes.length > 0
						? _vm.sessionEnd.sessionQRCodes[_vm.sessionEnd.sessionQRCodes.length - 1].usage
						: 0
					: 0,"message":_vm.localizedData.message}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }