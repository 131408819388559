<template>
	<div>
		<Loading v-if="loading" />
		<Menu activeMenu="attribution" />
		<div class="attribution">
			<div v-if="!loading" class="attribution-content-block">
				<div class="attribution-parkinglist-header">
					<h2 class="attribution-parking-info-header-title">Select a Parking</h2>

					<div class="admin-parking-search-field">
						<input
							ref="search"
							v-model="query"
							v-validate="{ required: true, min: 2 }"
							:placeholder="$t('admin.parking.searchParkingLabel')"
							:class="{ error: errors.has('parking-search-form.search') }"
							name="search"
							type="text"
							class="input-field admin-input-field small input admin-search-field"
							@input="debounceFilter"
						/>
						<p v-show="errors.has('parking-search-form.search')" class="input-error">
							{{ errors.first('parking-search-form.search') }}
						</p>
					</div>

					<Checkbox v-model="onlyAvailable" class="profile-checkbox" name="availableParkings">
						<span slot="label" class="checkbox-text text">
							{{ $t('admin.openParkingsOnlyCheckbox') }}
						</span>
					</Checkbox>
				</div>
				<div class="attribution-table">
					<div class="attribution-table-header">
						<div
							v-for="field in tableParkingFields"
							:key="field.key"
							:style="{ width: field.width }"
							class="attribution-table-column"
						>
							{{ field.label }}
						</div>
					</div>
					<div class="attribution-table-body">
						<div
							v-for="(parking, index) in filteredParkings"
							:key="`parking-${index}`"
							:class="{ selected: parking.id === selectedParking.id }"
							class="attribution-table-row"
							@click="doSelectParking(parking)"
						>
							<div
								v-for="field in tableParkingFields"
								:key="`parking-${field.key}`"
								:style="{ width: field.width }"
								class="attribution-table-column"
							>
								<span v-if="field.key === 'freeClassic'">
									{{ spotsAvailable(parking.id, false) }}
								</span>
								<span v-else-if="field.key === 'freeCargo'">
									{{ spotsAvailable(parking.id, true) }}
								</span>
								<span v-else>{{ parking[field.key] }}</span>
							</div>
						</div>
					</div>
				</div>
				<Loading v-if="attrLoading" />
				<ParkingSpots
					v-if="selectedParking.id && !attrLoading"
					:parking="selectedParking"
					:selectedParkingSpot="selectedParkingSpot"
					class="attribution-content-block"
					@selectParkingSpot="doSelectParkingSpot"
				/>
				<ParkingRequests
					v-if="selectedParking.id && !attrLoading"
					:requests="orderedRequests"
					:parking="selectedParking"
					:selectedParkingSpot="selectedParkingSpot"
					:distanceFilter="distanceFilter"
					class="attribution-content-block"
					@changeDistanceFilter="setDistanceFilter"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Checkbox from '@/components/form/Checkbox.vue'
import Loading from '@/components/Loading.vue'
import Menu from '@/components/admin/Menu'
import ParkingRequests from '@/components/admin/attribution/ParkingRequests'
import ParkingSpots from '@/components/admin/attribution/ParkingSpots'
import ValidationRequired from '@/mixins/validation-required'
import { debounce } from 'lodash'
import { filter } from 'lodash/fp'
import { parkingDistanceFilters, inputDebounceTime } from '@/config'

export default {
	name: 'Attribution',
	components: {
		Checkbox,
		Loading,
		ParkingRequests,
		ParkingSpots,
		Menu,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			onlyAvailable: true,
			query: '',
			debouncedQuery: '',
			debounceFilter: undefined,
			distanceFilter: parkingDistanceFilters.lower,
			tableParkingFields: [
				{ key: 'code', label: 'code', width: '17%' },
				{ key: 'name', label: 'Name', width: '32%' },
				{ key: 'parkingTypeName', label: 'Type', width: '17%' },
				{ key: 'freeClassic', label: 'Free classic', width: '17%' },
				{ key: 'freeCargo', label: 'Free cargo', width: '17%' },
			],
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			parkingsFetched: (state) => state.parkings.parkingsFetched,
			selectedParking: (state) => state.admin.attribution.selectedParking,
			selectedParkingSpot: (state) => state.admin.attribution.selectedParkingSpot,
			requests: (state) => state.admin.attribution.requests,
			attrLoading: (state) => state.admin.attribution.loading,
			loading: (state) => state.admin.loading,
		}),
		...mapGetters('admin', {
			orderedNotBigParkings: 'orderedNotBigParkings',
			spotsAvailable: 'spotsAvailable',

			orderedRequests: 'orderedRequests',
		}),
		filteredParkings() {
			const re = new RegExp(this.debouncedQuery, 'i')
			const allParkings = this.onlyAvailable
				? filter(
						(parking) => parking.spotsAvailableClassic > 0 || parking.spotsAvailableCargo > 0,
						this.orderedNotBigParkings
				  )
				: this.orderedNotBigParkings

			return filter(
				(parking) => parking.code && (parking.code.search(re) >= 0 || parking.name.search(re) >= 0),
				allParkings
			)
		},
	},
	created() {
		this.debounceFilter = debounce((e) => {
			this.debouncedQuery = e.target.value
		}, inputDebounceTime)
	},

	mounted() {
		return this.getAvailableParkings()
	},

	methods: {
		...mapActions({
			getAvailableParkings: 'admin/getAvailableParkings',
			selectParking: 'admin/selectParking',
			selectParkingSpot: 'admin/selectParkingSpot',
		}),
		doSelectParking(parking) {
			this.selectParkingSpot(parking.parkingSpots.length === 1 ? parking.parkingSpots[0] : {})
			this.selectParking(parking)

			// if there is only one parkingSpot, select that one by default
		},
		setDistanceFilter(val) {
			this.distanceFilter = parseInt(val, 10)
		},
		doSelectParkingSpot(spot) {
			this.selectParkingSpot(spot)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/attribution';
@import '../../assets/scss/pages/admin';
.admin-parking-search-field {
	margin: 0 100px;
	.input {
		margin: 0;
	}
}
</style>
