<template>
	<div>
		<label class="">{{ $t('profile.company.name') }}</label>
		<input
			:value="localCompany.name"
			:placeholder="$t('profile.company.name')"
			:class="{ error: errors.has('profile-form.companyName') }"
			:data-vv-as="$t('profile.company.name')"
			name="companyName"
			type="text"
			class="input-field profile-input-field input"
			@input="changeCompanyData($event.target.value, 'name')"
		/>
		<p v-show="errors.has('profile-form.companyName')" class="input-error">
			{{ errors.first('profile-form.companyName') }}
		</p>
		<label class="">{{ $t('profile.company.vatNumber') }}</label>
		<input
			v-validate="{ regex: /^[\w]{2}[0-9]{8,10}$/ }"
			:value="localCompany.vatNumber"
			:placeholder="`${$t('profile.company.vatNumber')} ${$t('profile.company.vatNumberExample')}`"
			:class="{ error: errors.has('profile-form.vatNumber') || customErrors.vatNumber }"
			:data-vv-as="$t('profile.company.vatNumber')"
			name="vatNumber"
			type="text"
			class="input-field profile-input-field input"
			@keyup="removeCustomError('vatNumber')"
			@input="changeCompanyData($event.target.value, 'vatNumber')"
		/>
		<p v-show="errors.has('profile-form.vatNumber')" class="input-error">
			{{ errors.first('profile-form.vatNumber') }}
		</p>
		<p v-show="!errors.has('profile-form.vatNumber') && customErrors.vatNumber" class="input-error">
			{{ $t('error.customFieldErrors.part1') }} {{ $t('profile.company.vatNumber') }}
			{{ $t('error.customFieldErrors.part3') }}
		</p>
		<div class="profile-addresses-form">
			<div class="profile-input-field-box">
				<label for="streetName" class="profile-input-field-label">
					{{ $t('profile.address.streetName') }}
				</label>
				<input
					v-validate="{ regex: /^([^0-9]*)$/ }"
					:value="localCompany.address.streetName"
					:placeholder="$t('profile.address.streetName')"
					:class="{ error: errors.has('profile-form.streetName') }"
					:data-vv-as="$t('profile.address.streetName')"
					name="streetName"
					type="text"
					class="input-field profile-input-field"
					autocomplete="address-line1"
					@input="changeCompanyData($event.target.value, 'streetName', true)"
				/>
			</div>
			<div class="profile-addresses-numbers">
				<div class="profile-input-field-box">
					<label for="houseNumber" class="profile-input-field-label">
						{{ $t('profile.address.houseNumber') }}
					</label>
					<input
						v-validate="{ regex: /\d/ }"
						:value="localCompany.address.houseNumber"
						:placeholder="$t('profile.address.houseNumber')"
						:class="{ error: errors.has('profile-form.houseNumber') }"
						:data-vv-as="$t('profile.address.houseNumber')"
						name="houseNumber"
						type="text"
						class="input-field inline profile-input-field"
						autocomplete="address-line2"
						@input="changeCompanyData($event.target.value, 'houseNumber', true)"
					/>
				</div>
				<div class="profile-input-field-box">
					<label for="bus" class="profile-input-field-label">
						{{ $t('profile.address.busNumber') }}
					</label>
					<input
						v-validate="{ regex: /[^a-zA-Z]/ }"
						:value="localCompany.address.busNumber"
						:placeholder="$t('profile.address.busNumber')"
						:class="{ error: errors.has('profile-form.bus') }"
						:data-vv-as="$t('profile.address.busNumber')"
						name="bus"
						type="text"
						class="input-field inline profile-input-field"
						autocomplete="address-line3"
						@input="changeCompanyData($event.target.value, 'busNumber', true)"
					/>
				</div>
			</div>
			<div class="profile-input-field-box">
				<label for="zip" class="profile-input-field-label">
					{{ $t('profile.address.postalCode') }}
				</label>
				<input
					v-validate="'length:4'"
					:value="localCompany.address.postalCode"
					:placeholder="$t('profile.address.postalCode')"
					:class="{ error: errors.has('profile-form.zip') }"
					:data-vv-as="$t('profile.address.postalCode')"
					name="zip"
					type="text"
					class="input-field profile-input-field"
					@input="changeCompanyData($event.target.value, 'postalCode', true)"
				/>
			</div>
			<div class="profile-input-field-box">
				<label for="city" class="profile-input-field-label">
					{{ $t('profile.address.city') }}
				</label>
				<input
					:value="localCompany.address.city"
					:placeholder="$t('profile.address.city')"
					:class="{ error: errors.has('profile-form.city') }"
					:data-vv-as="$t('profile.address.city')"
					name="city"
					type="text"
					class="input-field profile-input-field"
					@input="changeCompanyData($event.target.value, 'city', true)"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import { logger } from '@/logger'

export default {
	name: 'ProfileCompany',
	inject: ['$validator'],
	props: {
		company: {
			type: Object,
			default: () => ({}),
		},

		// name: {
		// 	type: String,
		// 	default: () => '',
		// },
		// vatNumber: {
		// 	type: String,
		// 	default: () => '',
		// },
		// address: {
		// 	type: Object,
		// 	default: () => ({}),
		// },
		customErrors: {
			type: Object,
			default: () => ({}),
		},
	},
	watch: {
		name(newValue) {
			logger.debug('ProfileCompany.name', newValue)

			// if (Object.keys(this.localCompany).length === 0 && this.localCompany.constructor === Object) {
			// 	this.localCompany = newValue
			// }
		},
	},
	created() {
		this.localCompany = { ...this.company }
	},
	methods: {
		removeCustomError(error) {
			this.$emit('removeCustomError', error)
		},
		changeCompanyData(val, key, address = false) {
			if (address) {
				this.localCompany.address[key] = val
			} else {
				this.localCompany[key] = val
			}

			this.$emit(`update:company`, this.localCompany)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
</style>
