<template>
	<div>
		<Loading v-if="isLoading" />
		<PreviousButton />
		<div class="forgot-pw">
			<h1 class="title forgot-pw-title">
				{{ $t('forgotPw.title') }}
			</h1>
			<!-- <p class="text forgot-pw-info">
				{{ $t('forgotPw.info') }}
			</p> -->
			<form class="forgot-pw-form" novalidate @submit.prevent="requestPasswordReset">
				<div class="input-box forgot-pw-input">
					<label class="forgot-pw-label" for="phone">
						{{ $t('profile.emailLabel') }}
					</label>
					<!-- :placeholder="$t('profile.phoneLabel')"
					<vue-tel-input
						v-model="phoneNumber"
						v-validate="'numeric'"
						:preferredCountries="['be', 'nl', 'fr']"
						:class="{ error: !!local.errors.phone }"
						:inputOptions="localizedData.options"
						name="phone"
						class="signup-phone-box input-field"
						required
						@input="onInputPhone"
					/> 

					 <p v-show="!!local.errors.phone" class="input-error">
						{{ local.errors.phone }}
					</p> -->
					<input
						v-model="email"
						v-validate="'required|email'"
						:placeholder="$t('profile.emailLabel')"
						:class="{ error: !!localErrors.email }"
						:data-vv-as="$t('profile.emailLabel')"
						name="email"
						type="email"
						class="input-field"
					/>

					<p v-show="localErrors.email" class="input-error">
						{{ localErrors.email }}
					</p>
				</div>
				<button
					:disabled="hasLocalErrors()"
					:class="{ disabled: hasLocalErrors() }"
					class="button red forgot-pw-button"
					type="submit"
				>
					{{ $t('forgotPw.requestLabel') }}
				</button>
			</form>

			<!-- <form
				v-show="passwordReset.userId"
				class="sms-validation-form"
				data-vv-scope="code-form"
				@submit.prevent=""
			>
				<p class="text sms-validation-info">
					{{ $t('smsValidation.validateInfo') }}
				</p>
				<div class="input-box sms-validation-input">
					<input
						v-model="smsCode"
						v-validate="'numeric|min:4'"
						:placeholder="$t('smsValidation.codeLabel')"
						:class="{ error: errors.has('code-form.accessCode') }"
						maxlength="4"
						name="accessCode"
						type="text"
						class="input-field"
					/>
					<p v-if="errors.has('code-form.accessCode')" class="input-error">
						{{ errors.first('code-form.accessCode') }}
					</p>
				</div>
				<button
					:disabled="errors.has('code-form.accessCode') || !smsCode"
					:class="{ disabled: errors.has('code-form.accessCode') || !smsCode }"
					class="button blue sms-validation-button"
					@click="doValidateToken"
				>
					{{ $t('smsValidation.validateLabel') }}
				</button>
			</form> -->
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import PreviousButton from '@/components/PreviousButton.vue'

export default {
	name: 'ForgotPassword',
	components: {
		Loading,
		PreviousButton,
	},
	data() {
		return {
			phoneNumber: '',
			// smsCode: '',
			local: {
				phoneNumber: '',
				errors: {
					phone: '',
				},
			},
			email: '',
			localErrors: {
				email: '',
			},
			options: {
				placeholder: this.$t('profile.phoneLabel'),
			},
		}
	},
	computed: {
		...mapState({
			alert: (state) => state.alert,
			isLoading: (state) => state.authentication.loading,
			passwordReset: (state) => state.authentication.passwordReset,
		}),
		localizedData() {
			return {
				options: { placeholder: this.$t('profile.phoneLabel') },
			}
		},
	},
	methods: {
		...mapActions({
			requestEmailAuth: 'authentication/requestEmailAuth',
			// validateToken: 'authentication/validateResetToken',
		}),
		hasLocalErrors() {
			// return (
			// 	Object.keys(this.local.errors).some((key) => this.local.errors[key]) ||
			// 	(this.phoneNumber || '').length < 1
			// )

			return Boolean(this.localErrors.email)
		},
		onInputPhone(value, phoneObject) {
			this.local.phoneNumber = phoneObject.number

			const empty = (value || '').length < 1

			this.local.errors.phone = empty || phoneObject.valid ? '' : this.$t('error.notValidPhone')
		},

		async requestPasswordReset() {
			// We store the phoneNumber in a local val on the 'onInputPhone' event because of a bug with the vue-phone-number-input component
			// When we copy-past a phone number in the field when it was already filled-in the country-code was not added.
			// This way, we make sure that the phone number is always correct.
			// this.email = this.local.email

			await this.requestEmailAuth(this.email)

			// this.flash(this.alert.message, this.alert.type)
		},
		// async doValidateToken() {
		// 	await this.validateToken({ pin: this.smsCode, userId: this.passwordReset.userId })
		// 	// this.flash(this.alert.message, this.alert.type)
		// },
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/forgotPw';
@import '../../assets/scss/pages/smsValidation';
</style>
