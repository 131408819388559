export const getPaginationList = (totalSize, currentPage) => {
	let paginationList = []
	let total = totalSize || 0;

	if (currentPage > 0 && currentPage < 3) {
		let generateTill = total < 4 ? total : 3
		paginationList.push(...generatePaginatinList(1, generateTill))
		if (total > 3) {
			paginationList.push({ pageNumber: null })
			paginationList.push({ pageNumber: total })
		}
	} else if (currentPage > 2 && currentPage < total - 1) {
		paginationList.push({ pageNumber: 1 })
		paginationList.push({ pageNumber: null })
		paginationList.push(...generatePaginatinList((currentPage - 1), (currentPage + 1)))
		paginationList.push({ pageNumber: null })
		paginationList.push({ pageNumber: total })
	} else {
		let generateFrom = (total - 2) || 1
		generateFrom = (generateFrom == 1 && total > 3) ? 2 : generateFrom
		if (total > 3) {
			paginationList.push({ pageNumber: 1 })
			paginationList.push({ pageNumber: null })
		}
		paginationList.push(...generatePaginatinList(generateFrom, total))
	}
	return paginationList;
}
const generatePaginatinList = (from, to) => {
	let paginationList = []
	for (let index = from; index <= to; index++) {
		paginationList.push({ pageNumber: index })
	}
	return paginationList;
}