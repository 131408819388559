import 'setimmediate'
import { config } from './config'

// import { createLogger, transports, format } from 'winston'

// export const logger = createLogger({
// 	transports: [
// 		new transports.Console({
// 			level: config.consoleLogLevel,
// 			format: format.combine(
// 				format.splat(),
// 				format.simple(),
// 			),
// 			stderrLevels: ['error'],
// 			consoleWarnLevels: ['warn'],
// 		}),
// 	],
// })

const levels = new Map([
	['silly', 4],
	['debug', 3],
	['info', 2],
	['warn', 1],
	['error', 0],
])
const logLevel = levels.get(config.consoleLogLevel)

/* eslint-disable no-console */
const log = (level, ...args) => {
	if (levels.get(level) <= logLevel) {
		switch (level) {
			case 'error':
				console.error(...args)
				break
			case 'warn':
				console.warn(...args)
				break
			default:
				console.log(...args)
				break
		}
	}
}
/* eslint-enable no-console */

export const logger = {
	log,
	silly: (...args) => log('silly', ...args),
	debug: (...args) => log('debug', ...args),
	info: (...args) => log('info', ...args),
	warn: (...args) => log('warn', ...args),
	error: (...args) => log('error', ...args),
}
