<template>
	<div
		:class="{ toApprove: parking.parkingTypeName === 'PrivateParkings' && !parking.approved }"
		class="admin-item-row clickable"
		@click="$emit('on-click', parking.id)"
	>
		<div class="admin-item-col smaller">
			{{ parking.code }}
		</div>
		<div class="admin-item-col small">
			{{ parking.name }}
		</div>
		<div class="admin-item-col small">
			{{ date(parking.installationDate) }}
		</div>
		<div v-if="parking.parkingTypeName" class="admin-item-col small">
			{{ $t(`parkings.parkingTypes.${parking.parkingTypeName.toLowerCase()}`) }}
		</div>
		<div class="admin-item-col smaller">
			{{ parking.active ? 'Y' : 'N' }}
		</div>
		<div class="admin-item-col smaller">
			{{ parking.isCycloparking ? 'Y' : 'N' }}
		</div>
		<div v-if="!parking.ownerId" class="admin-item-col smaller">
			{{ parking.badgeAccess ? 'Y' : 'N' }}
		</div>
	</div>
</template>

<script>
import { date } from '@/helpers'

export default {
	name: 'ParkingResult',
	components: {},
	props: {
		parking: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {}
	},
	computed: {},
	methods: {
		date,
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}

.admin-item-row.toApprove {
	background-color: lighten($red, 20);
}
</style>
