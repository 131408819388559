<template>
	<QRCode :text="text"></QRCode>
</template>
<script>
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
	name: 'QrCode',
	components: {
		QRCode: VueQRCodeComponent,
	},
	props: {
		text: {
			type: String,
			required: true,
		},
	},
}
</script>
