<template>
	<div :class="{ legacy: !request.address }" class="profile-subscriptions-profile-table-content">
		<div class="profile-subscriptions-profile-table-content-row">
			<div class="profile-subscriptions-profile-table-content-item text">
				{{ $t(`profile.request.requestLabel`) }}
			</div>
			<div
				:class="`profile-subscriptions-profile-table-content-item-icon ${
					request.isCargo ? 'cargo' : 'classic'
				}`"
			/>
		</div>
		<div class="profile-subscriptions-profile-table-content-row">
			<div
				class="profile-subscriptions-profile-table-content-row profile-subscriptions-profile-table-content-item address"
			>
				<span v-if="isSuggestion(request.type)">{{ $t(`request.types.${request.type}`) }}:</span>
				<img
					src="../../assets//images/marker-blue.svg"
					alt="Add Circle Icon"
					class="icon-svg"
					style="width: 20px; margin-right: 10px"
				/>
				{{
					request.address
						? `${request.address.streetName} ${request.address.houseNumber}`
						: request.comment.replace(/-\s*$/g, '')
				}}
			</div>
		</div>
		<div class="profile-subscriptions-profile-table-content-row align-top">
			<div class="profile-subscriptions-profile-table-content-item">
				<label class="profile-subscriptions-profile-table-content-item label"></label>
				<!-- STATUT -->
				<div class="profile-subscriptions-profile-table-content-row align-top align-icon">
					<div class="profile-subscriptions-profile-table-content-item text">
						<span v-if="(request.parkingsInRange || []).length > 0">{{
							$t('profile.request.requestProcessing')
						}}</span>
						<span v-else-if="Date.now() - request.createdAt < processingTime">{{
							$t('profile.request.requestProcessing')
						}}</span>
						<span v-else>{{ $t('profile.request.noParkingsInRange') }}</span>
					</div>
					<!-- v-if="request.address" -->
					<div
						v-if="
							(request.parkingsInRange || []).length > 0 ||
							Date.now() - request.createdAt < processingTime
						"
						class="info request-info-icon profile-subscriptions-profile-table-content-item icon-actions"
						@mouseenter="toggleModal"
						@mouseleave="toggleModal"
					>
						<div class="profile-subscriptions-profile-table-content-item info-icon">?</div>
						<div></div>
						<!-- <div class="">
						<div
							v-if="(request.parkingsInRange || []).length > 0"
							class="profile-subscriptions-profile-table-content-item text"
						> -->
						<!-- <div class="text" style="margin: 5px 0px"> -->
						<!-- {{ request.parkingsInRange.map((p) => p.code).join(', ') }} -->
						<!-- In progress  -->
						<!-- </div> -->
						<!-- </div>
						<div
							v-else-if="Date.now() - request.createdAt < processingTime"
							class="profile-subscriptions-profile-table-content-item text"
						>
							{{ $t('profile.request.requestProcessing') }}
						</div>
						<div v-else class="profile-subscriptions-profile-table-content-item text">
							{{ $t('profile.request.noParkingsInRange') }}
						</div>
					</div> -->
						<div v-if="showRequestInfo" class="info-modal large">
							<div>
								<div v-if="(request.parkingsInRange || []).length > 0">
									{{ $t('profile.request.parkingsInRange') }}
									<br />
									<div class="text" style="margin: 5px 0px">
										{{ request.parkingsInRange.map((p) => p.code).join(', ') }}
									</div>
								</div>

								<div style="display: flex; justify-content: space-between">
									<a
										:href="$t('general.navigationLinks.faq')"
										class="link"
										@mousedown.native="clickLink"
									>
										{{ $t('general.navigation.faq') }}
									</a>
									<router-link
										:to="{ name: 'parkings', params: { focus: request.address.id } }"
										class="link"
									>
										{{ $t('profile.request.showOnMap') }}
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="profile-subscriptions-profile-table-content-item">
				<label class="profile-subscriptions-profile-table-content-item label">
					{{ $t('profile.request.requestedAt') }}
				</label>
				<div class="profile-subscriptions-profile-table-content-item text">
					{{ date(request.requestedAt) }}
				</div>
			</div>
		</div>
		<div class="profile-subscriptions-profile-table-content-row">
			<!-- class="button-delete request-button-delete small red profile-subscriptions-profile-table-content-item icon-actions" -->
			<div
				v-if="!disableCancel && request.address"
				class="profile-subscriptions-profile-table-content-item right profile-subscriptions-profile-action"
				@mousedown="toggleDeleteRequestModal({ open: true, id: request.id })"
				@keyup.enter="toggleDeleteRequestModal({ open: true, id: request.id })"
			>
				{{ $t('profile.request.deleteRequestButton') }}
			</div>
			<!-- @mousedown="doCancelRequest($event, request.id)"
				@keyup.enter="doCancelRequest($event, request.id)" -->
			<div v-else class="profile-subscriptions-profile-table-content-item actions" />
		</div>
		<SessionModal
			:showModal="deleteRequestModalOpen"
			@close="toggleDeleteRequestModal({ open: false })"
			@confirm="doCancelRequest"
		>
			<template v-slot:title>
				<h1>{{ localizedData.deleteRequestTitle }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ localizedData.deleteRequestDescription }}
				</p>
			</template>
			<template v-slot:confirmButton>
				<button @click="doCancelRequest($event, requestDeleteId)">
					{{ localizedData.deleteRequestButton }}
				</button>
			</template>
		</SessionModal>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { date } from '@/helpers'
import SessionModal from '../SessionModal.vue'

export default {
	name: 'Request',
	components: { SessionModal },
	props: {
		request: {
			type: Object,
			default: () => ({}),
		},
		disableCancel: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showRequestInfo: false,
			processingTime: 57600000,
			deleteRequestModalOpen: false,
			requestDeleteId: null,
		}
	},
	computed: {
		localizedData() {
			return {
				deleteRequestTitle: this.$t('profile.request.deleteRequestTitle'),
				deleteRequestDescription: this.$t('profile.request.deleteRequestDescription'),
				deleteRequestButton: this.$t('profile.request.deleteRequestButton'),
			}
		},
	},
	methods: {
		...mapActions('profile', {
			cancelParkingRequest: 'cancelParkingRequest',
		}),
		toggleDeleteRequestModal(option = {}) {
			if ('open' in option) {
				this.deleteRequestModalOpen = option.open
				this.requestDeleteId = option.id
			} else {
				this.deleteRequestModalOpen = !this.deleteRequestModalOpen
				this.requestDeleteId = null
			}
		},
		doCancelRequest(e, id) {
			if (e) {
				e.preventDefault()
			}

			if (e.which === 1 || e.button === 0) {
				this.cancelParkingRequest(id)
			}
		},
		isSuggestion(type) {
			return type === 'parking'
		},
		toggleModal() {
			this.showRequestInfo = !this.showRequestInfo
		},
		date,
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
@import '../../assets/scss/components/info';

.request-button-delete {
	width: 20px;
	height: 20px;
}

.request-info-icon {
	position: relative;
	line-height: normal;
}
</style>
