<template>
	<div
		:class="{
			addressRequest: request.addressId,
			cargoRequestVsSpotMismatch: cargoRequestVsSpotMismatch(),
		}"
		class="attribution-table-row parking-request-table-row"
	>
		<div
			class="attribution-table-column user-field"
			@mouseenter="toggleModal"
			@mouseleave="toggleModal"
		>
			<router-link :to="{ name: 'admin', params: { search: request.user.email } }">
				{{ name }}
			</router-link>
		</div>
		<div class="attribution-table-column">
			<span v-if="!showUserInfo">{{
				request.addressId ? `Address: ${request.address.addressType}` : 'legacy'
			}}</span>
			<span v-if="showUserInfo" class="info-modal large">
				{{ activeSubNames }}
			</span>
		</div>
		<div class="attribution-table-column distance-value tiny">
			{{ request.walkingDistance }}
		</div>
		<div class="attribution-table-column">
			{{ date(request.requestedAt) }}
		</div>
		<div class="attribution-table-column tiny">
			{{ request.isCargo ? 'YES' : 'NO' }}
		</div>
		<div class="attribution-table-column">
			{{ declineDate() }}
		</div>
		<div class="attribution-table-column small">
			<div
				v-if="activeProp"
				class="attribution-table-column-button button red small"
				@click="doCancelProposal(activeProp)"
			>
				cancel
			</div>
			<div v-else style="display: flex">
				<div
					v-if="canAssignProposalFor()"
					class="attribution-table-column-button button blue small"
					@click="doCreateProposal()"
				>
					assign
				</div>
				<div
					v-if="!request.address"
					class="button-delete small red"
					@mousedown="doCancelRequest($event, request.requestId)"
					@keyup.enter="doCancelRequest($event, request.requestId)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { date } from '@/helpers'
import { findLast, sortBy, isEmpty } from 'lodash/fp'
import { PROPOSAL_STATES, SUBSCRIPTION_STATES } from '@/config'

// import { logger } from '@/logger'

export default {
	name: 'ParkingRequest',
	components: {},
	props: {
		request: {
			type: Object,
			default: () => ({}),
		},
		parking: {
			type: Object,
			default: () => ({}),
		},
		selectedParkingSpot: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			local: {},
			showUserInfo: false,
		}
	},
	computed: {
		...mapGetters('admin', {
			spotAvailableInSelected: 'spotAvailableInSelected',
			activeProposal: 'activeProposal',
		}),
		activeProp() {
			return this.activeProposal(this.request.requestId, this.parking.id)
		},
		name() {
			return `${this.request.user.lastName} ${this.request.user.firstName}`
		},
		activeSubNames() {
			return isEmpty(this.request.user.subscriptions)
				? 'No active subs'
				: `Active subs on ${this.request.user.subscriptions
						.filter((s) => s.status === SUBSCRIPTION_STATES.active)
						.map((s) => this.parkingNameDisplay(s))
						.join(', ')}`
		},
	},
	created() {},

	methods: {
		...mapActions('admin', {
			createProposal: 'createProposal',
			cancelProposal: 'cancelProposal',
			cancelParkingRequest: 'cancelParkingRequest',
		}),
		...mapMutations('admin', {}),
		doCreateProposal() {
			this.createProposal({
				requestId: this.request.requestId,
				parkingId: this.parking.id,
				parkingSpotId: this.selectedParkingSpot.id,
			})
		},
		doCancelProposal(proposal) {
			if (confirm('Are you sure you want to cancel this proposal?')) {
				this.cancelProposal(proposal)
			}
		},
		doCancelRequest(e, id) {
			if (e) {
				e.preventDefault()
			}

			if (confirm('Are you sure you want to cancel the request for this user?')) {
				this.cancelParkingRequest(id)
			}
		},
		declineDate() {
			if (this.activeProp) {
				if (this.activeProp.isCargo !== this.request.isCargo) {
					if (this.activeProp.isCargo) {
						return '! Cargo proposal for a standard request!'
					} else {
						return '! Standard proposal for a cargo request!'
					}
				} else {
					return ''
				}
			} else {
				const declinedProposal = findLast(
					(prop) =>
						prop.parkingId === this.parking.id &&
						(prop.status === PROPOSAL_STATES.expired || prop.status === PROPOSAL_STATES.rejected),
					sortBy((p) => p.lastStatusChangedAt, this.request.proposals)
				)

				return declinedProposal
					? `${date(declinedProposal.lastStatusChangedAt)} (${
							declinedProposal.status === PROPOSAL_STATES.expired ? 'exp' : 'decl'
					  })`
					: ''
			}
		},
		canAssignProposalFor() {
			if (
				this.selectedParkingSpot.id &&
				this.spotAvailableInSelected(this.selectedParkingSpot.isCargo)
			) {
				return true
			} else {
				return false
			}
		},
		toggleModal() {
			this.showUserInfo = !this.showUserInfo
		},
		cargoRequestVsSpotMismatch() {
			return (
				!this.activeProp &&
				this.selectedParkingSpot.id &&
				this.selectedParkingSpot.isCargo !== this.request.isCargo
			)
		},
		parkingNameDisplay(sub) {
			return sub.parkingCode ? `${sub.parkingCode} (${sub.walkingDistance || '? '}m)` : 'BP'
		},
		date,
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/attribution';
@import '../../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}
.info-modal {
	color: $red;
}
.addressRequest {
	background-color: lighten(#ffd022, 30);
}
.cargoRequestVsSpotMismatch {
	background-color: #eda132;
}
</style>
