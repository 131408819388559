import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import Vue from 'vue'
import { logger } from '@/logger'
import {
	sortBy,
	findIndex,
	find,
	filter,
	values,
	groupBy,
	flatten,
	map,
	min,
	compose,
} from 'lodash/fp'
import {
	parkingDistanceFilters,
	PROPOSAL_STATES,
	REQUEST_STATES,
	SUBSCRIPTION_STATES,
} from '@/config'

// Initial state
const initialState = () => ({
	availableParkings: [],
	selectedParking: {},
	selectedParkingSpot: {},
	requestsForParking: {},
	requests: [],
	loading: false,
})

export const parkingProps = `
	id
	name
	code
	installationDate
	latitude
	longitude
	parkingTypeName
	capacityCargo
	capacityClassic
	spotsAvailableClassic
	spotsAvailableCargo
	pendingSubsClassic
	pendingSubsCargo
	active
	proposals {
		id
		status
		createdAt
		lastStatusChangedAt
		isCargo
		parkingId
	}
	parkingSpots {
	    id
	    amount
	    name
	    isCargo
	    active
	}
`

const state = initialState()

const availabilityForClassic = (parking) => {
	// return parking.spotsAvailableClassic - filter((p) => p.status === 1 && !p.isCargo, parking.proposals || []).length

	return (
		parking.spotsAvailableClassic - parking.pendingSubsClassic - (parking.localClassicAssigns || 0)
	)
}

const availabilityForCargo = (parking) => {
	// return parking.spotsAvailableCargo - filter((p) => p.status === 1 && p.isCargo, parking.proposals || []).length
	return parking.spotsAvailableCargo - parking.pendingSubsCargo - (parking.localCargoAssigns || 0)
}

const activeProposal = (state, requestId, parkingId) => {
	const request = find({ requestId }, state.requests)

	return find((prop) => prop.status === 1 && prop.parkingId === parkingId, request.proposals || [])
}

const getters = {
	orderedNotBigParkings: (state) => {
		return filter((p) => p.parkingTypeName !== 'BigParking')(
			sortBy('code', state.availableParkings)
		)
	},

	// group legacy and address requests by user before sorting by date requested
	orderedRequests: (state) => {
		// return sortBy('requestedAt', state.requests)
		// return flatten(sortBy((gr) => min(map('requestedAt', gr)), values(groupBy((r) => r.user.lastName, state.requests))))

		const orderedReqs = compose(
			flatten,
			sortBy((gr) => min(map('requestedAt', gr))),
			values,
			groupBy((r) => r.user.email),
			map((r) =>
				Object.assign({}, r, {
					closestSubDist: min(
						map(
							(s) => s.walkingDistance,
							filter(
								(s) => s.parkingCode,
								r.user.subscriptions.filter((s) => s.status === SUBSCRIPTION_STATES.active)
							)
						)
					),
				})
			)
		)(state.requests)

		// display request with an active proposal on top
		return [
			...filter((r) => find((p) => p.status === 1, r.proposals), orderedReqs),
			...filter((r) => !find((p) => p.status === 1, r.proposals), orderedReqs),
		]
	},
	spotsAvailable: (state) => (parkingId, cargo) => {
		const parking = find({ id: parkingId }, state.availableParkings)

		return cargo ? availabilityForCargo(parking) : availabilityForClassic(parking)
	},
	spotAvailableInSelected: (state) => (cargo) => {
		return cargo
			? availabilityForCargo(state.selectedParking) > 0
			: availabilityForClassic(state.selectedParking) > 0
	},
	activeProposal: (state) => (requestId, parkingId) => {
		return activeProposal(state, requestId, parkingId)
	},
}

const actions = {
	async getAvailableParkings({ commit }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query parkingsAvailable {
						parkingsAvailable {
							${parkingProps}
						}
					}
				`,
			})
			const parkings = response.data.parkingsAvailable

			commit('setAvailableParkings', parkings)
		} catch (e) {
			logger.error('Problem retrieving parkings', e)
		} finally {
			commit('setLoading', false)
		}
	},

	selectParking({ commit, dispatch }, parking) {
		commit('setSelectedParking', parking)
		dispatch('getRequests', parking.id)
	},
	selectParkingSpot({ commit }, parkingSpot) {
		commit('setSelectedParkingSpot', parkingSpot)
	},
	async getRequests({ commit }, parkingId) {
		commit('setAttrLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query parkingRequests($parkingId: Int!, $walkingDistance: Int) {
						parkingRequests(parkingId: $parkingId, walkingDistance: $walkingDistance) {
							id
							requests {
								requestId
								requestedAt
								comment
								isCargo
								walkingDistance
								proposals {
									id
									status
									createdAt
									lastStatusChangedAt
									isCargo
									parkingId
								}
								addressId
								address {
									id
									addressType
								}
								user {
									id
									email
									firstName
									lastName
									subscriptions {
										id
										parkingCode
										walkingDistance
										status
									}
								}
							}
						}
					}
				`,
				variables: {
					parkingId,
					walkingDistance: parkingDistanceFilters.upper,
				},
			})

			logger.debug(response)

			const requests = response.data.parkingRequests

			commit('setRequests', requests)
		} catch (e) {
			logger.error('Problem retrieving parking requests', e)
		} finally {
			commit('setAttrLoading', false)
		}
	},

	async createProposal({ commit, dispatch }, { requestId, parkingId, parkingSpotId }) {
		commit('setAttrLoading', true)

		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation createProposal($requestId: Int!, $parkingId: Int!, $parkingSpotId: Int!) {
					createProposal(
						requestId: $requestId
						parkingId: $parkingId
						parkingSpotId: $parkingSpotId
					) {
						id
						status
						createdAt
						isCargo
						parkingId
					}
				}
			`,
			variables: {
				parkingId,
				requestId,
				parkingSpotId,
			},
		})

		if (response.data.createProposal) {
			commit('createProposal', { proposal: response.data.createProposal, requestId })
		} else {
			dispatch('alert/error', 'No available capacity for proposal', { root: true })
		}

		commit('setAttrLoading', false)
	},

	async cancelProposal({ commit, dispatch }, proposal) {
		commit('setAttrLoading', true)

		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation cancelProposal($id: Int!) {
					cancelProposal(id: $id)
				}
			`,
			variables: {
				id: proposal.id,
			},
		})

		if (response.data.cancelProposal) {
			dispatch('alert/success', `Proposal ${proposal.id} cancelled`, { root: true })
			commit('cancelProposal', proposal)
		} else {
			dispatch('alert/error', `Proposal ${proposal.id} cancel failed`, { root: true })
		}

		commit('setAttrLoading', false)
	},

	async cancelParkingRequest({ commit, dispatch }, requestId) {
		commit('setAttrLoading', true)

		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation cancelParkingRequest($id: Int!) {
					cancelParkingRequest(id: $id)
				}
			`,
			variables: {
				id: requestId,
			},
		})

		if (response.data.cancelParkingRequest) {
			dispatch('alert/success', `Request cancelled`, { root: true })
			commit('cancelParkingRequest', requestId)
		} else {
			dispatch('alert/error', `Request cancel failed`, { root: true })
		}

		commit('setAttrLoading', false)
	},
}

const mutations = {
	setAvailableParkings(state, parkings) {
		state.availableParkings = parkings
	},
	setSelectedParking(state, parking) {
		state.selectedParking = parking
	},
	setSelectedParkingSpot(state, parkingSpot) {
		state.selectedParkingSpot = parkingSpot
	},
	setRequests(state, parkingRequests) {
		Vue.set(state.requestsForParking, parkingRequests.id, parkingRequests.requests)
		state.requests = parkingRequests.requests
	},
	setAttrLoading(state, status) {
		state.loading = status
	},
	createProposal(state, { requestId, proposal }) {
		const idx = findIndex((req) => req.requestId === requestId, state.requests)
		const parkingIdx = findIndex(
			(parking) => state.selectedParking.id === parking.id,
			state.availableParkings
		)

		if (idx >= 0) {
			if (state.requests[idx].proposals) {
				state.requests[idx].proposals.push(proposal)
			} else {
				Vue.set(state.requests[idx], 'proposals', [proposal])
			}
		}

		if (parkingIdx >= 0) {
			if (state.availableParkings[parkingIdx].proposals) {
				state.availableParkings[parkingIdx].proposals.push(proposal)
				proposal.isCargo
					? Vue.set(
							state.availableParkings[parkingIdx],
							'localCargoAssigns',
							(state.availableParkings[parkingIdx].localCargoAssigns || 0) + 1
					  )
					: Vue.set(
							state.availableParkings[parkingIdx],
							'localClassicAssigns',
							(state.availableParkings[parkingIdx].localClassicAssigns || 0) + 1
					  )
			} else {
				Vue.set(state.availableParkings[parkingIdx], 'proposals', [proposal])
			}
		}
	},
	cancelProposal(state, proposal) {
		Vue.set(proposal, 'status', PROPOSAL_STATES.cancelled)
		Vue.set(
			find({ id: proposal.id }, state.selectedParking.proposals),
			'status',
			PROPOSAL_STATES.cancelled
		)

		const parkingIdx = findIndex(
			(parking) => state.selectedParking.id === parking.id,
			state.availableParkings
		)

		proposal.isCargo
			? Vue.set(
					state.availableParkings[parkingIdx],
					'localCargoAssigns',
					(state.availableParkings[parkingIdx].localCargoAssigns || 0) - 1
			  )
			: Vue.set(
					state.availableParkings[parkingIdx],
					'localClassicAssigns',
					(state.availableParkings[parkingIdx].localClassicAssigns || 0) - 1
			  )
	},

	cancelParkingRequest(state, requestId) {
		Vue.set(find({ requestId }, state.requests), 'status', REQUEST_STATES.cancelled)
		Vue.delete(state.requests, findIndex({ requestId }, state.requests))
		Vue.set(state.requestsForParking, state.selectParking.id, state.requests)

		// Vue.delete(state.requestsForParking, state.selectParking.id)
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
