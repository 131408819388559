<template>
	<div class="attribution-parking-info">
		<div class="attribution-parking-info-header">
			<h2 class="attribution-parking-info-header-title">
				Select a ParkingSpot price for "{{ parking.code }} - {{ parking.name }}"
			</h2>
		</div>
		<h2 v-if="parkingSpots.length === 0">
			This parking has no parkingSpots. You can add one in the Parking admin pannel.
		</h2>
		<div v-else class="attribution-table">
			<div class="attribution-table-header">
				<div
					v-for="field in tableParkingSpotFields"
					:key="field.key"
					:class="`attribution-table-column ${field.size}`"
				>
					{{ field.label }}
				</div>
			</div>
			<div class="attribution-table-body">
				<div
					v-for="(spot, index) in parkingSpots"
					:key="`spot-${index}`"
					:class="{ selected: spot.id === selectedParkingSpot.id }"
					class="attribution-table-row"
					@click="$emit('selectParkingSpot', spot)"
				>
					<div
						v-for="field in tableParkingSpotFields"
						:key="`spot-${field.key}`"
						:class="`attribution-table-column ${field.size}`"
					>
						<span v-if="field.key === 'isCargo'">
							{{ spot.isCargo ? 'YES' : 'NO' }}
						</span>
						<span v-else-if="field.key === 'amount'"> € {{ spot[field.key] }} </span>
						<span v-else>{{ spot[field.key] }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

// import { logger } from '@/logger'

export default {
	name: 'ParkingSpots',
	components: {},
	props: {
		parking: {
			type: Object,
			default: () => ({}),
		},
		selectedParkingSpot: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			local: {},
			tableParkingSpotFields: [
				{ key: 'name', label: 'Description' },
				{ key: 'amount', label: 'Price' },
				{ key: 'isCargo', label: 'cargo', size: 'tiny' },
			],
		}
	},
	computed: {
		...mapGetters('admin', {}),
		parkingSpots() {
			return this.parking.parkingSpots.filter((p) => p.active)
		},
	},
	created() {},

	methods: {},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/attribution';
@import '../../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}
</style>
