<template>
	<div class="subscription-wrapper">
		<Loading v-if="isLoading || voucher.isLoading" />
		<h1 class="title subscription-title">
			{{ $t('subscription.titleStep2') }}
		</h1>
		<div v-if="subscription && subscription.subscriptionType" class="subscription-payment-info">
			<div class="subscription-payment-info-row">
				<p class="text">
					{{ $t('subscription.subscriptionPlan') }}:
					{{
						tryTranslate(subscription.subscriptionType.name, 'subscription.subscriptionPlanNames')
					}}
				</p>
				<p class="text subscription-payment-info-price">
					&euro;{{ priceParking }} ({{
						tryTranslate(subscription.subscriptionType.duration, 'subscription.durations')
					}})
				</p>
			</div>
			<div v-if="deposit" class="subscription-payment-info-row">
				<p class="text">
					{{ $t('subscription.keyLabel') }}
				</p>
				<p class="text subscription-payment-info-price">&euro;{{ priceKey }}</p>
			</div>
			<div v-if="priceReduction > 0" class="subscription-payment-info-row">
				<p class="text">
					{{ $t('subscription.reductionLabel') }}
				</p>
				<p class="text subscription-payment-info-price">- &euro;{{ priceReduction }}</p>
			</div>
			<div class="subscription-payment-info-row total">
				<p class="text subscription-payment-info-price total">
					{{ $t('subscription.totalLabel') }}
				</p>
				<p class="text subscription-payment-info-price total">&euro;{{ priceTotal }}</p>
			</div>
		</div>
		<p
			class="text"
			v-html="isBigParking ? $t('subscription.infoStep2BP') : $t('subscription.infoStep2')"
		/>

		<div class="subscription-content">
			<template>
				<h3 class="subscription-field-title">
					{{ $t('subscription.voucherLabel') }}
				</h3>
				<form class="subscription-voucher-form" @submit.prevent="doValidateVoucher($event)">
					<div class="subscription-voucher-input-box">
						<input
							v-model="voucherName"
							:placeholder="$t('subscription.voucherLabel')"
							:class="{
								valid: voucherValid,
								error: voucherInvalid,
								disabled: subIsPending,
							}"
							:disabled="subIsPending"
							name="voucher"
							type="text"
							class="input-field subscription-input-field"
							autocorrect="off"
							autocapitalize="none"
						/>
						<p v-if="voucher.status === 'invalid'" class="input-error">
							{{ $t('error.notValidVoucher') }}
						</p>
						<p v-if="voucher.status === 'expired'" class="input-error">
							{{ $t('error.expiredVoucher') }}
						</p>
					</div>
					<button
						:disabled="!voucherCanValidate()"
						:class="{ disabled: !voucherCanValidate() }"
						type="submit"
						class="button blue subscription-voucher-button"
					>
						{{ $t('subscription.validate') }}
					</button>
				</form>
			</template>
			<h3 class="subscription-field-title subscription-payment-title">
				{{ $t('subscription.paymentMethodLabel') }}<span v-if="creditCardOpen">*</span>
			</h3>
			<div class="subscription-payment-actions">
				<div
					v-show="!creditCardOpen && !subIsRetryableForPaymentLink"
					:class="{ disabled: subIsPending || voucherToValidate }"
					class="button blue subscription-button credit-card"
					@click="() => toggleCreditCard()"
				>
					{{ $t('subscription.creditCardButtonLabel') }}
				</div>
				<div
					v-show="!creditCardOpen && !subIsRetryableForPaymentLink"
					:class="{ disabled: subIsPending || voucherToValidate }"
					class="button blue subscription-button bancontact"
					@click="() => toggleBancontact()"
				>
					{{ $t('subscription.bancontactButtonLabel') }}
				</div>
				<div
					v-show="subIsRetryableForPaymentLink"
					class="button blue subscription-button other-methods"
					@click="() => toggleSendInvoice()"
				>
					{{ $t('subscription.otherPaymentMethodButtonLabel') }}
				</div>
				<div
					v-show="!creditCardOpen && canShowStripePaymentLink && !voucherToValidate"
					id="payment-request-button"
					class="blue subscription-button stripe-button"
				>
					<!-- A Stripe Element will be inserted here. -->
				</div>
				<div
					v-if="false"
					:disabled="true"
					class="button blue disabled subscription-button bancontact"
				>
					{{ $t('subscription.bancontactButtonLabelSoon') }}
				</div>
			</div>

			<div v-show="showBancontactInfo" class="subscription-stripe-form subscription-invoice-info">
				<div class="subscription-invoice-info-label">{{ $t('subscription.payByInvoiceInfo') }}</div>
				<div
					v-show="!creditCardOpen"
					:class="{ disabled: subIsPending }"
					class="button blue subscription-button"
					@click="() => doInitiateBancontact()"
				>
					{{ $t('subscription.sendInvoiceButtonLabel') }}
				</div>
			</div>
			<div v-show="showSendInvoiceInfo" class="subscription-stripe-form subscription-invoice-info">
				<div class="subscription-invoice-info-label">
					{{ $t('subscription.payWithAnotherMethod') }}
				</div>
				<div
					v-show="!creditCardOpen"
					class="button blue subscription-button"
					@click="() => doPayFromInvoice()"
				>
					{{ $t('subscription.sendInvoiceButtonLabel') }}
				</div>
			</div>
			<!-- <form v-show="creditCardOpen && existsInStripe" class="subscription-stripe-form" @submit.prevent="createToken">
				<div ref="card" :class="{ error: stripeError }" class="subscription-card" />
				<p v-if="stripeError" class="input-error">
					{{ stripeError }}
				</p>
				<p class="subscription-card-extra-info">
					*{{ $t('subscription.paymentCreditCardDetails') }}
				</p>
				<div class="subscription-stripe-actions">
					<div class="button red subscription-button" @click="() => toggleCreditCard()">
						{{ $t('general.cancelLabel') }}
					</div>
					<button
						:disabled="stripeHasError()"
						:class="{ disabled: stripeHasError() }"
						class="button blue subscription-button"
						type="submit"
					>
						{{ $t('subscription.confirmLabel') }}
					</button>
				</div>
			</form> -->
		<MollieStep2 v-show="creditCardOpen" @childEvent="parentMethod" :backgroundColor="parentBackgroundColor"  /> 
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading.vue'
import { logger } from '@/logger'
import { config, APP_ENV, ALLOW_PAYMENT_LINKS_IN_PROD, DEFAULT_PRICES } from '@/config'
import { tryTranslate } from '@/helpers'
import MollieStep2 from './MollieStep2.vue'
const stripe = window.Stripe(config.stripePublicKey)
const hundred = 100

// const redirectUrl = config.subscriptionRedirectUrl
let card = null

export default {
	name: 'Step2',
	components: {
		Loading,
		MollieStep2
	},
	props: {
		value: {
			type: Object,
			required: true,
			default: () => {},
		},
		deposit: {
			type: Boolean,
			default: false,
		},
	
	},
	data() {
		return {
			// loading: false,
			stripeError: false,
			creditCardOpen: false,
			canShowStripePaymentLink: false,
			showBancontactInfo: false,
			showSendInvoiceInfo: false,
			elements: null,
			paymentRequest: null,
			prButton: null,
			existsInStripe: false,
			parentBackgroundColor:'#fffcf2'
		}
	},
	computed: {
		voucherName: {
			get() {
				return this.$store.state.subscription.voucher.name
			},
			set(name) {
				this.setVoucherName(name)
			},
		},
		...mapState({
			voucher: (state) => state.subscription.voucher,
			subscription: (state) => state.subscription.current,
			isLoading: (state) => state.subscription.isLoading,
			alert: (state) => state.alert,
			language: (state) => state.language,
			currentUser: (state) => state.profile.currentUser,
		}),
		...mapGetters('subscription', {
			subIsPending: 'subIsPending',
			subIsRetryableForPaymentLink: 'subIsRetryableForPaymentLink',
		}),
		isBigParking() {
			// return this.subscription.subscriptionType.name === 'BigParking'
			return !this.subscription.parkingId
		},
		priceParking() {
			return this.subscription.subscriptionType.price
		},
		priceReduction() {
			return this.voucherValid
				? (this.voucher.reduction * this.subscription.subscriptionType.price) / hundred
				: 0
		},
		priceTotal() {
			return this.priceParking - this.priceReduction + this.priceKey
		},
		priceKey() {
			return this.deposit ? DEFAULT_PRICES.deposit : 0
		},
		voucherValid() {
			return this.voucher.status === 'valid'
		},
		voucherInvalid() {
			return ['invalid', 'expired'].includes(this.voucher.status)
		},
		voucherToValidate() {
			return Boolean(this.voucherName) && (!this.voucher.status || this.voucherInvalid)
		},
		
	},
	watch: {
		voucherToValidate() {
			if (this.voucherToValidate) {
				this.creditCardOpen = false
				this.showBancontactInfo = false
			}
		},
		priceTotal() {
			this.updatePaymentRequest()
		},
	},
	mounted() {
		const urlParams = new URLSearchParams(window.location.search);
					const redirectParam = urlParams.get('redirect');

					if (redirectParam === 'true') {
						this.setLoading(true)
						this.creditCardOpen = true
						urlParams.delete('redirect');

					// Get the base URL without the query string
					const baseUrl = window.location.href.split('?')[0];

					// Build the new URL without the 'redirect' parameter
					const newUrl = baseUrl + '?' + urlParams.toString();

					// Update the URL without triggering a page reload
					window.history.replaceState({}, document.title, newUrl);

					}
		this.existsInStripe = this.currentUser.existsInStripe;
		
		// this.elements = stripe.elements({ locale: this.language.value })

		const style = {
			base: {
				color: '#258AC9',
				fontWeight: '600',
				fontFamily: '"Sofia Pro", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#ADD3EC',
				},
			},
			invalid: {
				color: '#E75130',
				iconColor: '#fa755a',
				'::placeholder': {
					color: '#f6baac',
				},
			},
		}

		/* card = this.elements.create('card', { style })

		card.mount(this.$refs.card)

		card.addEventListener('change', (event) => {
			if (event.error) {
				this.stripeError = event.error.message
			} else {
				this.stripeError = ''
			}
		}) */

		if (ALLOW_PAYMENT_LINKS_IN_PROD || APP_ENV !== 'PROD') {
			this.preparePaymentLinks()
		}
	},
	methods: {
		...mapActions({
			completeSubscription: 'subscription/completeSubscription',
			initiateBancontact: 'subscription/initiateBancontact',
			initiateStripePaymentLink: 'subscription/initiateStripePaymentLink',
			refreshSubscription: 'subscription/refreshSubscription',
			validateVoucher: 'subscription/validateVoucher',
			deleteVoucher: 'subscription/deleteVoucher',
		}),
		...mapMutations('subscription', {
			setLoading: 'setLoading',
			setSendInvoice: 'setSendInvoice',
			setVoucherName: 'setVoucherName',
		}),

		updatePaymentRequest() {
			if (this.paymentRequest) {
				this.paymentRequest.update({
					total: {
						label: tryTranslate(
							this.subscription.subscriptionType.name,
							'subscription.subscriptionPlanNames'
						),
						amount: this.priceTotal * hundred,
					},
				})
			}
		},
		async preparePaymentLinks() {
			this.paymentRequest = stripe.paymentRequest({
				country: 'BE',
				currency: 'eur',
				total: {
					label: tryTranslate(
						this.subscription.subscriptionType.name,
						'subscription.subscriptionPlanNames'
					),
					amount: this.priceTotal * hundred,
				},
				requestPayerName: true,
				requestPayerEmail: true,
			})

			const result = await this.paymentRequest.canMakePayment()

			if (result) {
				this.prButton = this.elements.create('paymentRequestButton', {
					paymentRequest: this.paymentRequest,
				})

				this.prButton.mount('#payment-request-button')
				this.canShowStripePaymentLink = true

				this.paymentRequest.on('paymentmethod', async (ev) => {
					let paymentIntentSecret = null

					// If the subscription was pending, we check if it's a paymentLink and if it has a scaPaymentIntentSecret. If no, we raise an error, if yes
					// We skip the doInitiatePaymentLink call.

					if (this.subIsPending) {
						if (this.subIsRetryableForPaymentLink) {
							paymentIntentSecret = this.subscription.scaPaymentIntentSecret
						} else {
							// Should not be possible
							this.flash('Something went wrong.', 'error')
						}
					} else {
						paymentIntentSecret = await this.doInitiatePaymentLink()
					}

					if (paymentIntentSecret) {
						/* fake one to simulate an error */
						/* const errorSecret = 'pi_3MNI9HIdlJzrpoZZ0o4LsRHk_secret_ahmrHITc2k58K0EkoQ6YBzSOpx' */
						const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
							paymentIntentSecret,

							/* errorSecret, */

							// eslint-disable-next-line camelcase
							{ payment_method: ev.paymentMethod.id },
							{ handleActions: false }
						)

						if (confirmError) {
							/* The user can retry the payment by clicking the button again. */
							/* The sub is now pending and has isPaymentLink:true and has a scaPaymentIntentSecret */
							ev.complete('fail')
						} else {
							ev.complete('success')

							// Check if the PaymentIntent requires any actions and if so let Stripe.js
							// handle the flow. If using an API version older than "2019-02-11"
							// instead check for: `paymentIntent.status === "requires_source_action"`.
							if (['requires_action', 'requires_source_action'].includes(paymentIntent.status)) {
								// Let Stripe.js handle the rest of the payment flow.
								const { error } = await stripe.confirmCardPayment(paymentIntentSecret)

								if (error) {
									ev.complete('fail')
								} else {
									// The payment has succeeded.
									this.$emit('next-step')
								}
							} else {
								// The payment has succeeded.
								this.$emit('next-step')
							}
						}
					} else {
						ev.complete('fail')
					}
				})
			}
		},

		async validateStripeSca(paymentIntentSecret) {
			this.setLoading(true)

			const result = await stripe.handleCardPayment(paymentIntentSecret)

			// Access the token with result.token

			if (result.error) {
				this.setLoading(false)
				this.flash(result.error.message, 'error') // maybe no flash message needed
				window.location.reload()
			} else {
				this.$emit('next-step')
			}
		},
		async doValidateVoucher(e) {
			if (e) {
				e.preventDefault()
			}

			await this.validateVoucher()
		},
		async doCompleteSubscription(stripeToken) {
			if (this.subscription.id) {
				const result = await this.completeSubscription(stripeToken)[0]
				this.setLoading(false)
				if (result) {
					const paymentIntentSecret = this.subscription.scaPaymentIntentSecret

					if (paymentIntentSecret) {
						return this.validateStripeSca(paymentIntentSecret)
					} else {
						this.$emit('next-step')

						return true
					}
				} else {
					// this.flash(this.alert.message, this.alert.type)
					logger.debug('Your subscription could not be confirmed, check your input')

					return true
				}
			} else {
				return true
			}
		},
		async doInitiateBancontact() {
			if (!this.subIsPending) {
				if (this.voucherToValidate) {
					await this.doValidateVoucher()
				}

				if (this.voucherInvalid) {
					return false
				}

				let result ;

				if (this.subscription.id) {
					result = await this.initiateBancontact({language:this.language.value})

					if (!result) {
						// this.flash(this.alert.message, this.alert.type)
						logger.debug('Your subscription could not be confirmed, check your input')
					}

					this.setSendInvoice()
					this.$emit('next-step')
				}
			}

			return true
		},

		async doInitiatePaymentLink() {
			if (this.voucherToValidate) {
				await this.doValidateVoucher()
			}

			if (this.voucherInvalid) {
				return false
			}

			let paymentIntentSecret = false

			if (this.subscription.id) {
				paymentIntentSecret = await this.initiateStripePaymentLink(this.language.value)

				if (!paymentIntentSecret) {
					// this.flash(this.alert.message, this.alert.type)
					logger.debug('Your subscription could not be confirmed, check your input')
				}
			}

			return paymentIntentSecret
		},

		async doPayFromInvoice() {
			if (this.subscription.id) {
				await this.refreshSubscription({
					subId: this.subscription.id,
					sendInvoiceForPaymentLink: true,
				})
				this.setSendInvoice()
				this.$emit('next-step')
			}
		},

		async createToken() {
			if (this.voucherToValidate) {
				await this.doValidateVoucher()
			}

			if (this.voucherInvalid) {
				return false
			}

			this.setLoading(true)

			return stripe.createToken(card).then((result) => {
				// Access the token with result.token

				if (result.error) {
					this.setLoading(false)
					this.flash(result.error.message, 'error') // maybe no flash message needed
				} else {
					this.doCompleteSubscription(result.token.id)
				}
			})
		},
		stripeHasError() {
			return Boolean(this.stripeError)
		},
		voucherCanValidate() {
			return Boolean(this.voucherName) && !this.voucherInvalid && !this.subIsPending
		},
		toggleBancontact() {
			if (!this.subIsPending && !this.voucherToValidate) {
				this.showBancontactInfo = !this.showBancontactInfo
			}
		},

		toggleSendInvoice() {
			if (this.subIsRetryableForPaymentLink) {
				this.showSendInvoiceInfo = !this.showSendInvoiceInfo
			}
		},
		toggleCreditCard() {
			// Only if subscripion is not pending
			if (!this.subIsPending && !this.voucherToValidate) {
				this.creditCardOpen = !this.creditCardOpen
				this.showBancontactInfo = false
			}
		},
		parentMethod(dataFromChild) {
		console.log('Parent method called with data:', dataFromChild);
		this.$emit('next-step')

		// Perform actions with the data received from the child component
         },
		tryTranslate,
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/subscription/step2';
</style>
