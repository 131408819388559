import { mapState, mapMutations, mapActions } from 'vuex'
import { logger } from '../logger'

export default {
	created() {
		logger.debug('mixing in subscription info !')

		this.setDirectSubscription({})

		if (this.$route.params.subType) {
			this.setDirectSubscription({
				type: this.$route.params.subType,
				voucher: this.$route.query.voucher,
			})
		}

		if (this.$route.query.from) {
			this.$store.commit('storeFromPath', this.$route.query.from)
		}

		if (this.isLoggedIn) {
			// this.routeAfterAuth()
			this.goAfterAuth()
		}
	},
	computed: {
		...mapState({
			directSubscription: (state) => state.subscription.direct,
			isLoggedIn: (state) => state.authentication.isLoggedIn,
		}),
	},
	methods: {
		...mapMutations({
			setDirectSubscription: 'subscription/setDirectSubscription',
		}),
		...mapActions({
			goAfterAuth: 'goAfterAuth',
		}),

		// routeAfterAuth() {
		// 	return this.directSubscription ? this.$router.push({ name: 'subscription' }) : this.$router.push({ name: 'profile' })
		// },
	},
}
