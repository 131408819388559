<template>
	<div>
		<div class="temporaryAccess">
			<Loading v-if="isLoading" />
			<Start
				v-if="canStart"
				:parking="parking"
				:hasCreditCard="hasCreditCard"
				@start="doStartTemporaryAccess"
			/>
			<Canceled v-if="canceled" />
			<QrCode
				v-if="canShowQrCode"
				:parkingName="parking.name"
				:isEndCode="canEnterToEnd || canLeaveToEnd"
				:canGetOut="canGetOut"
				:alreadyEntered="false"
				:temporaryAccess="currentTemporaryAccess"
				:isRetry="isRetry"
				@refresh="refresh"
			/>
			<Stop
				v-if="canStop"
				:parking="parking"
				:temporaryAccess="currentTemporaryAccess"
				:confirmationModal="stopModalOpen"
				:canGetOut="canGetOut"
				:leaveQrCodeVisible="showLeaveQrCode"
				:qrCodeVisible="canShowQrCode"
				:stopRequested="canLeaveToEnd"
				:isRetry="isRetry"
				:failedToEnterAtStart="failedToEnterAtStart"
				@stop="doStopTemporaryAccess"
				@refresh="refresh"
				@openModal="openStopModal"
				@closeModal="closeStopModal"
				@showTheLeaveQrCode="showTheLeaveQrCode"
				@hideTheLeaveQrCode="hideTheLeaveQrCode"
			/>
			<Invoiced
				v-if="invoicedOrPaid"
				:parking="parking"
				:temporaryAccess="currentTemporaryAccess"
				@refresh="refresh"
			/>
			<OtherParking
				v-if="Boolean(otherParking)"
				:parking="otherParking"
				@goToTa="doGoToActiveTemporaryAccess"
			/>
			<Retry
				v-if="canRetry"
				:temporaryAccess="currentTemporaryAccess"
				:retryEnter="retryEnter"
				@retry="doRetryTemporaryAccess"
			/>
		</div>
	</div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import Start from '@/components/temporary-access/Start.vue'
import QrCode from '@/components/temporary-access/QrCode.vue'
import Stop from '@/components/temporary-access/Stop.vue'
import OtherParking from '@/components/temporary-access/OtherParking.vue'
import Canceled from '@/components/temporary-access/Canceled.vue'
import Invoiced from '@/components/temporary-access/Invoiced.vue'
import Retry from '@/components/temporary-access/Retry.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import ValidationRequired from '@/mixins/validation-required'
import { TEMPORARY_ACCESS_STATUSES, DELAY_TO_RETRY } from '@/config'

/* import { logger } from '@/logger' */

export default {
	name: 'TemporaryAccess',
	components: {
		Loading,
		Start,
		QrCode,
		OtherParking,
		Canceled,
		Invoiced,
		Stop,
		Retry,
	},
	mixins: [ValidationRequired],
	props: {
		parkingCode: {
			type: String,
		},
		temporaryAccessId: {
			type: Number,
		},
	},
	data() {
		return {
			timer: null,
			timerForNow: null,
			now: new Date(),
			timerInterval: null,
			stopModalOpen: false,
			showLeaveQrCode: false,
		}
	},
	computed: {
		...mapState({
			profileLoading: (state) => state.profile.loading,
			loading: (state) => state.temporaryAccess.loading,
			refreshing: (state) => state.temporaryAccess.refreshing,
			parking: (state) => state.temporaryAccess.parking,
			currentTemporaryAccess: (state) => state.temporaryAccess.currentTemporaryAccess,
		}),
		isLoading() {
			return this.profileLoading || this.loading
		},
		hasCreditCard() {
			const c = this.currentUser.paymentMethod

			return c === 'card'
		},
		canStart() {
			return Boolean(!this.isLoading && this.parking.id && !this.currentTemporaryAccess.id)
		},
		hasParkingAndTa() {
			return Boolean(!this.isLoading && this.parking.id && this.currentTemporaryAccess.id)
		},
		otherParking() {
			return (
				this.hasParkingAndTa &&
				this.currentTemporaryAccess.parkingId !== this.parking.id &&
				this.currentTemporaryAccess.parking
			)
		},
		sameParking() {
			return Boolean(
				this.hasParkingAndTa && this.currentTemporaryAccess.parkingId === this.parking.id
			)
		},
		hasActiveTa() {
			return Boolean(this.sameParking && this.currentTemporaryAccess.active)
		},
		hasInactiveTa() {
			return Boolean(this.sameParking && !this.currentTemporaryAccess.active)
		},
		canEnterToStart() {
			return (
				this.hasActiveTa &&
				this.currentTemporaryAccess.status === TEMPORARY_ACCESS_STATUSES.START_CODE_GENERATED
			)
		},
		canGetOut() {
			return (
				this.hasActiveTa &&
				[
					TEMPORARY_ACCESS_STATUSES.STARTED,
					TEMPORARY_ACCESS_STATUSES.ENTERED_READY_TO_END,
				].includes(this.currentTemporaryAccess.status)
			)
		},
		failedToEnterAtStart() {
			return (
				this.hasActiveTa &&
				this.currentTemporaryAccess.status === TEMPORARY_ACCESS_STATUSES.STARTED &&
				this.isRetry
			)
		},
		canStop() {
			return (
				this.hasActiveTa &&
				[
					TEMPORARY_ACCESS_STATUSES.STARTED,
					TEMPORARY_ACCESS_STATUSES.STARTED_AND_WENT_OUT,
					TEMPORARY_ACCESS_STATUSES.ENTERED_READY_TO_END,
				].includes(this.currentTemporaryAccess.status)
			)
		},
		canEnterToEnd() {
			return (
				this.hasActiveTa &&
				this.currentTemporaryAccess.status === TEMPORARY_ACCESS_STATUSES.END_CODE_GENERATED
			)
		},
		canLeaveToEnd() {
			return (
				this.hasActiveTa &&
				this.currentTemporaryAccess.status === TEMPORARY_ACCESS_STATUSES.ENTERED_READY_TO_END
			)
		},
		canShowQrCode() {
			return (
				this.canEnterToStart ||
				this.canEnterToEnd ||
				(this.canGetOut && this.showLeaveQrCode) ||
				this.isRetry
			)
		},
		canceled() {
			return (
				this.hasInactiveTa &&
				this.currentTemporaryAccess.status === TEMPORARY_ACCESS_STATUSES.CANCELED
			)
		},
		invoiced() {
			return (
				this.sameParking &&
				[TEMPORARY_ACCESS_STATUSES.ENDED, TEMPORARY_ACCESS_STATUSES.INVOICED].includes(
					this.currentTemporaryAccess.status
				)
			)
		},
		invoicedOrPaid() {
			return (
				this.sameParking &&
				[
					TEMPORARY_ACCESS_STATUSES.ENDED,
					TEMPORARY_ACCESS_STATUSES.INVOICED,
					TEMPORARY_ACCESS_STATUSES.PAID,
				].includes(this.currentTemporaryAccess.status)
			)
		},
		expectedTimeInterval() {
			return this.canShowQrCode ? 5000 : this.canStop && this.stopModalOpen ? 60000 : null
		},
		canRetry() {
			let isAllowed = this.currentTemporaryAccess.canRetry

			if (isAllowed) {
				const lastScannedAt = this.currentTemporaryAccess.lastScannedAt

				const maxAllowedDate = new Date(lastScannedAt + DELAY_TO_RETRY)

				isAllowed = this.now <= maxAllowedDate
			}

			return isAllowed
		},
		retryEnter() {
			return [
				TEMPORARY_ACCESS_STATUSES.STARTED,
				TEMPORARY_ACCESS_STATUSES.ENTERED_READY_TO_END,
			].includes(this.currentTemporaryAccess.status)
		},
		isRetry() {
			return Boolean(this.currentTemporaryAccess.retryPendingSince)
		},
	},
	watch: {
		expectedTimeInterval() {
			this.checkRefreshInterval()
		},
	},

	created() {
		// this.timer = setInterval(this.refresh, this.timerInterval)
		this.timerForNow = setInterval(this.refreshNow, 1000)
	},
	mounted() {
		// allow direct link to confirmation step

		if (this.temporaryAccessId) {
			if (!this.currentTemporaryAccess.id) {
				this.loadTemporaryAccess(this.temporaryAccessId)
			}
		} else if (this.parkingCode) {
			if (!this.parking.id) {
				this.loadParkingOrExistingTa(this.parkingCode)
			}
		}
	},
	beforeDestroy() {
		this.resetState()
		this.clearError()
		this.cancelAutoRefresh()
	},
	methods: {
		...mapActions({
			loadParkingOrExistingTa: 'temporaryAccess/loadParkingOrExistingTa',
			refreshTemporaryAccess: 'temporaryAccess/refreshTemporaryAccess',
			loadTemporaryAccess: 'temporaryAccess/loadTemporaryAccess',
			clearError: 'alert/clear',
			startTemporaryAccess: 'temporaryAccess/startTemporaryAccess',
			retryTemporaryAccess: 'temporaryAccess/retryTemporaryAccess',
			stopTemporaryAccess: 'temporaryAccess/stopTemporaryAccess',
			goToActiveTemporaryAccess: 'temporaryAccess/goToActiveTemporaryAccess',
		}),
		...mapMutations('temporaryAccess', {
			resetState: 'resetState',
		}),
		/* eslint-disable-next-line max-statements */
		refreshNow() {
			this.now = new Date()
		},
		async doStartTemporaryAccess() {
			if (this.canStart) {
				await this.startTemporaryAccess()
			}
		},
		async doRetryTemporaryAccess() {
			if (this.canRetry) {
				this.hideTheLeaveQrCode()
				await this.retryTemporaryAccess()
			}
		},
		async doStopTemporaryAccess() {
			if (this.canStop) {
				await this.stopTemporaryAccess()
			}
		},
		doGoToActiveTemporaryAccess() {
			this.goToActiveTemporaryAccess()
		},
		checkRefreshInterval() {
			if (this.timerInterval !== this.expectedTimeInterval) {
				if (this.timerInterval) {
					this.cancelAutoRefresh()
				}

				if (this.expectedTimeInterval) {
					this.timerInterval = this.expectedTimeInterval
					this.timer = setInterval(this.refresh, this.timerInterval)
				} else {
					this.timerInterval = null
				}
			}
		},
		refresh(opts = {}) {
			const canRefresh = true

			if (canRefresh && this.sameParking && !this.refreshing) {
				this.refreshTemporaryAccess(opts)
			}
		},
		cancelAutoRefresh() {
			clearInterval(this.timer)
		},
		openStopModal() {
			this.stopModalOpen = true
		},
		showTheLeaveQrCode() {
			this.showLeaveQrCode = true
		},
		hideTheLeaveQrCode() {
			this.showLeaveQrCode = false
		},
		closeStopModal() {
			this.stopModalOpen = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/temporaryAccess/temporaryAccess';
</style>
