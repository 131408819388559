<template>
	<div tabindex="0" @focus="focusDropdown" @blur="away">
		<div v-on-clickaway="away" class="dropdown">
			<div class="dropdown-selected" @mousedown="toggleDropdown($event)">
				{{ selected.value }}
				<div :class="{ open: dropdownOpen }" class="dropdown-arrow" />
			</div>
			<div :class="{ open: dropdownOpen }" class="dropdown-list">
				<div
					v-for="option in options.filter((o) => o.value !== selected.value)"
					:key="option.key"
					:class="{ active: option.value === localSelection.value }"
					class="dropdown-list-option"
					@mousedown="onClick($event, option)"
				>
					{{ option.value }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'

export default {
	name: 'DropdownLanguage',
	mixins: [clickaway],
	props: {
		options: {
			type: Array,
			required: true,
		},
		selected: {
			type: Object,
			required: false,
			default: () => {},
		},
	},
	data() {
		return {
			dropdownOpen: false,
			localSelection: Object.assign({}, this.selected),
		}
	},
	created() {
		window.addEventListener('keydown', this.dropdownEvents, true)
	},
	destroyed() {
		window.removeEventListener('keydown', this.dropdownEvents, true)
	},
	methods: {
		onClick(e, option) {
			this.doPreventDefault(e)
			this.localSelection = option

			if (this.dropdownOpen && this.selected !== option) {
				this.$emit('on-click', option)
				this.toggleDropdown()
			}
		},
		toggleDropdown(e) {
			this.doPreventDefault(e)
			this.liteToggle()
			this.checkUpdateCurrentSelection()
		},
		liteToggle() {
			this.dropdownOpen = !this.dropdownOpen
			this.setEventArrowListeners()
		},
		checkUpdateCurrentSelection() {
			if (!this.dropdownOpen && this.localSelection.value !== this.selected.value) {
				this.$emit('on-click', this.localSelection)
			}
		},
		setEventArrowListeners() {
			if (this.dropdownOpen) {
				window.addEventListener('keydown', this.changeCurrentSelection, true)
			} else {
				window.removeEventListener('keydown', this.changeCurrentSelection, true)
			}
		},
		changeCurrentSelection(e) {
			const keyCodeUp = 38
			const keyCodeDown = 40
			const keyCodeEnter = 13
			const localOptions = this.options.filter((option) => option.value !== this.selected.value)
			const amountOptions = localOptions.length
			const currentIndex = localOptions.findIndex(
				(option) => option.value === this.localSelection.value
			)
			const canMoveUp = currentIndex > 0
			const canMoveDown = currentIndex < amountOptions - 1

			switch (e.keyCode) {
				case keyCodeUp:
					e.preventDefault()
					this.localSelection = canMoveUp ? localOptions[currentIndex - 1] : this.localSelection
					break
				case keyCodeDown:
					e.preventDefault()
					this.localSelection = canMoveDown ? localOptions[currentIndex + 1] : this.localSelection
					break
				case keyCodeEnter:
					this.toggleDropdown()
					break
				default:
					this.liteToggle()
					break
			}
		},
		away() {
			this.isFocused = false

			if (this.dropdownOpen) {
				this.liteToggle()
			}
		},
		focusDropdown() {
			this.isFocused = true

			if (!this.dropdownOpen) {
				this.toggleDropdown()
			}
		},
		dropdownEvents(e) {
			const keyCodeDown = 40
			const keyCodeEnter = 13

			switch (e.keyCode) {
				case keyCodeDown:
				case keyCodeEnter:
					if (this.isFocused && !this.dropdownOpen) {
						e.preventDefault()
						this.liteToggle()
					}

					break
				default:
					break
			}
		},
		doPreventDefault(e) {
			if (e) {
				e.preventDefault()
			}
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/components/dropdownLang';
</style>
