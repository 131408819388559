import { render, staticRenderFns } from "./SessionResult.vue?vue&type=template&id=10127f26&scoped=true"
import script from "./SessionResult.vue?vue&type=script&lang=js"
export * from "./SessionResult.vue?vue&type=script&lang=js"
import style0 from "./SessionResult.vue?vue&type=style&index=0&id=10127f26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10127f26",
  null
  
)

export default component.exports