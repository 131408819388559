<template>
	<div>
		<div class="login">
			<PreviousButton/>
			<div class="login-hero">
				<img class="login-hero-image" src="../../assets/images/logo-pb.png" alt="" />
			</div>
			<div class="login-content">
				<Loading v-if="local.loading" />
				<h1 class="title login-title">
					{{ $t('login.title') }}
				</h1>
				<!-- <p class="text login-info">
					{{ $t('login.info')[directSubscription] || $t('login.info.general') }}
				</p> -->
				<form class="login-form" novalidate @submit.prevent="validateBeforeSubmit">
					<div class="input-box login-input">
						<label class="login-label">
							<span>{{ $t('login.yourLogin') }}</span>
						</label>
						<input
							v-model="credentials.email"
							v-validate="'required'"
							:placeholder="$t('login.emailOrPhoneLabel')"
							:class="{ error: errors.has('email') }"
							:data-vv-as="$t('login.emailOrPhoneLabel')"
							name="email"
							class="input-field"
							autocorrect="off"
							autocapitalize="none"
							@input="onInputEmail"
						/>
						<p v-show="errors.has('email')" class="input-error">
							{{ errors.first('email') }}
						</p>
					</div>
					<div class="input-box login-input">
						<label class="login-label">
							<span>{{ $t('profile.passwordLabelSignup') }}</span>
						</label>
						<div class="input-box login-input">
							<input
								ref="password"
								v-model="credentials.password"
								v-validate="'required|min:6'"
								:placeholder="$t('profile.passwordLabel')"
								:class="{ error: errors.has('password') }"
								:data-vv-as="$t('profile.passwordLabel')"
								name="password"
								type="password"
								class="input-field input-password"
							/>
							<div class="input-reveal-button" @click="toggleReveal">
								<div :class="{ revealed: local.reveal }" class="input-reveal-button-line" />
							</div>
							<p v-show="errors.has('password')" class="input-error">
								{{ errors.first('password') }}
							</p>
						</div>
					</div>
					<div class="login-link-box">
						<router-link class="login-link" to="/forgotPassword">
							{{ $t('login.loginForgotPassword') }}
						</router-link>
					</div>
					<div v-if="loginInfo">
						{{ $t(`login.${loginInfo}`) }}
					</div>

					<!-- <router-link class="login-link link" to="/forgotPassword">
						{{ $t('login.loginForgotPassword') }}
					</router-link> -->

					<div class="login-form-actions">
						<button
							:disabled="errors.any()"
							:class="{ disabled: errors.any() }"
							class="button login-button"
							type="submit"
						>
							{{ $t('login.loginLabel') }}
						</button>
						<router-link
							:to="{ name: 'signup', params: $route.params, query: $route.query }"
							class="login-link-button"
						>
							{{ $t('login.signupLabel') }}
						</router-link>

					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading'
import HasSubscriptionInfo from '@/mixins/has-subscription-info'
import PreviousButton from '@/components/PreviousButton.vue';

export default {
	name: 'Login',
	components: {
		Loading,
		PreviousButton,
	},
	mixins: [HasSubscriptionInfo],
	data() {
		return {
			credentials: {
				email: '',
				password: '',
			},
			local: {
				loading: false,
				reveal: false,
			},
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.authentication.currentUser,
			loginError: (state) => {
				return { type: state.alert.type, message: state.alert.message }
			},
			valid: (state) => state.authentication.valid,
			loginInfo: (state) => state.authentication.loginInfo,
		}),
	},
	mounted() {
		this.$validator.pause()
	},
	beforeDestroy() {
		this.clearGeneralError()
	},
	methods: {
		...mapActions({
			loginUser: 'authentication/loginUser',
			clearLoginError: 'alert/clear',
		}),
		async doLogin() {
			if (!this.local.loading) {
				this.local.loading = true
				await this.loginUser(this.credentials)
				this.local.loading = false

				if (this.valid) {
					this.flash(this.$t('flashMessage.loginDone'), 'success')

					this.goAfterAuth() // HasSubscriptionInfo
				}
			}
		},
		onInputEmail(event) {
			this.credentials.email = event.target.value.trim()
		},
		clearGeneralError() {
			if (this.loginError.message) {
				this.clearLoginError()
			}
		},
		toggleReveal() {
			this.local.reveal = !this.local.reveal

			if (this.local.reveal) {
				this.$refs.password.setAttribute('type', 'text')
			} else {
				this.$refs.password.setAttribute('type', 'password')
			}
		},
		validateBeforeSubmit() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			this.$validator.validateAll().then((result) => {
				if (result) {
					this.doLogin()
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/login';
</style>
